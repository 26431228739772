import { Therapy } from '@alberta/konexi-shared';
import { InjectionToken } from '@angular/core';

import {
  AppointmentDB,
  ArticleDB,
  AttachmentDB,
  AttributeTemplateDB,
  AuditDB,
  CareProposalDB,
  ContractArticleGroupDB,
  DeviceDB,
  DirectOrderDB,
  DoctorDB,
  ErpOrderDB,
  ErpTaskDB,
  FaxDB,
  GroupDB,
  HospitalDB,
  InstitutionAuditDB,
  InstitutionContactDB,
  InstitutionNoteDB,
  InsuranceContractDB,
  IntegratedCareDB,
  NursingHomeDB,
  NursingServiceDB,
  OrderDB,
  PatientDB,
  PatientNotesDB,
  PayerDB,
  PayerInfoDB,
  PharmacyDB,
  PlannedOrderDB,
  PostalCodeDB,
  ProductGroupDB,
  RegionDB,
  ReminderDB,
  ReturnDeliveryDB,
  RightsetDB,
  SingleOrderDB,
  StandardCareProposalDB,
  SubunitDB,
  TemplateDB,
  UsersDB,
} from './databases';

const patientPlugin = {
  fields: [
    '_id',
    'lastName',
    'firstName',
    'customerId',
    'nursingHomeId',
    'nursingServiceId',
    'fieldNurseId',
    'city',
    'postalCode',
    'additionalAddress',
    'birthday',
    'nursingHome.subunitId',
    'additionalUserId',
    'additionalUserIds',
  ],
};

const doctorPlugin = {
  fields: ['lastName', 'firstName', 'city', 'postalCode', 'institution.description'],
  db: 'global',
};

const hospitalPlugin = {
  fields: ['company', 'name', 'city', 'postalCode'],
  db: 'global',
};

const nursingHomePlugin = {
  fields: ['name', 'city', 'postalCode'],
  db: 'global',
};

const nursingServicePlugin = {
  fields: ['name', 'city', 'postalCode'],
  db: 'global',
};

const payerPlugin = {
  fields: ['_id', 'name', 'ikNumber'],
  db: 'global',
};

const payerInfoPlugin = {
  fields: ['payerId', 'header'],
};

const postalCodePlugin = {
  fields: ['_id', 'city', 'postalCode'],
  db: 'global',
};

const appointmentPlugin = {
  fields: ['patientIds', 'nursingHomeId', 'userId', 'title'],
};

const usersPlugin = {
  fields: ['firstName', 'lastName', 'email'],
  db: 'users',
};

const groupPlugin = {
  fields: ['_id', 'label'],
};

const regionPlugin = {
  fields: ['_id', 'parent', 'label'],
};

const rightsetPlugin = {
  fields: ['_id', 'description'],
  db: 'users',
};

const productGroupPlugin = {
  fields: ['_id', 'number', 'name', 'description', 'parentId'],
};

const articlePlugin = {
  fields: [
    '_id',
    'name',
    'pcn',
    'customerNumber',
    'manufacturerNumber',
    'productGroupId',
    'manufacturer',
    'additionalDescription',
    'description',
  ],
};

const standardCareProposalPlugin = {
  fields: ['name'],
};

const insuranceContractPlugin = {
  fields: ['_id', 'name', 'therapyId'],
  modifier: {
    therapyId: value => {
      return Therapy[value];
    },
  },
};

const pharmacyPlugin = {
  fields: ['name', 'city', 'postalCode'],
  db: 'global',
};

const careProposalPlugin = {
  fields: ['patientId'],
};

const integratedCarePlugin = {
  fields: ['patientId'],
};

const erpTaskPlugin = {
  fields: ['patientId', 'entityId'],
};

const orderPlugin = {
  fields: ['patientId', 'careProposalId'],
};

const singleOrderPlugin = {
  fields: ['patientId', 'integratedCareId'],
};

const directOrderPlugin = { fields: ['patientId'] };

const auditPlugin = {
  fields: ['patientId'],
};

const faxPlugin = {
  fields: ['title', 'createdBy'],
};

const patientNotesPlugin = {
  fields: ['patientId'],
};

const erpOrderPlugin = {
  fields: ['salesId', 'patientId'],
};

const devicePlugin = {
  fields: ['articleId', 'patientId'],
};

const attributeTemplatePlugin = {
  fields: ['name'],
};

const subunitPlugin = {
  fields: ['institutionId'],
};

const institutionContactPlugin = {
  fields: ['institutionId'],
};

const institutionNotePlugin = {
  fields: ['institutionId'],
};

const institutionAuditPlugin = {
  fields: ['institutionId'],
};

const attachmentPlugin = {
  fields: [
    'metadata.patientId',
    'metadata.auditNodeId',
    'metadata.templateNodeId',
    'metadata.auditId',
    'metadata.templateId',
  ],
  dbPostfix: 'files',
};

const returnDeliveryPlugin = {
  fields: ['patientId'],
};

const contractArticleGroupPlugin = {
  fields: ['_id', 'name', 'customerNumber'],
};

const templatePlugin = {
  fields: ['templateTag'],
};

const reminderPlugin = {
  fields: ['patientId', 'entityId', 'date', 'userId'],
};

const plannedOrderPlugin = {
  fields: ['patientId'],
};

export interface DBPlugin {
  fields: string[];
  modifier?: Record<string, (item: any) => string>;
  db?: string;
}

export const PluginsToken = new InjectionToken('Plugins', { providedIn: 'root', factory: () => DB_PLUGINS });

const DB_PLUGINS: Record<string, DBPlugin> = {};

DB_PLUGINS[PatientDB] = patientPlugin;
DB_PLUGINS[NursingHomeDB] = nursingHomePlugin;
DB_PLUGINS[NursingServiceDB] = nursingServicePlugin;
DB_PLUGINS[HospitalDB] = hospitalPlugin;
DB_PLUGINS[InsuranceContractDB] = insuranceContractPlugin;
DB_PLUGINS[DoctorDB] = doctorPlugin;
DB_PLUGINS[PayerDB] = payerPlugin;
DB_PLUGINS[PayerInfoDB] = payerInfoPlugin;
DB_PLUGINS[PostalCodeDB] = postalCodePlugin;
DB_PLUGINS[UsersDB] = usersPlugin;
DB_PLUGINS[GroupDB] = groupPlugin;
DB_PLUGINS[RegionDB] = regionPlugin;
DB_PLUGINS[RightsetDB] = rightsetPlugin;
DB_PLUGINS[StandardCareProposalDB] = standardCareProposalPlugin;
DB_PLUGINS[CareProposalDB] = careProposalPlugin;
DB_PLUGINS[IntegratedCareDB] = integratedCarePlugin;
DB_PLUGINS[SingleOrderDB] = singleOrderPlugin;
DB_PLUGINS[ProductGroupDB] = productGroupPlugin;
DB_PLUGINS[ArticleDB] = articlePlugin;
DB_PLUGINS[PharmacyDB] = pharmacyPlugin;
DB_PLUGINS[OrderDB] = orderPlugin;
DB_PLUGINS[AuditDB] = auditPlugin;
DB_PLUGINS[PatientNotesDB] = patientNotesPlugin;
DB_PLUGINS[FaxDB] = faxPlugin;
DB_PLUGINS[ErpOrderDB] = erpOrderPlugin;
DB_PLUGINS[DeviceDB] = devicePlugin;
DB_PLUGINS[AttachmentDB] = attachmentPlugin;
DB_PLUGINS[AttributeTemplateDB] = attributeTemplatePlugin;
DB_PLUGINS[SubunitDB] = subunitPlugin;
DB_PLUGINS[InstitutionContactDB] = institutionContactPlugin;
DB_PLUGINS[InstitutionNoteDB] = institutionNotePlugin;
DB_PLUGINS[InstitutionAuditDB] = institutionAuditPlugin;
DB_PLUGINS[ReturnDeliveryDB] = returnDeliveryPlugin;
DB_PLUGINS[AppointmentDB] = appointmentPlugin;
DB_PLUGINS[TemplateDB] = templatePlugin;
DB_PLUGINS[ErpTaskDB] = erpTaskPlugin;
DB_PLUGINS[DirectOrderDB] = directOrderPlugin;
DB_PLUGINS[ContractArticleGroupDB] = contractArticleGroupPlugin;
DB_PLUGINS[ReminderDB] = reminderPlugin;
DB_PLUGINS[PlannedOrderDB] = plannedOrderPlugin;

export default DB_PLUGINS;
