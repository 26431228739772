import { Injectable } from '@angular/core';
import { isNumber } from 'lodash';
import { Channel } from 'twilio-chat/lib/channel';
import { Member } from 'twilio-chat/lib/member';
import { Message } from 'twilio-chat/lib/message';

import { ChatChannel, ChatMember, ChatMessage } from '../data/db-schema';

@Injectable({
  providedIn: 'root',
})
export class TwilioToChatDtoHelpersService {
  public convertToChatChannel(channel: Channel, updateReasons?: string): ChatChannel {
    return {
      sid: channel.sid,
      dateUpdated: channel.dateUpdated.toISOString(),
      dateCreated: channel.dateCreated.toISOString(),
      friendlyName: channel.friendlyName || '',
      uniqueName: channel.uniqueName || '',
      lastMessageIndex: channel.lastMessage ? channel.lastMessage.index : null,
      lastMessageTimestamp: channel.lastMessage
        ? channel.lastMessage.timestamp.toISOString()
        : channel.dateCreated.toISOString(),
      lastLocalUpdateAt: undefined,
      lastConsumedMessageIndex: channel.lastConsumedMessageIndex || null,
      removed: false,
      attributes: channel.attributes,
      updateReasons,
    };
  }

  public async convertToChatMessage(message: Message): Promise<ChatMessage> {
    return {
      _id: message.attributes['_id'],
      isLocal: undefined,
      channelSid: message.channel.sid,
      sid: message.sid,
      dateUpdated: message.dateUpdated.toISOString(),
      timestamp: message.timestamp.toISOString(),
      index: message.index,
      body: message.body,
      memberSid: message.memberSid,
      memberIdentity: (await message.getMember()).identity,
      status: 'sent',
      retries: 0,
      attributes: message.attributes,
    };
  }

  public convertToChatMember(member: Member): ChatMember {
    return {
      sid: member.sid,
      channelSid: member.channel.sid,
      identity: member.identity,
      lastConsumedMessageIndex: isNumber(member.lastConsumedMessageIndex) ? member.lastConsumedMessageIndex : 0,
      dateUpdated: member.dateUpdated.toISOString(),
      isLocal: undefined,
    };
  }
}
