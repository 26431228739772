import { IReminder } from '@alberta/konexi-shared';
import moment from 'moment';

export function filterTodayReminders(reminders: IReminder[]) {
  return (
    reminders &&
    reminders.filter(
      reminder => !reminder.archived && moment(new Date(reminder.date)).isSame(moment().startOf('day'), 'd')
    )
  );
}
