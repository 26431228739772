import { AttachmentType, IPatient } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { IStateExtension } from 'src/app/common/contracts/state/state-extension';
import { IStateRegistry } from 'src/app/common/contracts/state/state-registry';
import { PatientDB } from 'src/app/common/repository/databases';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';
import { AttachmentModelName } from 'src/app/shared/models/model-names';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable({ providedIn: 'root' })
export class AttachmentStateExtension implements IStateExtension<AttachmentDto> {
  private _stateRegistry: IStateRegistry;
  private _myPatients: IPatient[] = [];

  public get name(): string {
    return AttachmentModelName;
  }

  constructor(private _authenticationService: AuthService, private _queryService: QueryService) {}

  setRegistry(stateRegistry: IStateRegistry): void {
    this._stateRegistry = stateRegistry;
  }
  async canBeStored(attachment: AttachmentDto): Promise<boolean> {
    const isMyAttachment = attachment.patient != null || (await this.isMyAttachment(attachment));

    return isMyAttachment && attachment.metadata.createdBy !== this._authenticationService.authentication.account._id;
  }

  public async afterCreate(attachments: AttachmentDto[]): Promise<void> {
    if (!attachments || !attachments.length) {
      return;
    }

    await this.fetchMyPatients();

    if (!this._myPatients.length) {
      return;
    }

    const filteredAttachments = attachments.filter(
      attachment =>
        attachment.metadata.type === AttachmentType.CareProposal ||
        attachment.metadata.type === AttachmentType.PatientAgreement ||
        attachment.metadata.type === AttachmentType.DoctorDelegation
    );

    for (const attachment of filteredAttachments) {
      const canBeDispatched = await this.canBeStored(attachment);
      if (!canBeDispatched) {
        continue;
      }

      this._stateRegistry.createBySync(AttachmentModelName, 'notification', attachment);
    }
  }

  afterUpdate(attachments: AttachmentDto[]): Promise<void> {
    return;
  }

  private async isMyAttachment(attachment: AttachmentDto): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this._authenticationService.authenticatedEventPublisher
        .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
        .subscribe({
          complete: () => {
            const patient = this._myPatients
              .filter(myPatient => !myPatient.deactivationReason && myPatient.accountingStatus)
              .find(
                myPatient =>
                  attachment.metadata.patientId &&
                  myPatient._id === attachment.metadata.patientId &&
                  attachment.metadata.auditId == null
              );

            if (patient) {
              (attachment as any).patient = patient;
            }

            resolve(patient != null);
          },
        });
    });
  }

  private async fetchMyPatients() {
    return new Promise<void>(resolve => {
      this._authenticationService.authenticatedEventPublisher
        .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
        .subscribe({
          complete: async () => {
            this._myPatients = await this._queryService.search<IPatient>(
              {
                // tslint:disable-next-line: max-line-length
                query: `fieldNurseId:${this._authenticationService.authentication.account._id} additionalUserId:${this._authenticationService.authentication.account._id} additionalUserIds:${this._authenticationService.authentication.account._id}`,
              },
              PatientDB,
              { isIn: true }
            );

            resolve();
          },
        });
    });
  }
}
