import { NgModule } from '@angular/core';
import {
  SelectUserToShareDialogModule,
} from 'src/app/shared/components/select-user-to-share-dialog/select-user-to-share-dialog.module';

import { PatientRemovalService } from '../business/patient/patient-removal.service';
import { PipelineModule } from './pipeline/pipeline.module';
import { DataRemovalToken } from './services/contracts/data/data-removal';
import { RouteParserModule } from './services/route/route-parser.module';
import { SyncModule } from './services/sync/sync.module';

@NgModule({
  imports: [SyncModule.forRoot(), RouteParserModule.forRoot(), PipelineModule.forRoot(), SelectUserToShareDialogModule],
  providers: [{ provide: DataRemovalToken, useClass: PatientRemovalService, multi: true }],
})
export class SharedModule {}
