import { IGroup, IRightset, IUser } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { Deferred } from 'src/app/common/deferred/deferred';
import { GroupDB, RightsetDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';

import { AuthService } from './auth.service';
import { RegionService } from './region.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationResolver {
  private _unitOfWork: IUnitOfWork;
  private _ready = new Deferred<void>();
  private _rightSets: IUser[] = [];

  constructor(
    private _authService: AuthService,
    @Inject(UnitOfWorkFactory) private _unitOfWorkFactory: IUnitOfWorkFactory,
    private _regionProvider: RegionService
  ) {
    this._regionProvider.restrictToUser(true);

    this._unitOfWorkFactory.create().then(unitOfWork => {
      this._unitOfWork = unitOfWork;

      this._ready.resolve();
    });
  }

  private userGroupReducer(groups) {
    return groups.reduce((rights, groupItem) => {
      groupItem.rightSet.forEach(item => {
        if (rights.includes(item)) {
          return;
        }
        rights.push(item);
      });
      return rights;
    }, []);
  }

  private permissionComparer(rightSetA, rightSetB) {
    if (rightSetA.permission < rightSetB.permission) {
      return -1;
    }
    if (rightSetA.permission > rightSetB.permission) {
      return 1;
    }
    return 0;
  }

  private reduceAndSortRightSet(rightSets) {
    const sortedAuthorization = rightSets
      .reduce((permissions, rightSetItem) => {
        (rightSetItem as any).permission.forEach(item => {
          permissions.push(item);
        });
        return permissions;
      }, [])
      .sort(this.permissionComparer);

    return sortedAuthorization.reduce((acc: any[], value: any) => {
      const permission = acc.find(
        permission1 => permission1.domain === value.domain && permission1.permission <= value.permission
      );
      if (permission) {
        permission.permission = value.permission;
      } else {
        acc.push(value);
      }

      return acc;
    }, []);
  }

  public async resolveRegion(): Promise<void> {
    await this._authService.init;

    if (!this._authService.authentication.account) {
      return;
    }
    const regions = await this._regionProvider.getAll();
    if (!regions) {
      return;
    }

    this._authService.authentication.account.authorization.regions = regions;
  }

  public async resolve(user?: IUser): Promise<void> {
    await this._authService.init;

    if (
      !this._authService.authentication.account ||
      (user && user._id !== this._authService.authentication.account._id)
    ) {
      await this._authService.resetOrganisationMembers(user);
      return;
    }

    await this._authService.init;
    await this.reBuildAuthorization(user || this._authService.authentication.account);
  }

  private async reBuildAuthorization(user: IUser) {
    await this.reFetchGroupsAndRightSets(user);

    if (!this._rightSets) {
      return;
    }

    await this._authService.resetRights(this.reduceAndSortRightSet(this._rightSets));
  }

  private async reFetchGroupsAndRightSets(user: IUser) {
    await this._ready.promise;

    const groupRepository = await this._unitOfWork.create<IGroup>(GroupDB);
    const groups = await groupRepository.getItems(user.groups);
    const rightSetRepository = await this._unitOfWork.create<IRightset>(RightsetDB);
    const rightSetIds = this.userGroupReducer(groups);
    this._rightSets = await rightSetRepository.getItems(rightSetIds);
  }
}
