import { IMaintenance } from '@alberta/konexi-shared';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMaintenanceService } from 'src/app/business/maintenance/contracts/maintenance';
import { MaintenanceDto } from 'src/app/shared/models/maintenance/maintenance-dto.model';
import { MaintenanceService } from 'src/app/shared/services/maintenance/maintenance.service';

import { CountdownService } from '../../../services/maintenance/countdown.service';
import { ICountdownInfo } from './contracts/countdown-info';

@Component({
  selector: 'itl-maintenance-snack-bar',
  templateUrl: './maintenance-snack-bar.component.html',
  styleUrls: ['./maintenance-snack-bar.component.scss'],
  providers: [CountdownService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceSnackBarComponent implements AfterViewInit, OnDestroy {
  public countdownInfo: ICountdownInfo;
  public maintenance: IMaintenance;
  public showMaintenance = false;
  private _ngUnsubscribe = new Subject();

  constructor(
    @Inject(MaintenanceService) private _maintenanceService: IMaintenanceService,
    private _countdownService: CountdownService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.watchCountdown();
  }

  private watchCountdown() {
    this._countdownService.countdown.pipe(takeUntil(this._ngUnsubscribe)).subscribe(countdownInfo => {
      this.countdownInfo = countdownInfo;
      this._changeDetectorRef.detectChanges();
    });
  }

  public ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  public ngAfterViewInit() {
    this._maintenanceService.maintenanceWatch.pipe(takeUntil(this._ngUnsubscribe)).subscribe(maintenance => {
      if (!maintenance) {
        this.showMaintenance = false;
        this._countdownService.stop();
        this._changeDetectorRef.detectChanges();
        return;
      }
      this.maintenance = maintenance;
      this.showMaintenance = this._countdownService.start(maintenance as MaintenanceDto);
      this._changeDetectorRef.detectChanges();
    });
  }
  public closeSnackBar(): void {
    this.ngOnDestroy();
    this.showMaintenance = false;

    setTimeout(async () => {
      this._ngUnsubscribe = new Subject();
      this.watchCountdown();
      await this._maintenanceService.startWatchingMaintenance();
      await this.ngAfterViewInit();
    }, this._countdownService.redrawTimeoutMs());
  }
}
