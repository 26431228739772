import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { IStorageMetadata } from 'src/app/common/contracts/database/storage-metadata';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { Deferred } from 'src/app/common/deferred/deferred';
import {
  AuditDB,
  CareProposalDB,
  OrderDB,
  PatientDB,
  PatientNotesDB,
  RegionDB,
  SyncTimestampDB,
} from 'src/app/common/repository/databases';
import { SqliteStorage } from 'src/app/common/storage/sqlite-storage';

import { StorageInformation } from '../models/storage-information';
import { IDatabaseService } from './contracts/database/database-service';
import { IGenericStorage } from './contracts/database/generic-storage';
import { IIndexRepairProgress } from './contracts/database/index-repair-progress';

@Injectable({ providedIn: 'root' })
export class DatabaseService implements IDatabaseService {
  private _storages: Record<string, IGenericStorage>;
  private _ready = new Deferred<void>();
  private _priorityRank = [SyncTimestampDB, PatientDB, CareProposalDB, OrderDB, AuditDB, PatientNotesDB, RegionDB];

  constructor(public translate: TranslateService, @Inject(PlatformSyncToken) private _platformSync: IPlatformSync) {}

  setStorages(storages: Record<string, IGenericStorage>): void {
    this._storages = storages;

    this._ready.resolve();
  }

  // tslint:disable-next-line:array-type
  public get databaseNames(): Promise<IStorageMetadata[]> {
    return this._ready.promise.then(() => {
      const storageKeys = Object.keys(this._storages);

      const metadata: IStorageMetadata[] = [];

      let index = 0;
      for (const key of storageKeys) {
        metadata[index++] = {
          databaseName: key,
          priority: this._priorityRank.includes(key) ? 1 : 2,
        };
      }

      return metadata;
    });
  }

  public async getDatabase(name: string): Promise<IGenericStorage> {
    await this._ready.promise;

    const storage = this._storages[name];
    if (!storage) {
      throw new Error(`No storage defined for ${name}`);
    }
    await storage.ready();
    return storage;
  }

  public async clearSingleCollection(collectionName: string): Promise<void> {
    await this._ready.promise;

    if (!collectionName || collectionName.length < 4) {
      return;
    }
    if (this._storages.hasOwnProperty(collectionName)) {
      await this._storages[collectionName].clear().catch(() => undefined);
    }
    // tslint:disable-next-line:array-type
    const promises: Promise<void>[] = [];
    promises.push(await this.clearSyncTimestamp(collectionName));

    await Promise.all(promises);
  }

  public repairSingleIndex(collectionName: string): Observable<IIndexRepairProgress> {
    const progressSubject = new Subject<IIndexRepairProgress>();

    // tslint:disable-next-line: no-floating-promises
    this._ready.promise.then(() => {
      if (!collectionName || collectionName.length < 4) {
        progressSubject.next({ processedItems: 1, totalItems: 1 });
        progressSubject.complete();
      }

      if (this._storages.hasOwnProperty(collectionName)) {
        this._storages[collectionName].repairIndex(progressSubject);
      }
    });

    return progressSubject.asObservable();
  }

  public async clearAll(switchingChannel: boolean = false): Promise<void> {
    await this._ready.promise;

    // tslint:disable-next-line:array-type
    const promises: Promise<void>[] = [];
    for (const name in this._storages) {
      if (this._storages.hasOwnProperty(name) && (this._storages[name].isDeletable || switchingChannel)) {
        promises.push(this._storages[name].clear().catch(() => undefined));
      }
    }

    if (this._platformSync.isCordova) {
      const syncTimestampStorage = await this.getDatabase('syncTimestamp.db');
      promises.push(syncTimestampStorage.clear().catch(() => undefined));
    }

    await Promise.all(promises);
  }

  public async getStorageInformation(): Promise<StorageInformation[]> {
    try {
      await this._ready.promise;

      // tslint:disable-next-line:array-type
      const storageInformation: StorageInformation[] = [];
      for (const name in this._storages) {
        if (this._storages.hasOwnProperty(name)) {
          const length = await this._storages[name].length();
          let indexLength = null;
          if (this._storages[name] instanceof SqliteStorage) {
            indexLength = await this._storages[name].length(true);
          }
          const splittedName = name.split('.');
          const displayName = this.translate.instant(`entities.${splittedName[0]}`);
          storageInformation.push(
            new StorageInformation(
              name,
              displayName,
              this._storages[name].isDeletable,
              this._storages[name].canRepairIndex,
              length,
              0,
              indexLength
            )
          );
        }
      }
      return storageInformation.sort((a, b) => a.displayName.localeCompare(b.displayName));
    } catch (error) {
      window.logger.error('DATABASESERVICE GETSTORAGEINFORMATION', error);
    }
  }

  private async clearSyncTimestamp(collectionName: string) {
    const syncTimestampStorage = await this.getDatabase('syncTimestamp.db');
    const entityName = collectionName.substring(0, collectionName.length - 3);
    return syncTimestampStorage.removeItem(entityName);
  }
}
