import { inject, InjectionToken } from '@angular/core';
import { Platform } from '@ionic/angular';
import pouchdbDebug from 'pouchdb-debug';
import RxDB, { RxDatabase } from 'rxdb';
import { takeWhile } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';

import { AuthService } from '../../auth.service';
import { Branch, EnvironmentService } from '../../environment/environment.service';
import {
  channelsMigrationStrategies,
  channelsSchema,
  chatChannelConsumptionSchema,
  ChatCollections,
  membersSchema,
  messagesMigrationStrategies,
  messagesSchema,
} from './db-schema';

const debug = makeDebug('services:chat:db');

export const RX_DB_SERVICE_TOKEN = new InjectionToken<Promise<RxDatabase<ChatCollections>>>(
  'Manually constructed MyService',
  {
    providedIn: 'root',
    factory: () => _initDb(inject(Platform), inject(RX_DB_TOKEN), inject(AuthService), inject(EnvironmentService)),
  }
);

// abstraction of static class with depdency injection
export const RX_DB_TOKEN = new InjectionToken<typeof RxDB>('rxdb token', {
  providedIn: 'root',
  factory: () => RxDB,
});

export async function _initDb(
  platform: Platform,
  rxdb: typeof RxDB,
  authService: AuthService,
  environmentService: EnvironmentService
) {
  rxdb.plugin(require('pouchdb-adapter-idb'));
  rxdb.plugin(pouchdbDebug);
  rxdb.plugin(require('rxdb/plugins/watch-for-changes'));

  debug('init db');
  const isCordova = platform.is('cordova');
  const pouchSettings: any = {
    revs_limit: 1,
  };

  if (isCordova) {
    debug('Use cordova');
    rxdb.plugin(require('pouchdb-adapter-cordova-sqlite'));
    pouchSettings.iosDatabaseLocation = 'default';
    pouchSettings.androidDatabaseImplementation = 2;
  }
  // migration - see rx-db-migration.spec.ts (to migrate schema)
  // https://github.com/pubkey/rxdb/blob/master/docs-src/data-migration.md
  // Use for dev
  // await RxDB.removeDatabase('chatdb', 'idb');

  const db = await rxdb.create<ChatCollections>({
    name: await createDbName(authService, environmentService),
    adapter: isCordova ? 'cordova-sqlite' : 'idb',
    ignoreDuplicate: true,
    queryChangeDetection: true,
    pouchSettings,
  });
  const setupPromises = [
    db.collection({
      name: 'channels',
      schema: channelsSchema,
      migrationStrategies: channelsMigrationStrategies,
    }),
    db.collection({
      name: 'consumptions',
      schema: chatChannelConsumptionSchema,
    }),
    db.collection({
      name: 'messages',
      schema: messagesSchema,
      migrationStrategies: messagesMigrationStrategies,
    }),
    db.collection({
      name: 'members',
      schema: membersSchema,
    }),
  ];
  await Promise.all(setupPromises);
  debug('db init completed');
  // setup watch for changes when writing directly with .pouch
  db.messages.watchForChanges();
  return db;
}

export async function createDbName(authService: AuthService, environmentService: EnvironmentService) {
  return new Promise<string>(resolve => {
    authService.authenticatedEventPublisher.pipe(takeWhile(authEventData => !authEventData.isAuthenticated)).subscribe({
      complete: () => {
        const account = authService.authentication.account;
        const branch = environmentService.branch === Branch.production ? 'master' : environmentService.branch;
        const dbName = `${account.firstName[0]}${account.lastName[0]}${account._id.slice(-4)}_${branch}`;

        resolve(dbName.toLowerCase());
      },
    });
  });
}
