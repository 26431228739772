import { IUser } from '@alberta/konexi-shared';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Dictionary, groupBy, sortBy } from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FieldNurse } from '../../models/field-nurse';
import { ChatChannelWithDetails, ChatService } from '../../services/chat/chat.service';
import { FieldNurseService } from '../../services/field-nurse.service';

@Component({
  selector: 'itl-select-user-to-share-dialog',
  templateUrl: './select-user-to-share-dialog.component.html',
  styleUrls: ['./select-user-to-share-dialog.component.scss'],
})
export class SelectUserToShareDialogComponent implements OnInit, OnDestroy {
  public showChannelList = true;
  public showSearchList = false;
  public showNoChannelsState = false;
  public isLoading = true;
  public isOnline: boolean;

  public groupedChannels: Dictionary<ChatChannelWithDetails[]>;
  public userResults: IUser[];
  public selectedItem: ChatChannelWithDetails | FieldNurse;
  public allSingleChannels: ChatChannelWithDetails[] = [];
  public showMoreButtonDisabled = false;

  get sid(): string {
    if (!this.selectedItem) {
      return undefined;
    }
    return (this.selectedItem as ChatChannelWithDetails).sid;
  }

  get fieldNurseId(): string {
    if (!this.selectedItem) {
      return undefined;
    }
    return (this.selectedItem as FieldNurse)._id;
  }

  private _destroyed = new Subject();

  constructor(
    private _modalCtrl: ModalController,
    private _chatService: ChatService,
    private _fieldNurseService: FieldNurseService
  ) {}

  async ngOnInit() {
    this.observeOnlineState();
    const channels$ = await this._chatService.getChannels();
    channels$
      .pipe(
        takeUntil(this._destroyed),
        map(channels => sortBy(channels, channel => moment(channel.lastMessageTimestamp)).reverse())
      )
      .subscribe(channels => {
        this.groupedChannels = groupBy(channels, item => {
          return item.uniqueName === '' ? 'multi' : 'single';
        });
        this.allSingleChannels = [];
        if (this.groupedChannels['single'] && this.groupedChannels['single'].length) {
          this.allSingleChannels = [...this.groupedChannels['single']];
          this.groupedChannels['single'] = this.groupedChannels['single'].slice(0, 5);
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public async done() {
    if (this.isChannel(this.selectedItem)) {
      await this._modalCtrl.dismiss(this.selectedItem).catch(error => console.error(error));
    } else {
      const chatCannel = await this._chatService.createChat('', [this.selectedItem._id]);
      await this._modalCtrl.dismiss(chatCannel).catch(error => console.error(error));
    }
  }

  public async close() {
    await this._modalCtrl.dismiss().catch(error => console.log(error));
  }

  public selectChannel(chatChannel: ChatChannelWithDetails) {
    if (this.selectedItem === chatChannel) {
      this.selectedItem = null;
      return;
    }
    this.selectedItem = chatChannel;
  }

  public selectUser(user: FieldNurse) {
    if (this.selectedItem === user) {
      this.selectedItem = null;
      return;
    }
    this.selectedItem = user;
  }

  public async searchUser(value) {
    if (!value || value.length === 0) {
      this.clearSearch();
      return;
    }
    this.isLoading = true;
    this.userResults = await this._fieldNurseService.query(value, true);
    this.showChannelList = false;
    this.showSearchList = true;
    this.isLoading = false;
  }

  public clearSearch() {
    this.showChannelList = true;
    this.showSearchList = false;
  }

  public showMore() {
    if (!this.showMoreButtonDisabled) {
      this.groupedChannels['single'] = this.allSingleChannels;
      this.showMoreButtonDisabled = true;
    }
  }

  private isChannel(selectedItem: ChatChannelWithDetails | FieldNurse): selectedItem is ChatChannelWithDetails {
    return (selectedItem as ChatChannelWithDetails).sid !== undefined;
  }

  private observeOnlineState() {
    this._chatService
      .observeIsOnline()
      .pipe(takeUntil(this._destroyed))
      .subscribe(isOnline => {
        this.isOnline = isOnline;
      });
  }
}
