import 'reflect-metadata';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import LocalForage from 'localforage';
import { extendPrototype as extendWithGetItems } from 'localforage-getitems';
import { extendPrototype as extendWithSetItems } from 'localforage-setitems';

import { AppModule } from './app/app.module';
import { ILogger } from './app/common/contracts/logging/logger';
import { environment } from './environments/environment';

import 'hammerjs';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    logger: ILogger;
    requestIdleCallback: (callback, options) => void;
    cancelIdleCallback: (id: number) => void;
  }
}
const requestIdleCallback = (cb, options) => {
  const start = Date.now();
  return setTimeout(() => {
    cb({
      didTimeout: false,
      timeRemaining: () => {
        return Math.max(0, 50 - (Date.now() - start));
      },
    });
  }, 1);
};

window.requestIdleCallback = window.requestIdleCallback || requestIdleCallback;

const cancelIdleCallback = id => {
  clearTimeout(id);
};
window.cancelIdleCallback = window.cancelIdleCallback || cancelIdleCallback;

extendWithSetItems(LocalForage);
extendWithGetItems(LocalForage);

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
bootstrap().catch(err => console.error(err));
