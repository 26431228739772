import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { MbscCalendarOptions } from '@mobiscroll/angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IBaseInputContract } from '../contracts/base-input';
import TIMEPICKERALBERTATHEME, { getMobiScrollCalenderOptions } from './date-time-picker-options';

export enum DateType {
  DateTime,
  Date,
  Time,
}

@Component({
  selector: 'itl-date-time',
  templateUrl: 'date-time.component.html',
  styleUrls: ['date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeComponent implements OnInit, OnDestroy, IBaseInputContract, ControlValueAccessor {
  @Input() public title = '';
  @Input() public placeholder = '';
  // Min Max wird nur auf das Datum nicht auf die Zeit angewendet
  @Input() public min = new Date('1900-01-01');
  @Input() public max = new Date();
  @Input() public preSelection: Date;
  @Input() public type: DateType = DateType.Date;
  // TODO add Clear Button
  @Input() public showClearButton = false;
  @Input() public required = false;
  @Input() public icon: string;
  @Input() public disabled: boolean;
  @Input() public noIcon = false;
  @Input() public description: string;
  @Input() public dateFormatter = 'dd.MM.yyyy';
  @Input() public minTime: string | Date;
  @Input() public maxTime: string | Date;
  @Input() public noDeleteButton = false;
  @Output() public change: EventEmitter<Date> = new EventEmitter<Date>();

  @ViewChild('mobiscrollDateInput', { static: true }) mobiscrollDateInput: any;
  @ViewChild('picker', { static: true }) public timePicker: any;
  @ViewChild('timeInput', { static: true }) public timeInputElement: any;

  public calenderOptions: MbscCalendarOptions;
  public albertaTimePickerOptions = TIMEPICKERALBERTATHEME;
  public date: Date;
  private _ngUnsubscribe = new Subject();

  private onChange = (date: Date) => {};
  private onTouched = () => {};

  // TODOs
  // - mark control as touched when Timepicker clicked but nothing is selected
  // - add Placeholder
  // - when clicked on icon / item then open date or timepicker
  // - Bei click immer Date öffen

  constructor(
    public plt: Platform,
    private _translate: TranslateService,
    public ngControl: NgControl,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.calenderOptions = getMobiScrollCalenderOptions(this._translate, this.plt, this.min, this.max);
    if (this.type !== DateType.Time) {
      this.subscribeToDate();
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  openMobiScroll() {
    this.mobiscrollDateInput.instance.show();
  }
  clearDate(event: Event): void {
    event.stopPropagation();
    this.date = null;
    this.change.emit(null);
  }
  cancel() {
    this.markAsTouched();
  }

  private subscribeToDate() {
    this.mobiscrollDateInput.onSet.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      let retVal: Date;
      switch (this.type) {
        case DateType.Date:
          retVal = moment(value.valueText, 'DD.MM.YYYY').toDate();
          break;
        case DateType.DateTime:
          retVal = moment(`${value.valueText} ${moment(this.date).format('LT')}`, 'DD.MM.YYYY HH:mm').toDate();
          break;
      }
      this.date = retVal;
      this.updateChanges();
      if (this.type !== DateType.Date) {
        this.timePicker.open();
      }
    });
  }

  public openDate() {
    this.mobiscrollDateInput._instance.show();
  }

  setTime(value) {
    let retVal;
    switch (this.type) {
      case DateType.DateTime:
        retVal = moment(
          `${this.date ? moment(this.date).format('L') : moment(new Date()).format('L')} ${value}`,
          'DD.MM.YYYY HH:mm'
        )
          .utc()
          .toDate();
        break;
      case DateType.Time:
        retVal = moment(`01.01.1970 ${value}`, 'DD.MM.YYYY HH:mm').utc().toDate();
        break;
    }
    this.date = retVal;
    this.change.emit(this.date);
    this.updateChanges();
  }

  openTimePicker() {
    this.timeInputElement.nativeElement.click();
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  public updateChanges() {
    this.onChange(this.date);
  }

  // ControlValueAccessor interface
  public writeValue(obj: any) {
    this.date = obj;
    this._changeDetectorRef.markForCheck();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public removeInput($event): void {
    this.clearDate($event);
    this.updateChanges();
  }
}
