<div
  class="alberta-default-listitem-wrapper"
  [ngClass]="{
    'alberta-default-listitem-wrapper--disabled': disabled,
    'alberta-default-listitem-wrapper--noHover': noHover
  }"
  (click)="itemClicked()"
  [ngStyle]="{ height: fixedHeight, 'padding-left': paddingLeft }"
>
  <div class="listitem-icon-wrapper"><ng-content select="itl-listitem-icon"></ng-content></div>
  <div class="listitem-content-wrapper">
    <div class="text-wrapper">
      <div class="main-information">
        <div class="listitem-text-container">
          <ng-content select="itl-listitem-top-header"></ng-content>
          <ng-content select="itl-listitem-header"></ng-content>
          <ng-content select="itl-listitem-bottom-subheader"></ng-content>
          <ng-content select="itl-listitem-additional-info"></ng-content>
        </div>
        <ng-content select="itl-listitem-status"></ng-content>
      </div>
      <ng-content select="itl-listitem-bottom-additional-info"></ng-content>
    </div>
  </div>
</div>
