import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

// @ts-ignore
const iconContext = require.context('../../../../assets/icon', true, /^\.\/.*\.svg$/);

const iconMap: Map<string, string> = new Map();
iconContext.keys().forEach((path: string) => {
  const groups = path.match(/\/([a-z0-9.-]+)(?=\.[a-z]+)/);
  if (groups && Array.isArray(groups)) {
    return iconMap.set(groups[1], path);
  }
});

@Component({
  selector: 'itl-alberta-icon',
  template: ` <ion-icon [icon]="icon"></ion-icon> `,
  styles: [':host { line-height:inherit; display: flex }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlbertaIconComponent implements OnChanges {
  @Input() name: string;

  public icon: string;

  ngOnChanges() {
    const foundAlbertaIconPath = iconMap.get(this.name);
    if (foundAlbertaIconPath) {
      this.icon = `assets/icon/${foundAlbertaIconPath}`;
    } else {
      this.icon = this.name;
    }
  }
}
