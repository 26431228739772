import { IIntegratedCare } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from 'src/app/common/state/state-extension';
import { IntegratedCareModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class IntegratedCareStateExtension extends BaseStateExtension<IIntegratedCare> {
  public get name(): string {
    return IntegratedCareModelName;
  }

  async canBeStored(item: IIntegratedCare): Promise<boolean> {
    return true;
  }

  protected afterCreateInternal(item: IIntegratedCare): void {}

  protected afterUpdateInternal(item: IIntegratedCare): void {
    this.stateRegistry.update(IntegratedCareModelName, 'selectedItem', item);
  }
  protected removeFromState(item: IIntegratedCare): void {}
}
