<itl-chat-message
  [text]="this.text"
  [messageTime]="this.messageTime"
  [authorName]="this.authorName"
  [showNames]="this.showNames"
  [changedMemberSid]="this.changedMemberSid"
  [colorHex]="this.colorHex"
  [type]="this.type"
  [status]="this.status"
  [payload]="this.payload"
  [advancedMessage]="advancedMessageBody"
></itl-chat-message>

<ng-template #advancedMessageBody>
  <div class="advanced-message">
    <div class="header" [ngClass]="{ 'header--no-name': this.showNames === false }">
      <div class="icon" *ngIf="this.payload.icon">
        <ion-icon [name]="this.payload.icon"></ion-icon>
      </div>
      <div class="title-wrapper">
        <div class="title ellipsis bold">
          {{ this.payload.title }}
        </div>
        <div class="subtitle ellipsis">{{ this.payload.subtitle }}</div>
      </div>
    </div>
    <div class="body">{{ this.payload.body }}</div>
    <div class="action-buttons-container">
      <itl-button class="action-button" (click)="actionButtonClicked()">Anzeigen</itl-button>
    </div>
  </div>
</ng-template>
