import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { isString, last } from 'lodash';
import { combineLatest, from, interval, Observable } from 'rxjs';
import { map, filter, mergeMap, switchMap, take, tap, timeout } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';
import { v4 } from 'uuid';

import { ChannelStatus } from '../../components/chat/channel-list-entry/channel-list-entry.component';
import { AuthService } from '../auth.service';
import { UserService } from '../user.service';
import { ChatConnectionStateService } from './chat-connection-state.service';
import { ChatMessageDataService } from './chat-message-data.service';
import { ChatSendQueueService } from './chat-send-queue.service';
import { ChatSendService } from './chat-send.service';
import { ChatUserService } from './chat-user.service';
import { ChatDbService } from './data/chat-db.service';
import { ChatChannel, ChatMember, ChatMessage } from './data/db-schema';
import { Chat } from './model/chat-instance';
import { ChatChannelDetail } from './model/chat.model';
import { TwilioChatEventSourceService } from './twillio/twilio-chat-event-source.service';
import { fetchMissingMessages } from './utils/missing-messages-finder';

const debug = makeDebug('services:chat:service');

export interface ChatChannelWithDetails extends ChatChannel {
  lastMessage: ChatMessage | null;
  unreadMessages: number;
  isActive: boolean;
  status?: ChannelStatus;
  assignedTo?: string;
}

@Injectable({ providedIn: 'root' })
export class ChatService {
  private userInfoCache: { [key: string]: { name: string; active: boolean } } = {};

  constructor(
    private _chatDb: ChatDbService,
    private _chatUserService: ChatUserService,
    private _chatSendService: ChatSendService,
    private _usersService: UserService,
    private _connectionStateService: ChatConnectionStateService,
    private _chatMessagesDataService: ChatMessageDataService,
    private _platform: Platform,
    /* imported to force init */
    private _chatSendQueue: ChatSendQueueService,
    private _twilioChatEventSourceService: TwilioChatEventSourceService,
    private readonly _authService: AuthService
  ) {
    this.registerPlatformResumeHandler();
  }

  private registerPlatformResumeHandler() {
    this._platform.resume.subscribe(async () => {
      try {
        debug('platform got active. fetch last messages!');
        await this.fetchLastMessagesOfChannels();
        await this.fetchLastMessagesOfChannels(Chat.PatientApp);
      } catch (err) {
        window.logger.error('fetch messages on resume failed', err);
      }
    });
  }

  public observeIsOnline(): Observable<boolean> {
    return this._connectionStateService.observeOnlineState();
  }

  public observeAgentIsOnline(): Observable<boolean> {
    return this._connectionStateService.observeAgentOnlineState();
  }

  public async markChannelAsRead(channelSid: string, index: number) {
    debug('mark channel as read', { channelSid, index });
    await this._chatMessagesDataService.updateChannelConsumptionStatus(channelSid, index);
  }

  public async checkForNewMessagesInChannel(channelSid: string, chat = Chat.Alberta) {
    const lastMessage = await this._chatDb.getLastMessageOfChannel(channelSid);
    const channel = await this._chatDb.getChannel(channelSid);
    const lastLocalIndex = lastMessage ? lastMessage.index : -1;
    debug('check for new messages', {
      lastLocalIndex,
      lastMessageIndex: channel.lastMessageIndex,
      fetch: channel.lastMessageIndex > lastLocalIndex,
    });
    if (channel.lastMessageIndex > lastLocalIndex) {
      await this.fetchMessagesOfChannel(channel.sid, undefined, channel.lastMessageIndex - lastLocalIndex, chat);
    }
  }

  public async fetchLastMessagesOfChannels(chat = Chat.Alberta) {
    const channels$ = await this._chatDb.getChannels(chat);
    channels$.pipe(take(1)).subscribe(async channels => {
      for (const channel of channels) {
        debug('fetch last message of channel', channel.sid);
        try {
          await this.fetchMessagesOfChannel(channel.sid, undefined, 1, chat);
        } catch (error) {
          console.error(error);
          window.logger.error('Fetching messages of channel failed.', error);
        }
      }
    });
  }

  public async getChannels(chat = Chat.Alberta): Promise<Observable<ChatChannelWithDetails[]>> {
    debug('get channels');
    const channels$ = await this._chatDb.getChannels(chat);
    const enrichChannel = async (channel: ChatChannelWithDetails) => {
      debug('enrich channel', channel);
      try {
        let unreadMessages = 0;
        if (
          channel.attributes &&
          (channel.attributes['assignedToId'] === this._authService.authentication.account._id ||
            channel.attributes['assignedToId'] === '')
        ) {
          unreadMessages = await this._chatMessagesDataService.getUnreadMessagesOfChannel(channel.sid);
        }

        channel.unreadMessages = unreadMessages;
        const sendQueue = await this._chatDb.getCurrentSendQueue(channel.sid);
        if (sendQueue.length > 0) {
          debug('channel has messages in queue. using them as last message');
          channel.lastMessage = last(sendQueue);
        } else {
          debug('no messages in send queue. get last message...', channel.sid);
          const lastMessage = await this._chatDb.getLastMessageOfChannel(channel.sid);
          if (lastMessage) {
            const userInfo = await this.getUserInfo(lastMessage.memberIdentity);
            lastMessage.authorName = userInfo.name;
            debug('channel has last message in chache. using it');
            channel.lastMessage = lastMessage;
            channel.isActive = userInfo.active;
          }
        }
        if (channel.uniqueName) {
          const user = await this.setFriendlyNameToRemoteUserName(channel);
          if (user) {
            channel.isActive = user.active;
          }
        } else {
          channel.isActive = true;
        }
      } catch (error) {
        window.logger.error('error with getMessageByIndex', error);
      }
      return channel;
    };
    return channels$.pipe(switchMap(channels => from(Promise.all(channels.map(enrichChannel)))));
  }

  private async setFriendlyNameToRemoteUserName(
    channel: ChatChannel | ChatChannelWithDetails
  ): Promise<{
    name: string;
    active: boolean;
  }> {
    const userIdentity = await this._chatUserService.getUserIdentity();
    const memberIdentities = this.getMemberSidsFromUniqueName(channel.uniqueName);
    const remoteUserIdentity = memberIdentities.find(f => f !== userIdentity);
    if (remoteUserIdentity) {
      const remoteUserName = await this.getUserInfo(remoteUserIdentity);
      if (remoteUserName && remoteUserName.name) {
        channel.friendlyName = remoteUserName.name;
      }
      return remoteUserName;
    }
  }

  private getMemberSidsFromUniqueName(uniqueName: string) {
    return uniqueName.split('§');
  }

  public async createChat(name: string, chatUserChatIds: string[]): Promise<ChatChannel> {
    const userIdentity = await this._chatUserService.getUserIdentity();
    debug('create chat with', name, chatUserChatIds, userIdentity);
    const chatChannel = await this._chatSendService.createChat(name, [userIdentity, ...chatUserChatIds]);
    await this._chatDb.upsertChannel(chatChannel);
    return chatChannel;
  }

  private async getMemberSidByLocalUser(channelSid: string, localUserIdentity: string): Promise<string | null> {
    return await this._chatDb.getMemberSidForIdentity(channelSid, localUserIdentity);
  }

  private async getLocalUserIdentity(): Promise<string | null> {
    return await this._chatUserService.getUserIdentity();
  }

  public async sendMessage(channelSid: string, message: string, attributes = {}) {
    debug('send message', { channelSid, message });
    const uuid = v4();
    const localUserIdentity = await this.getLocalUserIdentity();
    const localUserMemberSid = await this.getMemberSidByLocalUser(channelSid, localUserIdentity);
    const newMessage = this.convertToMessage(
      uuid,
      localUserMemberSid,
      localUserIdentity,
      channelSid,
      message,
      attributes
    );
    await this._chatDb.insertMessage(newMessage);
    await this._chatDb.setChannelLastLocalUpdate(channelSid);
  }

  public async fetchMessagesOfChannel(channelSid: string, anchor: number, count = 100, chat = Chat.Alberta) {
    debug('load messages of channel', { channelSid, anchor, count });
    let normalizedAnchor = anchor;
    if (normalizedAnchor < 0) {
      normalizedAnchor = undefined;
    }
    const messages = await this._chatSendService.fetchMessagesOfChannel(channelSid, normalizedAnchor, count, chat);

    const localUserIdentity = await this._chatUserService.getUserIdentity();
    for (const message of messages) {
      const isLocalUser = message.memberIdentity === localUserIdentity;
      message.isLocal = isLocalUser;
    }
    debug('...got messages in channel', channelSid, 'messages:', messages);

    if (await this._chatDb.bulkInsertMessages(messages)) {
      await this._chatDb.setChannelLastLocalUpdate(channelSid);
    }
  }

  public async isChannelReady(channel: ChatChannel, timeoutMs = 10 * 1000): Promise<boolean> {
    const localUser = await this.getLocalUserIdentity();

    const ready = interval(500)
      .pipe(
        map(async () => await this.getMemberSidByLocalUser(channel.sid, localUser)),
        map(memberSid => memberSid != null),
        filter(hasMemberSid => hasMemberSid === true),
        take(1),
        timeout(timeoutMs)
      )
      .toPromise();
    return ready;
  }

  public async getChannelDetails(channel: ChatChannel, chat = Chat.Alberta): Promise<Observable<ChatChannelDetail>> {
    debug('get channel details');
    const enrichChannelMember = async (member: ChatMember) => {
      debug('enricht member', member);
      const userInfo = await this.getUserInfo(member.identity);
      return { ...member, friendlyName: userInfo.name, isActive: userInfo.active };
    };
    const enrichMessages = async (message: ChatMessage) => {
      const userInfo = await this.getUserInfo(message.memberIdentity);
      message.authorName = userInfo.name;
      return message;
    };
    if (channel.uniqueName) {
      await this.setFriendlyNameToRemoteUserName(channel);
    }

    return combineLatest([
      (await this._chatDb.getChannelMembers(channel.sid)).pipe(
        mergeMap(members => from(Promise.all(members.map(enrichChannelMember))))
      ),
      (await this._chatDb.getMessagesOfChannel(channel.sid)).pipe(
        mergeMap(messages => from(Promise.all(messages.map(enrichMessages))))
      ),
    ]).pipe(
      tap(([_members, messages]) => {
        setTimeout(() => fetchMissingMessages(messages, chat, this.fetchMessagesOfChannel.bind(this)));
      }),
      mergeMap(([members, messages]) =>
        from(
          new Promise<{
            members;
            messages: ChatMessage[];
            channel1: ChatChannel;
          }>(async resolve => {
            const channel1 = await this._chatDb.getChannel(channel.sid);

            resolve({ members, messages, channel1 });
          })
        )
      ),
      map(({ members, messages, channel1 }) => {
        debug('map', { members, messages });

        const isOneOnOneChat = isString(channel.uniqueName) && channel.uniqueName !== '';
        // group chats are always active
        const isChannelActive = !isOneOnOneChat || members.every(member => member.isActive !== false);
        return {
          members,
          messages,
          channel,
          ...channel1,
          isActive: isChannelActive,
          showAssignAgentDialog: channel1.attributes && channel1.attributes['showAssignAgentDialog'] === true,
        } as ChatChannelDetail;
      })
    );
  }

  private async getUserInfo(identity: string): Promise<{ name: string; active: boolean }> {
    const cachedUserInfo = this.userInfoCache[identity];
    if (cachedUserInfo) {
      return cachedUserInfo;
    }
    const user = await this._usersService.find(identity);
    if (!user) {
      return { name: '', active: false };
    }
    const name = `${user.firstName} ${user.lastName}`;
    this.userInfoCache[identity] = { name, active: user.active === false ? false : true };
    return this.userInfoCache[identity];
  }

  public async updateChannelAttributes(channelSid: string, attributes = {}) {
    return this._chatSendService.updateChannelAttributes(channelSid, attributes);
  }

  private convertToMessage(
    uuid: string,
    memberSid: string,
    memberIdentity: string,
    channelSid: string,
    body: string,
    attributes: any
  ): ChatMessage {
    return {
      _id: uuid,
      isLocal: true,
      memberSid,
      memberIdentity,
      channelSid,
      body,
      status: 'pending',
      timestamp: new Date().toISOString(),
      retries: 0,
      dateUpdated: new Date().toISOString(),
      attributes,
    } as ChatMessage;
  }
}
