import { inject, InjectionToken } from '@angular/core';
import { filter } from 'rxjs/operators';
import * as TwilioChat from 'twilio-chat';
import makeDebug from '../../../../../makeDebug';
import { ChatUserService } from '../chat-user.service';

const debug = makeDebug('services:chat:twilio');

export type twilioClientFactory = (token: string, options?: TwilioChat.Client.Options) => Promise<TwilioChat.Client>;

export const CREATE_CLIENT_METHOD = new InjectionToken<twilioClientFactory>('Manually constructed Twilio Service', {
  providedIn: 'root',
  factory: () => TwilioChat.Client.create,
});

export const TWILIO_TOKEN = new InjectionToken<Promise<TwilioChat.Client>>('Manually constructed Twilio Service', {
  providedIn: 'root',
  factory: () => _initTwilio(inject(ChatUserService), inject(CREATE_CLIENT_METHOD)),
});

export function _initTwilio(
  _chatUserService: ChatUserService,
  createClient: twilioClientFactory
): Promise<TwilioChat.Client> {
  let client: TwilioChat.Client = null;
  return new Promise<TwilioChat.Client>(resolve => {
    _chatUserService.localUserChatToken$
      .pipe(filter(chatToken => chatToken && chatToken.length > 0))
      .subscribe(async (chatToken: string) => {
        debug('initialize twilio', chatToken);
        try {
          if (client) {
            await client.updateToken(chatToken);
            return;
          }

          client = await createClient(chatToken, { logLevel: 'error' });
          debug('... initialized twilio!');
          return resolve(client);
        } catch (err) {
          console.error('twilio init error', err);
        }
      });
  });
}
