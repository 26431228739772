import { Pipe, PipeTransform } from '@angular/core';

import { UsersComponentModel } from '../../../business/user/users.component.model';

@Pipe({
  name: 'username',
})
export class UsernamePipe implements PipeTransform {
  constructor(private _userModel: UsersComponentModel) {}
  transform(value: string) {
    if (!value) {
      return;
    }
    return new Promise<string>(resolve => {
      this._userModel.getAll().subscribe(users => {
        const user = users.find(user1 => user1._id === value);
        if (!user) {
          resolve('Unbekannter Benutzer');
          return;
        }
        resolve(`${user.firstName}  ${user.lastName}`);
      });
    });
  }
}
