import { IUser } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { UserDto } from 'src/app/shared/models/user-management/user-dto.model';

import { UsersDB } from '../../common/repository/databases';
import { Changes } from '../../common/tracking';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class UsersWorkItem extends WorkItem<UserDto> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
    this.deletable = false;
  }
  get name(): string {
    return 'UsersWorkItem';
  }
  get database(): string {
    return UsersDB;
  }

  public async beforeUpdate(item: IUser, changes: Changes): Promise<IUser> {
    if ('password' in item) {
      delete (item as any).password;
    }
    return item;
  }
}
