import { Inject, Injectable } from '@angular/core';
import { CommandBroker } from 'src/app/commands/command-broker';
import { ICommandBroker } from 'src/app/commands/contracts/command-broker';
import { IQueryService } from 'src/app/shared/services/contracts/query/query-service';
import { QueryService } from 'src/app/shared/services/query/query.service';

import { IAppController } from '../contracts/controller/app-controller';
import { IStateRegistry } from '../contracts/state/state-registry';
import { AppController } from '../controller/app-controller';
import { StateRegistry } from '../state/state-registry';

@Injectable({ providedIn: 'root' })
export class ComponentModelDependencies {
  constructor(
    @Inject(CommandBroker) private _commandBroker: ICommandBroker,
    @Inject(QueryService) private _queryService: IQueryService,
    @Inject(AppController) private _appController: IAppController,
    @Inject(StateRegistry) private _stateRegistry: IStateRegistry,
  ) {}

  public get appController(): IAppController {
    return this._appController;
  }

  public get queryService(): IQueryService {
    return this._queryService;
  }

  public get stateRegistry(): IStateRegistry {
    return this._stateRegistry;
  }

  public get commandBroker(): ICommandBroker {
    return this._commandBroker;
  }
}
