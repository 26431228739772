import { NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Requestor, StorageBackend } from '@openid/appauth';
import { AuthService, Browser, ConsoleLogObserver } from 'ionic-appauth';
import { environment } from 'src/environments/environment';

export class AuthServiceWrapper extends AuthService {
  async removeToken(): Promise<void> {
    await this.storage.removeItem('token_response');
  }
}

export let authFactory = (
  platform: Platform,
  ngZone: NgZone,
  requestor: Requestor,
  browser: Browser,
  storage: StorageBackend
) => {
  const authService = new AuthServiceWrapper(browser, storage, requestor);
  authService.authConfig = environment.authCodeFlowConfigApp;

  if (!platform.is('cordova')) {
    authService.authConfig.redirect_url = window.location.origin + '/auth/callback';
    authService.authConfig.end_session_redirect_url = window.location.origin + '/auth/endsession';
  }

  if (platform.is('cordova')) {
    (window as any).handleOpenURL = callbackUrl => {
      ngZone.run(() => {
        if (callbackUrl.indexOf(authService.authConfig.redirect_url) === 0) {
          authService.authorizationCallback(callbackUrl);
        } else {
          authService.endSessionCallback();
        }
      });
    };
  }

  authService.addActionObserver(new ConsoleLogObserver());
  return authService;
};
