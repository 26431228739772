import { Permission } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthService } from '../shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ChatGuard implements CanActivate {
  constructor(private readonly _auth: AuthService) {}

  public canActivate(): Observable<boolean> {
    return this._auth.authenticatedEventPublisher.pipe(
      filter(auth => auth.isAuthenticated),
      map(auth => this._auth.userHasPermission('chat', Permission.DeleteArchive))
    );
  }
}
