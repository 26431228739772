import { IArticle, IGroup, IPatient, IRegion } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';

import {
  AttributeTemplateModelName,
  DeviceModelName,
  DoctorModelName,
  ErpOrderModelName,
  GroupModelName,
  HospitalModelName,
  InsuranceContractModelName,
  NursingHomeModelName,
  NursingServiceModelName,
  PatientModelName,
  PayerModelName,
  PharmacyModelName,
  PostalCodeModelName,
  ProductGroupModelName,
  RegionModelName,
  StandardCareProposalModelName,
} from '../models/model-names';
import { ArticleModelName } from './../models/model-names';
import { IGeneralEntityService } from './contracts/general-entity.service';
import { IQueryService } from './contracts/query/query-service';
import { IProviderConfig, ProviderConfigToken } from './provider-config';
import { QueryService } from './query/query.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralEntityService implements IGeneralEntityService {
  private restrictors = {};
  public restriction: any;

  public responsibleTypes: string[] = [
    DoctorModelName,
    InsuranceContractModelName,
    HospitalModelName,
    NursingHomeModelName,
    NursingServiceModelName,
    PayerModelName,
    PostalCodeModelName,
    GroupModelName,
    RegionModelName,
    ProductGroupModelName,
    PharmacyModelName,
    ErpOrderModelName,
    DeviceModelName,
    StandardCareProposalModelName,
    AttributeTemplateModelName,
    PatientModelName,
    ArticleModelName,
  ];
  public type:
    | 'doctor'
    | 'hospital'
    | 'insuranceContract'
    | 'nursingHome'
    | 'nursingService'
    | 'payer'
    | 'postalCode'
    | 'group'
    | 'region'
    | 'productGroup'
    | 'pharmacy'
    | 'erpOrder'
    | 'device'
    | 'standardCareProposal'
    | 'attributeTemplate'
    | 'patient';

  constructor(
    @Inject(QueryService) private _queryService: IQueryService,
    @Inject(ProviderConfigToken) private _providerConfig: IProviderConfig
  ) {
    this.setRestrictions();
  }

  public clone(): IGeneralEntityService {
    return new GeneralEntityService(this._queryService, this._providerConfig);
  }

  public isRestricted(item: any): boolean {
    const restrictor = this.restrictors[this.type];
    if (!restrictor) {
      return false;
    }
    return restrictor(item);
  }

  public getAll(): Promise<any[]> {
    return this._queryService.getAll<any>(this._providerConfig[this.type].database);
  }

  public find(id: string): Promise<any> {
    return this._queryService.get<any>(id, this._providerConfig[this.type].database);
  }

  public query(query: string): Promise<any> {
    return this._queryService.search<any>({ query }, this._providerConfig[this.type].database);
  }

  private setRestrictions() {
    this.restrictors[PatientModelName] = (item: IPatient) => {
      if (typeof this.restriction === 'function') {
        return this.restriction(item);
      }
    };

    this.restrictors[GroupModelName] = (item: IGroup) => {
      if (typeof this.restriction === 'function') {
        return this.restriction(item);
      }
    };

    this.restrictors[RegionModelName] = (item: IRegion) => {
      if (typeof this.restriction === 'function') {
        return this.restriction(item);
      }
    };

    this.restrictors[ArticleModelName] = (item: IArticle) => {
      if (typeof this.restriction === 'function') {
        return this.restriction(item);
      }
    };

    this.restrictors[PayerModelName] = (item: any) =>
      item && this.restriction && item.type && this.restriction.type && item.type !== this.restriction.type;

    this.restrictors[ProductGroupModelName] = (item: any) => {
      if (typeof this.restriction === 'function') {
        return this.restriction(item);
      }
      return item && this.restriction && item.type && this.restriction.type && item.type === this.restriction.type;
    };

    this.restrictors[InsuranceContractModelName] = (item: any) => {
      if (typeof this.restriction === 'function') {
        return this.restriction(item);
      }
      return (
        item &&
        this.restriction &&
        item.therapyId &&
        this.restriction.therapyId &&
        item.therapyId.toString() !== this.restriction.therapyId.toString()
      );
    };
  }
}
