import {
  IDuration,
  IInsuranceContract,
  IProductGroupQuantity,
  IRequiredTemplate,
  ISalesContract,
  IVisitInterval,
  PricingModelType,
} from '@alberta/konexi-shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class InsuranceContractDto extends AggregateRoot implements IInsuranceContract, ISearchable {
  therapyTypeIds: number[];
  name: string;
  therapyId: number;
  validFrom: Date;
  validTo: Date;
  description: string;
  salesContracts: ISalesContract[];
  insuranceContractName: string;
  payerIds: string[];
  notPayingPayerIds: string[];
  isSelfPayer: boolean;
  pricingModelType: PricingModelType;
  pricingBasis: string;
  billingType: number;
  productGroupIds: string[];
  costEstimateRequired: boolean;
  periodOnPrescriptionNecessary: boolean;
  maximumAmountForProductGroups: IProductGroupQuantity[];
  visitInterval: IVisitInterval;
  maxPermanentPrescriptionPeriod: IDuration;
  approvalObligation: boolean;
  flatChargeTextAllowed: boolean;
  ageLimit: number;
  ageLimitLower: number;
  requiredTemplates: IRequiredTemplate[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  tenant: string;
  contractArticleGroupIds: string[];

  constructor() {
    super();

    this.salesContracts = [];
    this.requiredTemplates = [];
    this.maximumAmountForProductGroups = [];
    this.productGroupIds = [];
    this.payerIds = [];
  }

  get displayText(): string {
    return this.name;
  }
}
