import { CareProposalStatus, InstitutionType, NoteType } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import makeDebug from 'debug';
import { cloneDeep } from 'lodash';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { MessagePayload } from 'src/app/shared/models/share/message-payload';
import { environment } from 'src/environments/environment';

import { ChatConnectionStateService } from './chat/chat-connection-state.service';
import { ChatChannel } from './chat/data/db-schema';
import { ChatChannelDetail } from './chat/model/chat.model';
import { ConnectionStateService } from './connection-state.service';
import { IConnectionStateService } from './contracts/sync/connection-state-service';

const debug = makeDebug('services:tracker');

/**
 * Action event state for every cancelable user interaction
 */
export enum ActionEvent {
  Start = 'Start',
  Done = 'Done',
  Cancel = 'Cancel',
}

/**
 * Share event type for every share action that somehow transfers an object
 * from the user to somewhere else
 */
export enum ShareEventType {
  Chat = 'Chat',
  Fax = 'Fax',
  Email = 'Email',
  Download = 'Download',
  Print = 'Print',
}

export enum ActivityType {
  Unknown,
  Patient,
  PatientList,
  NursingHome,
}

export class ScheduledLogEvent {
  private timeoutHandler: NodeJS.Timeout;

  constructor(timeoutHandler: NodeJS.Timeout) {
    this.timeoutHandler = timeoutHandler;
  }

  public cancel() {
    global.clearTimeout(this.timeoutHandler);
  }
}

@Injectable({ providedIn: 'root' })
export class TrackerService {
  private isTrackingEnabled = true;
  private _isAuthOnline = false;
  tracker: amplitude.AmplitudeClient;
  constructor(
    private readonly _chatConnectionStateService: ChatConnectionStateService,
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync
  ) {
    this.tracker = amplitude.getInstance();
    this.tracker.init(environment.amplitude.apiKey);
  }

  public set isAuthOnline(isOnline: boolean) {
    debug('set auth online status', isOnline);
    this._isAuthOnline = isOnline;
  }

  public identify(userId: string, email: string, organization: any) {
    debug('identify user', { userId, email, organization });
    this.tracker.setUserId(userId);
    this.tracker.setUserProperties({
      email,
    });
    if (organization) {
      this.tracker.setUserProperties({
        tenantId: organization.tenantId,
        organizationName: organization.name,
        organizationId: organization._id,
        withSync: this._platformSync.canBeSynced,
        modules: organization.modules,
      });
    }
  }

  public setVersion(release: string, version: string) {
    debug('set release and version', release, version);
    this.tracker.setVersionName(release + '#' + version);
  }

  public clearIdentify() {
    debug('clear identify');
    this.tracker.clearUserProperties();
  }

  public setTrackingEnabled(enabled: boolean) {
    debug('set tracking status', enabled);
    this.isTrackingEnabled = enabled;
  }

  /**
   * Default log event for Amplitude Service
   */
  private logEvent(name: string, params: any = null) {
    if (!this.isTrackingEnabled) {
      return;
    }

    let populatedParams = cloneDeep(params);
    if (!populatedParams) {
      populatedParams = {};
    }
    populatedParams.isAuthOnline = this._isAuthOnline;
    populatedParams.isChatOnline = this._chatConnectionStateService.isOnline;
    populatedParams.isAgentChatOnline = this._chatConnectionStateService.isAgentChatOnline;
    populatedParams.isConnected = this._connectionStateService.isConnected;
    populatedParams.withSync = this._platformSync.canBeSynced;

    global.setTimeout(() => {
      try {
        debug('log event', name, populatedParams);
        this.tracker.logEvent(name, populatedParams);
      } catch (err) {
        window.logger.error('user tracking error', err);
      }
    });
  }

  /**
   * Schedule a log event for Amplitude Service. This log event is scheduled to log after
   * a certain delay, if no canceled explicitely
   */
  private scheduleLogEvent(name: string, params: any = null, delay: number = 1000): ScheduledLogEvent {
    if (!this.isTrackingEnabled) {
      return;
    }

    const timeoutHandler = global.setTimeout(() => {
      this.logEvent(name, params);
    }, delay);

    return new ScheduledLogEvent(timeoutHandler);
  }

  /* SYNC */

  public trackSyncOpen() {
    this.logEvent('sync:open');
  }

  public trackSyncDetail() {
    this.logEvent('sync:detail');
  }

  public trackSyncClose() {
    this.logEvent('sync:close');
  }

  public trackSyncFinished() {
    this.logEvent('sync:finished');
  }

  /* DASHBOARD */
  public trackShowDashboard(actionEvent: ActionEvent) {
    this.logEvent('dashboard:show', { actionEvent });
  }

  public trackDashboardOpenPatient() {
    this.logEvent('dashboard:clicked-patient');
  }

  /* NOTIFICATIONS */

  public trackNotificationsOpen(actionEvent: ActionEvent) {
    this.logEvent('notifications:open', { actionEvent });
  }

  public trackNotificationsDelete(destination: string) {
    this.logEvent('notifications:action:delete', { destination });
  }

  public trackNotificationsNavigateTo(destination: string) {
    this.logEvent('notifications:action:navigate-to', { destination });
  }

  /* AUTH */
  public trackLogin() {
    this.logEvent('auth:login');
  }

  public trackLogout() {
    this.logEvent('auth:logout');
  }

  public trackLoginWrongPassword() {
    this.logEvent('auth:wrong-password');
  }

  /* PATIENT-LIST */
  public trackShowPatientList(actionEvent: ActionEvent) {
    this.logEvent('patient-list:show', { actionEvent });
  }

  public trackPatientListSearch() {
    this.logEvent('patient-list:search');
  }

  public trackPatientSortOpen() {
    this.logEvent('patient-list:sort-open');
  }

  public trackPatientSetSort(sort: object) {
    this.logEvent('patient-list:sort', sort);
  }

  public trackPatientFilterOpen() {
    this.logEvent('patient-list:filter-open');
  }

  public trackPatientSetFilter(filter: object) {
    this.logEvent('patient-list:filter-set', filter);
  }

  public trackPatientClearFilter() {
    this.logEvent('patient-list:filter-clear');
  }

  public trackAllPatientsOpen(actionEvent: ActionEvent) {
    this.logEvent('patient-list:allPatients', { actionEvent });
  }

  public trackMyPatientsOpen(actionEvent: ActionEvent) {
    this.logEvent('patient-list:myPatients', { actionEvent });
  }

  public trackChatOpen(actionEvent: ActionEvent) {
    this.logEvent('chat:open', { actionEvent });
  }

  /* EDIT-PATIENT-DIALOG */
  public trackShowEditPatientDialog(isNew: boolean) {
    this.logEvent('edit-patient-dialog:open', { isNew });
  }

  public trackSaveEditPatientDialog() {
    this.logEvent('edit-patient-dialog:save');
  }

  public trackCancelEditPatientDialog(hasUnsavedChanges: boolean) {
    this.logEvent('edit-patient-dialog:cancel', { hasUnsavedChanges });
  }

  /* PATIENT DASHBOARD */
  public trackPatientDashboardLoadingPage(event: ActionEvent, duration?: number): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:dashboard:load', { event, duration });
  }

  /* PATIENT MASTERDATA */
  public trackPatientMasterdataOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:masterdata:open', { actionEvent });
  }

  public trackPatientMasterdataActionEdit() {
    this.logEvent('patient:masterdata:action:edit');
  }

  /* PATIENT ATTACHMENTS */
  public trackPatientAttachmentsOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:attachments:open', { actionEvent });
  }

  public trackPatientAttachmentsActionAdd(event: ActionEvent) {
    this.logEvent('patient:attachments:action:add', { event });
  }

  public trackPatientAttachmentsOpenAttachment() {
    this.logEvent('patient:attachments:open-attachment');
  }

  /* ATTACHMENT */
  public trackAttachmentShow(actionEvent: ActionEvent) {
    this.logEvent('patient:attachment:show', { actionEvent });
  }

  public trackAttachmentActionShare(event: ActionEvent) {
    this.logEvent('patient:attachment:action:share', { event });
  }

  public trackAttachmentActionEMail() {
    this.logEvent('patient:attachment:action:email');
  }

  public trackAttachmentActionDownload() {
    this.logEvent('patient:attachment:action:download');
  }

  public trackAttachmentActionFax(event: ActionEvent) {
    this.logEvent('patient:attachment:action:fax', { event });
  }

  public trackAttachmentChangeTherapy(actionEvent: ActionEvent) {
    this.logEvent('patient:attachment:action:change-therapy', { actionEvent });
  }

  /* PATIENT INSTITUTIONS */

  public trackPatientInstitutionsOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:institutions:open', { actionEvent });
  }

  public trackPatientInstitutionDetail(institutionType: string, institutionId: string) {
    this.logEvent('patient:institution:detail', { institutionType, institutionId });
  }

  public trackPatientInstitutionEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:institution:action:edit', { actionEvent });
  }

  /* PATIENT CONTACTS */

  public trackPatientContactsOpen(): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:contacts:open');
  }

  public trackPatientContactDetail() {
    this.logEvent('patient:contact:detail');
  }

  public trackPatientContactEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:contact:action:edit', { actionEvent });
  }

  /* PATIENT DOCUMENTS */

  public trackPatientDocumentsOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:documents:open', { actionEvent });
  }

  public trackPatientDocumentsSort(sort: object) {
    this.logEvent('patient:documents:sort', { sort });
  }

  public trackPatientDocumentsAdd(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:action:add', { actionEvent });
  }

  public trackPatientDocumentsShare(actionEvent: ActionEvent, shareEventTpe: ShareEventType) {
    this.logEvent('patient:documents:action:fax', { actionEvent, shareEventTpe });
  }

  public trackPatientDocumentsStatus(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:action:status', { actionEvent });
  }

  public trackPatientDocumentsMultiselect(enable: boolean) {
    this.logEvent('patient:documents:action:multiselect', { enable });
  }

  public trackPatientDocumentsSave(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:edit:save', { actionEvent });
  }

  public trackPatientDocumentsCompletedOnSave() {
    this.logEvent('patient:documents:edit:completedonsave');
  }

  public trackPatientDocumentsNotCompletedOnSave() {
    this.logEvent('patient:documents:edit:notcompletedonsave');
  }

  public trackPatientDocumentsSendEmailOnComplete(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:edit:email:sendoncomplete', { actionEvent });
  }

  public trackPatientDocumentsSendEmailAdressesSet() {
    this.logEvent('patient:documents:edit:email:adressesset');
  }

  public trackPatientDocumentOpen(actionEvent: ActionEvent) {
    this.logEvent('patient:document:open', { actionEvent });
  }

  public trackPatientDocumentStatus(
    actionEvent: ActionEvent,
    isCompleted?: boolean | undefined,
    isArchived?: boolean | undefined
  ) {
    this.logEvent('patient:document:action:status', { actionEvent, isCompleted, isArchived });
  }

  public trackPatientDocumentOpenReport() {
    this.logEvent('patient:document:open-report');
  }

  public trackPatientDocumentCopy(actionEvent: ActionEvent) {
    this.logEvent('patient:document:action:copy', { actionEvent });
  }

  public trackPatientDocumentEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:document:action:edit', { actionEvent });
  }

  public trackPatientDocumentShare(actionEvent: ActionEvent, shareEventType: ShareEventType) {
    this.logEvent('patient:document:action:share', { actionEvent, shareEventType });
  }

  public trackPatientDocumentConvertDocument(actionEvent: ActionEvent) {
    this.logEvent('patient:document:action:convert-document', { actionEvent });
  }

  public trackPatientDocumentReportShare(actionEvent: ActionEvent, shareEventType: ShareEventType) {
    this.logEvent('patient:document:report:action:share', { actionEvent, shareEventType });
  }

  public trackPatientDocumentReportPrint(actionEvent: ActionEvent, shareEventType: ShareEventType) {
    this.logEvent('patient:document:report:action:print', { actionEvent, shareEventType });
  }

  /* PATIENT ERP */

  public trackPatientErpOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:erp:open', { actionEvent });
  }

  /* PATIENT Notes */

  public trackPatientNotesOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:notes:open', { actionEvent });
  }
  public trackPatientNoteAdd(actionEvent: ActionEvent, isNew?: boolean, type?: NoteType) {
    this.logEvent('patient:note:add', { actionEvent, isNew, type });
  }

  public trackPatientNoteDetail(id: string) {
    this.logEvent('patient:note:detail', { id });
  }

  public trackPatientNoteEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:note:edit', { actionEvent });
  }

  public trackPatientNoteDelete(type?: NoteType) {
    this.logEvent('patient:note:edit', { type });
  }

  /* PATIENT Device */
  public trackPatientDeviceOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:device:open', { actionEvent });
  }

  public trackDeviceDetail(id: string) {
    this.logEvent('patient:device:detail', { id });
  }

  /* PATIENT ERP Task */
  public trackPatientErpTaskOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:erpTask:open', { actionEvent });
  }

  public trackErpTaskDetail(id: string) {
    this.logEvent('patient:erpTask:detail', { id });
  }

  /* Fax Protocol */
  public trackFaxProtocolOpen(actionEvent: ActionEvent) {
    this.logEvent('fax:protocol:open', { actionEvent });
  }

  public trackFaxProtocolSearch() {
    this.logEvent('fax:protocol:search');
  }

  public trackFaxProtocolFilterOpen() {
    this.logEvent('fax:protocol:filter-open');
  }

  public trackFaxProtocolFilter(filter: object) {
    this.logEvent('fax:protocol:filter-set', filter);
  }

  public trackFaxProtocolFilterClear() {
    this.logEvent('fax:protocol:filter-clear');
  }

  public trackFaxProtocolShowMoreCount(count: number) {
    this.logEvent('fax:protocol:show-more', { count });
  }

  public trackFaxProtocolDetail() {
    this.logEvent('fax:protocol:detail');
  }

  public trackFaxProtocolResend(actionEvent: ActionEvent) {
    this.logEvent('fax:protocol:action:resend', { actionEvent });
  }

  /* PRODUCTS */

  public trackProductsArticleOpen() {
    this.logEvent('products:article:open');
  }

  public trackProductsArticleSearch() {
    this.logEvent('products:article:search');
  }

  public trackProductsArticleDetail() {
    this.logEvent('products:article:detail');
  }

  /* INSTITIONS */

  public trackInstitutionSearchOpen(institutionType: InstitutionType, actionEvent?: ActionEvent) {
    this.logEvent('institution:search:open', { institutionType, actionEvent });
  }

  public trackInstitutionSearch(institutionType: InstitutionType) {
    this.logEvent('institution:action:search', { institutionType });
  }

  public trackInstitutionDetail(institutionType: InstitutionType): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:detail:masterdata', { institutionType });
  }

  public trackInstitutionSubunits(institutionType: InstitutionType): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:detail:subunits', { institutionType });
  }

  public trackInstitutionSubunitsAdd(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:subunits:action:add', { institutionType, actionEvent });
  }

  public trackInstitutionSubunitsEdit(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:subunits:action:edit', { institutionType, actionEvent });
  }

  public trackInstitutionContacts(institutionType: InstitutionType): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:detail:contacts', { institutionType });
  }

  public trackInstitutionContactsAdd(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:contacts:action:add', { institutionType, actionEvent });
  }

  public trackInstitutionContactsEdit(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:contacts:action:edit', { institutionType, actionEvent });
  }

  public trackInstitutionNotes(institutionType: InstitutionType): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:detail:notes', { institutionType });
  }

  public trackInstitutionNotesAdd(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:notes:action:add', { institutionType, actionEvent });
  }

  public trackInstitutionNotesEdit(institutionType: InstitutionType, actionEvent: ActionEvent) {
    this.logEvent('institution:detail:notes:action:edit', { institutionType, actionEvent });
  }

  /* INSTITUTION DOCUMENTS */

  public trackInstitutionDocumentsOpen(actionEvent: ActionEvent): ScheduledLogEvent {
    return this.scheduleLogEvent('institution:documents:open', { actionEvent });
  }

  public trackInstitutionDocumentsAdd(actionEvent: ActionEvent) {
    this.logEvent('institution:documents:action:add', { actionEvent });
  }

  public trackInstitutionDocumentsShare(actionEvent: ActionEvent, shareEventTpe: ShareEventType) {
    this.logEvent('institution:documents:action:fax', { actionEvent, shareEventTpe });
  }

  public trackInstitutionDocumentOpen(actionEvent: ActionEvent) {
    this.logEvent('institution:document:open', { actionEvent });
  }

  public trackInstitutionDocumentStatus(
    actionEvent: ActionEvent,
    isCompleted?: boolean | undefined,
    isArchived?: boolean | undefined
  ) {
    this.logEvent('institution:document:action:status', { actionEvent, isCompleted, isArchived });
  }

  public trackInstitutionDocumentOpenReport() {
    this.logEvent('institution:document:open-report');
  }

  public trackInstitutionDocumentCopy(actionEvent: ActionEvent) {
    this.logEvent('institution:document:action:copy', { actionEvent });
  }

  public trackInstitutionDocumentEdit(actionEvent: ActionEvent) {
    this.logEvent('institution:document:action:edit', { actionEvent });
  }

  public trackInstitutionDocumentShare(actionEvent: ActionEvent, shareEventType: ShareEventType) {
    this.logEvent('institution:document:action:share', { actionEvent, shareEventType });
  }

  /* SETTINGS */

  public trackSettingsOpen() {
    this.logEvent('settings-general:open');
  }

  public trackSettingsContentOpen() {
    this.logEvent('settings-general:action:content-open');
  }

  public trackSettingsContentDelete(actionEvent: ActionEvent, storageName: string) {
    this.logEvent('settings-general:action:content-delete', { actionEvent, storageName });
  }

  public trackSettingsContentDeleteAll(actionEvent: ActionEvent) {
    this.logEvent('settings-general:action:content-delete-all', { actionEvent });
  }

  public trackSettingsIndexRepair(actionEvent: ActionEvent, storageName: string) {
    this.logEvent('settings-general:action:index-repair', { actionEvent, storageName });
  }

  /* HELP */

  public trackHelpOpen() {
    this.logEvent('help:open');
  }

  public trackHelpUpdateLink(url: string) {
    this.logEvent('help:action:update-link', { url });
  }

  /* CHAT */
  public trackChatShowChannels(numberOfChannels: number) {
    this.logEvent('chat:show-channels', { numberOfChannels });
  }

  /* CAREPROPOSAL */

  public trackCareproposalListOpen(actionEvent: ActionEvent) {
    this.logEvent('patient:careproposal:list:open', { actionEvent });
  }

  public trackCareproposalAdd(actionEvent: ActionEvent) {
    this.logEvent('patient:careproposal:action:add', { actionEvent });
  }

  public trackIntegratedCareProposalAdd(actionEvent: ActionEvent) {
    this.logEvent('patient:integratedCareproposal:action:add', { actionEvent });
  }

  public trackCareproposalDetail() {
    this.logEvent('patient:careproposal:detail');
  }

  public trackCareproposalSetState(actionEvent: ActionEvent, status: CareProposalStatus) {
    this.logEvent('patient:careproposal:action:set-state', { actionEvent, status });
  }
  public trackIntegratedCareSetState(actionEvent: ActionEvent, status: CareProposalStatus) {
    this.logEvent('patient:integratedCareproposal:action:set-state', { actionEvent, status });
  }

  public trackCareproposalEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:careproposal:action:edit', { actionEvent });
  }

  public trackIntegratedCareProposalEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:integratedCareproposal:action:edit', { actionEvent });
  }

  public trackCareproposalOrder(actionEvent: ActionEvent, orderType: string) {
    this.logEvent('patient:careproposal:action:order', { actionEvent, orderType });
  }

  public trackDeliveryInformationMismatch(actionEvent: ActionEvent, missMatchTyp: string) {
    this.logEvent('patient:order:deliveryInformationMismatch', { actionEvent, missMatchTyp });
  }

  public trackDeliveryInformationMismatchSave(actionEvent: ActionEvent) {
    this.logEvent('patient:order:deliveryInformationPatientSave', { actionEvent });
  }

  public trackChatOpenChannel(channel: ChatChannelDetail) {
    const params = {
      channelSid: channel.sid,
      uniqueName: channel.uniqueName,
      friendlyName: channel.friendlyName,
      memberCount: channel.members.length,
    };
    this.logEvent('chat:open-channel', params);
  }

  public trackChatCreateNewChannel(channel: ChatChannel, numberOfParticipants: number) {
    const params = { channelSid: channel.sid, numberOfParticipants };
    this.logEvent('chat:create-new-channel', params);
  }

  public trackChatSendMessage(channel: ChatChannelDetail, messageLength: number, emojis: any[]) {
    const params = {
      channelSid: channel.sid,
      uniqueName: channel.uniqueName,
      friendlyName: channel.friendlyName,
      memberCount: channel.members.length,
      messageLength,
      emojis,
    };
    this.logEvent('chat:send-message', params);
  }

  public trackErpOrderDetailsShown(erpOrderId: string, isExpaned: boolean) {
    const params = {
      erpOrderId,
      isExpaned,
    };
    this.logEvent('erpOrder:expandDetail', params);
  }

  public trackShare(payload: MessagePayload) {
    this.logEvent('chat:share-open', payload);
  }

  public trackShareCanceled(payload: MessagePayload) {
    this.logEvent('chat:share-canceled', payload);
  }

  public trackShareSent(payload: MessagePayload) {
    this.logEvent('chat:share-sent', payload);
  }

  public chatAdvancedMessageActionButtonClicked(payload: MessagePayload) {
    this.logEvent('chat:advanced-message:clicked-action-button', payload);
  }

  /* RETOURE */

  public trackReturnDeliveryCreated(actionEvent: ActionEvent, id: string) {
    this.logEvent('returnDelivery:action:created', { actionEvent, id });
  }

  public trackReturnDeliveryEdited(actionEvent: ActionEvent, id: string) {
    this.logEvent('returnDelivery:action:edited', { actionEvent, id });
  }

  public trackReturnDeliveryTransmitted(
    actionEvent: ActionEvent,
    id: string,
    hasArticles: boolean,
    hasComment: boolean
  ) {
    this.logEvent('returnDelivery:action:transmitted', { actionEvent, id, hasArticles, hasComment });
  }

  public trackReturnDeliveryDeleted(actionEvent: ActionEvent, id: string) {
    this.logEvent('returnDelivery:action:deleted', { actionEvent, id });
  }

  public trackReturnDeliverDetail() {
    this.logEvent('returnDelivery:detail');
  }

  /* PATIENT ACTIVITY */

  public trackPatientActivitiesOpen(actionEvent: ActionEvent, activityType: ActivityType) {
    this.scheduleLogEvent('patient:activities:open', { actionEvent, activityType });
  }

  public trackPatientActivityAdd(actionEvent: ActionEvent, activityType?: ActivityType) {
    this.logEvent('patient:activity:action:add', { actionEvent, activityType });
  }

  public trackPatientActivityEdit(actionEvent: ActionEvent, activityType?: ActivityType) {
    this.logEvent('patient:activity:action:edit', { actionEvent, activityType });
  }

  public trackPatientActivitiesShowCurrent() {
    this.logEvent('patient:activities:show:current');
  }

  public trackPatientActivitiesShowInPast() {
    this.logEvent('patient:activities:show:in-past');
  }

  public trackPatientActivityDetail(activityType: ActivityType) {
    this.logEvent('patient:activity:detail', { activityType });
  }

  /* Scanacs Zuzabe */
  public trackQueryCopayment(copaymentStatus: string, ikNumber: string) {
    this.logEvent('patient:activity:query-copayment', { copaymentStatus, ikNumber });
  }

  /* CALENDAR */
  public trackGrantAccessResult(result: boolean) {
    this.logEvent('calendar:grantAccessForOutlook', { accessGranted: result });
  }

  /* ErpTask */
  public trackErpTaskDueDateChanged() {
    this.logEvent('erpTask:dueDateChanged');
  }
  public trackErpTaskCreateDocument() {
    this.logEvent('erpTask:createDocument');
  }

  public trackErpTaskCreateAttachment() {
    this.logEvent('erpTask:createAttachment');
  }

  /* IntegratedCare */
  public trackIntegratedCareDetail() {
    this.logEvent('patient:integratedcare:detail');
  }

  public trackIntegratedCareOrder(actionEvent: ActionEvent) {
    this.logEvent('patient:integratedcare:order', { actionEvent });
  }

  /* Reminder */
  public trackReminderCreated(actionEvent: ActionEvent, id: string) {
    this.logEvent('reminder:action:created', { actionEvent, id });
  }

  public trackReminderEdited(actionEvent: ActionEvent, id: string) {
    this.logEvent('reminder:action:edited', { actionEvent, id });
  }

  public trackDocumentPreventCopy(actionEvent: ActionEvent) {
    this.logEvent('patient:documents:copy', { actionEvent });
  }
}
