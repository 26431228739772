import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PatientAppUserChannelDB } from 'src/app/common/repository/databases';
import { AttachmentDto } from '../../models/attachment/attachment-dto.model';

import { IPatientAppUser } from '../../models/patient-app/patient-app-user';
import { ChatDbService } from '../chat/data/chat-db.service';
import { ChatChannel } from '../chat/data/db-schema';
import { Chat } from '../chat/model/chat-instance';
import { IDatabaseService } from '../contracts/database/database-service';
import { IGenericStorage } from '../contracts/database/generic-storage';
import { DatabaseService } from '../database.service';
import { FeathersService } from '../feathers.service';
import { PatientAppUserSynchronizer } from './patient-app-user-synchronizer';

@Injectable({ providedIn: 'root' })
export class PatientAppService {
  private _patients$ = new BehaviorSubject<IPatientAppUser[]>([]);
  private _channels: Record<string, ChatChannel> = {};
  private _patientAppUserChannelDb: IGenericStorage;

  public get patients(): Observable<IPatientAppUser[]> {
    return this._patients$.asObservable();
  }

  constructor(
    @Inject(DatabaseService) private readonly _databaseService: IDatabaseService,
    private _patientAppUserSynchronizer: PatientAppUserSynchronizer,
    private _feathersService: FeathersService,
    private readonly _chatDbService: ChatDbService
  ) {}

  public async init() {
    this._patientAppUserChannelDb = await this._databaseService.getDatabase(PatientAppUserChannelDB);

    await this._patientAppUserChannelDb.ready();

    const channels$ = await this._chatDbService.getChannels(Chat.PatientApp);

    combineLatest([channels$, this._patientAppUserSynchronizer.patientAppSyncReady])
      .pipe(
        map(([channels]) => channels),
        filter(channels => Boolean(channels && channels.length)),
        tap(channels => channels.forEach(channel => (this._channels[channel.sid] = channel))),
        switchMap(() =>
          of(
            Object.values(this._channels).filter(channel => channel.attributes && channel.attributes['isOpen'] === true)
          )
        ),
        switchMap(
          (channels: ChatChannel[]) =>
            new Promise<IPatientAppUser[]>(async resolve => {
              const patientAppUsers = await this._patientAppUserChannelDb.getItems(
                channels.map(channel => channel.sid)
              );
              resolve(patientAppUsers && patientAppUsers.length ? patientAppUsers : []);
            })
        )
      )
      .subscribe((patientAppUsers: IPatientAppUser[]) => this._patients$.next(patientAppUsers));
  }

  public async getAttachements(patientId: string, tenantId: string): Promise<IAttachment[]> {
    const attachmentService = this._feathersService.getService<IAttachment>('gcp-attachment');
    const result = [].concat(
      await attachmentService.find({ patientId: patientId, tenantid: tenantId, type: 'patientapp' })
    );
    return result;
  }
}

export interface IAttachment {
  metadata: IAttachmentMetadata;
}

export interface IAttachmentMetadata {
  originalname: string;
  patientId: string;
  tenantId: string;
  documentType: PatientDocumentType;
  fileName: string;
  createdAt: string;
  updated: string;
}

type PatientDocumentType = 'Copayment' | 'PatientAgreement';
