import { IAudit } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { AuditChannel } from 'src/app/business/swodoc/contracts/audit-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { AuditModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class AuditSyncService extends SyncService<IAudit> {
  protected limit = 100;

  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IAudit>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(AuditModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(channel: string): boolean {
    return channel === AuditChannel.CREATE || channel === AuditChannel.EDIT || channel === AuditChannel.DELETE;
  }
}
