<ion-content>
  <div class="syncDismissBtn" (click)="dismiss()"><ion-icon name="alberta-cancel"></ion-icon></div>
  <ion-slides #slider style="height: 100%;" *ngIf="showIndicator">
    <ion-slide class="wrapper">
      <div class="motto-wrapper" [ngStyle]="style">
        <div class="motto">
          <div class="mottoHeader">
            <div>{{ 'sync.quote' | translate }}</div>
            <div>{{ 'sync.ofTheDay' | translate }}</div>
          </div>
          <div class="mottoQuoteText">"{{ motto.quoteText }}"</div>
          <div class="mottoAuthor">&mdash; {{ motto.author }}</div>
        </div>
        <div class="loadingIndicatorMotto" (click)="goToDataPage()">
          <ion-spinner name="circles" color="light"></ion-spinner> {{ 'sync.syncStatus' | translate }}
          <ion-icon name="alberta-arrow-right"></ion-icon>
        </div>
      </div>
    </ion-slide>

    <ion-slide class="progress">
      <div class="progress-wrapper">
        <div class="syncItem-wrapper">
          <div class="syncItem" *ngFor="let progressItem of progressItems | progressItemSortPipe">
            <div class="syncItemTitle">{{ 'entities.' + progressItem.title | translate }}</div>
            <div class="syncItemProgress">
              <ion-spinner
                name="circles"
                color="light"
                *ngIf="progressItem.progress < progressItem.total"
              ></ion-spinner>
              {{ progressItem.progress }} / {{ progressItem.total }}
            </div>
          </div>
        </div>
        <div class="loadingIndicatorProgress" (click)="goToMottoPage()">
          <ion-icon name="alberta-arrow-left"></ion-icon> {{ 'sync.toTheQuote' | translate }}
        </div>
      </div>
    </ion-slide>
  </ion-slides>
</ion-content>
