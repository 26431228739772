import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessagePayload } from 'src/app/shared/models/share/message-payload';

export interface MessageClickedEvent {
  type: 'action';
  payload: MessagePayload;
}
@Component({
  selector: 'itl-chat-message-component',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent {
  @Input() text: string;
  @Input() messageTime: string;
  @Input() authorName: string;
  @Input() showNames: boolean;
  @Input() changedMemberSid: boolean;
  @Input() colorHex: string;
  @Input() type: 'local' | 'remote';
  @Input() status: string;
  @Input() payload: MessagePayload;
  @Output() messageClicked = new EventEmitter<MessageClickedEvent>();

  public customStyle: object;
  public isAdvancedMessage: boolean;

  public actionButtonClicked() {
    this.messageClicked.emit({ type: 'action', payload: this.payload });
  }
}
