import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputTemplatesModule } from '../../_templates/input-templates.module';
import { ArticleListItem } from './article-list-item/article-list-item';
import { AttributeTemplateItem } from './attribute-template-list-item/attribute-template-list-item';
import { DoctorListItem } from './doctor-list-item/doctor-list-item';
import { FieldNurseListItem } from './field-nurse-list-item/field-nurse-list-item';
import { GroupListItem } from './group-list-item/group-list-item';
import { HospitalListItem } from './hospital-list-item/hospital-list-item';
import {
  InstitutionContactFunctionListItem,
} from './institution-contact-function-list-item/institution-contact-function-list-item';
import { InsuranceContractListItem } from './insurance-contract-list-item/insurance-contract-list-item';
import { NursingHomeListItem } from './nursing-home-list-item/nursing-home-list-item';
import { NursingServiceListItem } from './nursing-service-list-item/nursing-service-list-item';
import { PatientTemplateItem } from './patient-template-list-item/patient-template-list-item';
import { PayerListItem } from './payer-list-item/payer-list-item';
import { PharmacyListItem } from './pharmacy-list-item/pharmacy-list-item';
import { PostalCodeListItem } from './postal-code-list-item/postal-code-list-item';
import { ProductGroupListItem } from './product-group-list-item/product-group-list-item';
import { RegionListItem } from './region-list-item/region-list-item';
import { StandardCareProposalListItem } from './standard-care-proposal/standard-care-proposal-list-item';
import { TemplateTagTemplateItem } from './templatetag-template-list-item/templatetag-template-list-item';

@NgModule({
  declarations: [
    PharmacyListItem,
    FieldNurseListItem,
    NursingHomeListItem,
    NursingServiceListItem,
    HospitalListItem,
    DoctorListItem,
    PostalCodeListItem,
    GroupListItem,
    RegionListItem,
    ProductGroupListItem,
    InsuranceContractListItem,
    PayerListItem,
    StandardCareProposalListItem,
    AttributeTemplateItem,
    PatientTemplateItem,
    TemplateTagTemplateItem,
    InstitutionContactFunctionListItem,
    ArticleListItem,
  ],
  imports: [CommonModule, InputTemplatesModule],
  exports: [
    GroupListItem,
    InsuranceContractListItem,
    PayerListItem,
    ProductGroupListItem,
    StandardCareProposalListItem,
    AttributeTemplateItem,
    TemplateTagTemplateItem,
  ],
})
export class AutocompleteListItemsModule {}
