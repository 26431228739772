import { Injectable, NgZone } from '@angular/core';
import { clamp, find, last, sortBy } from 'lodash';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, take, takeWhile } from 'rxjs/operators';

import makeDebug from '../../../../makeDebug';
import { AuthService } from '../auth.service';
import { ChatConnectionStateService } from './chat-connection-state.service';
import { ChatSendService } from './chat-send.service';
import { ChatService } from './chat.service';
import { ChatDbService } from './data/chat-db.service';
import { ChatChannel, ChatChannelConsumptionStatus } from './data/db-schema';
import { Chat } from './model/chat-instance';

const debug = makeDebug('services:chat:chat-messages-status');

interface IChannelConsumptionRelation {
  channel: ChatChannel;
  unreadMessages: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChatMessagesStatusService {
  private readonly _unreadMessagesSubject$ = new ReplaySubject<number>();
  private readonly _unreadAgentMessagesSubject$ = new ReplaySubject<number>();

  constructor(
    private readonly _authService: AuthService,
    private readonly _chatDb: ChatDbService,
    private readonly _chatConnectionState: ChatConnectionStateService,
    private readonly _chatSendService: ChatSendService,
    private readonly _chatService: ChatService,
    private readonly _ngZone: NgZone
  ) {
    this._authService.authenticatedEventPublisher.pipe(takeWhile(authEvent => !authEvent.isAuthenticated)).subscribe({
      complete: () => {
        this._initUnreadWatcher()
          .then(() => this._initAgentUnreadWatcher())
          .then(() => this._initSyncConsumptionStatus());
      },
    });
  }

  public getTotalUnreadMessagesCount(): Observable<number> {
    return this._unreadMessagesSubject$.asObservable();
  }

  public getTotalUnreadAgentMessagesCount(): Observable<number> {
    return this._unreadAgentMessagesSubject$.asObservable();
  }

  private _initSyncConsumptionStatus() {
    this._ngZone.runOutsideAngular(() =>
      setInterval(() => this.syncChatConsumptionStatus().then(() => this.syncAgentConsumptionSatus()), 10000)
    );
  }

  private async syncAgentConsumptionSatus() {
    debug('periodic sync of consumption status');
    if (this._chatConnectionState.isAgentChatOnline) {
      const allConsumptions = await this._chatDb.getAllLocalConsumptions();
      debug('all consumptions', allConsumptions);
      const promises = allConsumptions.map(async consumption => {
        try {
          const channel = await this._chatDb.getChannel(consumption._id);
          if (!channel || !channel.isAgent) {
            return;
          }

          const localIndexHigher = this.isLocalIndexHigher(channel, consumption);
          debug('check for higher local index', { channel, consumption, localIndexHigher });
          if (localIndexHigher) {
            try {
              await this._chatSendService.setAgentConsumptionIndex(
                consumption._id,
                consumption.localLastConsumedMessageIndex
              );
            } catch (err) {
              if (err.status === 404) {
                console.error('tried to update remote consumption index without access for channel', channel.sid, err);
                await this._chatDb.deleteLocalConsumptionOfChannel(channel.sid);
              } else {
                throw err;
              }
            }
          }
        } catch (error) {
          window.logger.error('could not sync consumption status', error);
        }
      });
      await Promise.all(promises);
    } else {
      debug('was offline. sync skipped');
    }
  }

  private async syncChatConsumptionStatus() {
    debug('periodic sync of consumption status');
    if (this._chatConnectionState.isOnline) {
      const allConsumptions = await this._chatDb.getAllLocalConsumptions();
      debug('all consumptions', allConsumptions);
      const promises = allConsumptions.map(async consumption => {
        try {
          const channel = await this._chatDb.getChannel(consumption._id);
          if (!channel || channel.isAgent) {
            return;
          }

          const localIndexHigher = this.isLocalIndexHigher(channel, consumption);
          debug('check for higher local index', { channel, consumption, localIndexHigher });
          if (localIndexHigher) {
            try {
              await this._chatSendService.setConsumptionIndex(
                consumption._id,
                consumption.localLastConsumedMessageIndex
              );
            } catch (err) {
              if (err.status === 404) {
                console.error('tried to update remote consumption index without access for channel', channel.sid, err);
                await this._chatDb.deleteLocalConsumptionOfChannel(channel.sid);
              } else {
                throw err;
              }
            }
          }
        } catch (error) {
          window.logger.error('could not sync consumption status', error);
        }
      });
      await Promise.all(promises);
    } else {
      debug('was offline. sync skipped');
    }
  }

  private isLocalIndexHigher(channel: ChatChannel, consumption: ChatChannelConsumptionStatus) {
    if (channel && channel.lastConsumedMessageIndex) {
      return channel.lastConsumedMessageIndex < consumption.localLastConsumedMessageIndex;
    }

    return 0 < consumption.localLastConsumedMessageIndex;
  }

  private async _initUnreadWatcher() {
    debug('init unread watcher');
    // subscribe to channel updates
    const channels$ = await this._chatDb.getChannels(Chat.Alberta);
    const localConsumptions$ = await this._chatDb.watchAllLocalConsumptions();
    combineLatest([channels$, localConsumptions$])
      .pipe(
        map(([channels, consumptions]) => this.getChannelConsumptionReleations(channels, consumptions)),
        map(relations =>
          Object.values(relations).reduce((acc, relation) => {
            const sum =
              relation.channel.attributes && relation.channel.attributes['isOpen'] === false
                ? acc
                : acc + relation.unreadMessages;

            return sum;
          }, 0)
        )
      )
      .subscribe(newUnreadMessages => {
        debug('calculated unread messages', newUnreadMessages);
        this._unreadMessagesSubject$.next(newUnreadMessages);
      });
  }

  private async _initAgentUnreadWatcher() {
    debug('init unread agent watcher');

    const accumulator = (acc: number, relation: IChannelConsumptionRelation) =>
      relation.channel.attributes &&
      ((relation.channel.attributes['assignedToId'] !== this._authService.authentication.account._id &&
        relation.channel.attributes['assignedToId'] !== '') ||
        relation.channel.attributes['isOpen'] === false)
        ? acc
        : acc + relation.unreadMessages;

    const channels$ = await this._chatDb.getChannels(Chat.PatientApp);
    const localConsumptions$ = await this._chatDb.watchAllLocalConsumptions();
    combineLatest([channels$, localConsumptions$])
      .pipe(
        map(([channels, consumptions]) => this.getChannelConsumptionReleations(channels, consumptions)),
        map(relations => this.reduceToUnreadMessages(relations, accumulator))
      )
      .subscribe(async ({ unreadMessages, relations }) => {
        debug('calculated unread agent messages', unreadMessages);
        this._unreadAgentMessagesSubject$.next(unreadMessages);
        await this.markMessagesAsReadForClosedChannels(relations);
      });
  }

  private reduceToUnreadMessages(
    relations: IChannelConsumptionRelation[],
    accumulator: (acc: number, relation: IChannelConsumptionRelation) => number
  ) {
    const unreadMessages = Object.values(relations).reduce(accumulator, 0);
    return { unreadMessages, relations };
  }

  private async markMessagesAsReadForClosedChannels(relations: IChannelConsumptionRelation[]) {
    for (const relation of relations) {
      if (
        relation.unreadMessages &&
        relation.channel.attributes &&
        relation.channel.attributes['isOpen'] === false &&
        relation.channel.attributes['assignedToId'] !== this._authService.authentication.account._id &&
        relation.channel.updateReasons &&
        relation.channel.updateReasons.includes('attributes')
      ) {
        await this._chatService.checkForNewMessagesInChannel(relation.channel.sid, Chat.PatientApp);
        await new Promise<void>(async resolve => {
          const messages$ = await this._chatDb.getMessagesOfChannel(relation.channel.sid);

          messages$.pipe(take(1)).subscribe(async messages => {
            const sortedMessages = sortBy(messages, 'index');
            const lastMessage = last(sortedMessages);
            await this._chatService.markChannelAsRead(relation.channel.sid, lastMessage.index);
            resolve();
          });
        });
      }
    }
  }

  private getChannelConsumptionReleations(channels: ChatChannel[], consumptions: ChatChannelConsumptionStatus[]) {
    const channelConsumptionRelations: IChannelConsumptionRelation[] = [];

    for (const channel of channels) {
      const localChannelConsumption = find(consumptions, { _id: channel.sid });
      let consumptionIndex = 0;
      if (
        localChannelConsumption &&
        localChannelConsumption.localLastConsumedMessageIndex > (channel.lastConsumedMessageIndex || 0)
      ) {
        consumptionIndex = localChannelConsumption.localLastConsumedMessageIndex;
      } else {
        consumptionIndex = channel.lastConsumedMessageIndex || 0;
      }

      const unreadMessages = clamp((channel.lastMessageIndex || 0) - consumptionIndex, 0, Number.MAX_SAFE_INTEGER);

      channelConsumptionRelations.push({ unreadMessages, channel });
    }

    return channelConsumptionRelations;
  }
}
