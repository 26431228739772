import { IDoctor, IInstitution } from '@alberta/konexi-shared';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PatientViewModel } from 'src/app/business/patient/view-models/patient-view-model';
import { InstitutionsService } from 'src/app/institution/master/institutions.service';
import { FaxContact } from 'src/app/shared/models/fax-contact.model';
import { BaseReport } from 'src/app/shared/models/report/base-report';
import { SelectionItem } from 'src/app/shared/models/selectionItem';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PatientContactService } from 'src/app/shared/services/patient-contact.service';
import { ReportService } from 'src/app/shared/services/report.service';

@Component({
  selector: 'itl-fax-create',
  templateUrl: './fax-create.modal.html',
  styleUrls: ['./fax-create.modal.scss'],
})
export class FaxCreateModal implements OnInit {
  private _faxContacts: FaxContact[];
  public faxContactsSelectionItems: SelectionItem[] = [];
  public patient: PatientViewModel = new PatientViewModel();
  public institution: IInstitution;
  public doctor: IDoctor;
  public currentUserId: string;
  public reportData: BaseReport;
  public isIndividualNumber: boolean;
  public individualNumber: string;
  public isLoading = true;

  constructor(
    private _patientContactsProvider: PatientContactService,
    private _institutionService: InstitutionsService,
    private _navParams: NavParams,
    public translate: TranslateService,
    private _modalCtrl: ModalController,
    private _auth: AuthService,
    private _router: Router,
    private _reportService: ReportService
  ) {}

  async ngOnInit(): Promise<void> {
    this.reportData = this._navParams.get('data');
    this.patient = this._navParams.get('patient');
    this.doctor = this._navParams.get('doctor');
    this.institution = this._navParams.get('institution');

    this._faxContacts = await this.getFaxContacts();
    this._faxContacts.forEach(contact => {
      if (contact.fax) {
        this.faxContactsSelectionItems.push(
          new SelectionItem(contact, `${contact.type}:\n${contact.name}\n${contact.fax}`)
        );
      }
    });
    this.isLoading = false;
    this.currentUserId = this._auth.authentication.account._id;
  }

  private async getFaxContacts(): Promise<FaxContact[]> {
    let contacts: FaxContact[] = [];

    if (this.institution) {
      contacts = await this._institutionService.getInstitutionFaxContact(this.institution);
    } else if (this.doctor) {
      contacts = await this._institutionService.getDoctorFaxContacts(this.doctor._id);
    } else if (this.patient) {
      contacts = await this._patientContactsProvider.getPatientContacts(this.patient._id);
    }

    return contacts;
  }

  public selectChanged(value: any[]): void {
    if (!value) {
      return;
    }
    this.reportData.recipients = value;
  }

  public async close(): Promise<void> {
    await this._modalCtrl.dismiss().catch(error => console.log(error));
  }

  public async done() {
    if (this.isIndividualNumber) {
      this.reportData.recipients = [{ fax: this.enforcePhoneNumberPattern(this.individualNumber) }] as any[];
    } else {
      this.reportData.recipients = this.reportData.recipients.map(m => {
        m.fax = this.enforcePhoneNumberPattern(m.fax);
        return m;
      });
    }

    if (!this._navParams.get('skipReportPreview')) {
      this._reportService.reportData$$.next(this._navParams.get('report'));
      this._reportService.data$$.next(this.reportData);
      this._reportService.patient$$.next(this.patient);
      this._reportService.type$$.next(this._navParams.get('type'));
      this._reportService.options$$.next({
        mail: true,
        fax: true,
        mailSubject: this._navParams.get('mailSubject'),
        mailBody: this._navParams.get('mailBody'),
      });
      await this._router.navigateByUrl('/report');
    }

    await this._modalCtrl.dismiss(this.reportData.recipients).catch(error => console.log(error));
  }

  private enforcePhoneNumberPattern(string) {
    const regexMatch: string[] = string.match(/[0-9]{0,14}/g);
    if (regexMatch === null) {
      return '';
    }
    let newString = regexMatch.join('');
    if (newString.startsWith('0')) {
      newString = newString.substr(1);
    }
    newString = `+49${newString}`;
    return newString;
  }
}
