import { Component, Input } from '@angular/core';

export enum ChannelStatus {
  new,
  activ,
  finished,
  completed,
}

@Component({
  selector: 'itl-channel-list-entry',
  templateUrl: './channel-list-entry.component.html',
  styleUrls: ['./channel-list-entry.component.scss'],
})
export class ChannelListEntryComponent {
  @Input() isSelected = false;
  @Input() showIsSelectedBadge = false;
  @Input() channelName = '';
  @Input() lastMessageText = '';
  @Input() lastActivityDate = '';
  @Input() unreadMessages = 0;
  @Input() lastMessageSenderName = '';
  @Input() lastMessageStatus = '';
  @Input() lastListItem = false;
  @Input() isActive = true;
  @Input() showLastMessage = true;
  @Input() showLabel = false;
  @Input() labelText: string;
  @Input() labelMarked = false;
  @Input() channelStatus: ChannelStatus;

  public labelBaseColorConfig = {};
  constructor() {
    this.labelBaseColorConfig[ChannelStatus.new] = '#FF003D';
    this.labelBaseColorConfig[ChannelStatus.activ] = '#00B500';
    this.labelBaseColorConfig[ChannelStatus.finished] = '#001737';
    this.labelBaseColorConfig[ChannelStatus.completed] = '#001737';
  }

  public disalbedAvatarBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue(
    '--alberta-chat-avatar-disalbed-background'
  );

  public disalbedAvatarTextColor = getComputedStyle(document.documentElement).getPropertyValue(
    '--alberta-chat-avatar-disalbed-text'
  );

  public disalbedAvatarBorder = getComputedStyle(document.documentElement).getPropertyValue(
    '--alberta-chat-avatar-disabled-border'
  );
}
