import { inject, Injectable } from '@angular/core';
import { ChatDbService } from 'src/app/shared/services/chat/data/chat-db.service';

import { ChatChannel, ChatMessage } from './data/db-schema';
import { Chat } from './model/chat-instance';
import { TWILIO_AGENT_TOKEN } from './twillio/agent-twilio.factory';
import { TwilioChatSendService } from './twillio/twilio-chat-send.service';
import { TwilioToChatDtoHelpersService } from './twillio/twilio-to-chat-dto-helpers';
import { TWILIO_TOKEN } from './twillio/twilio.factory';

@Injectable({
  providedIn: 'root',
  // factory needed because with "useClass" it would not inject the twilio token properly
  useFactory: () =>
    new TwilioChatSendService(
      inject(TWILIO_TOKEN),
      inject(TWILIO_AGENT_TOKEN),
      inject(TwilioToChatDtoHelpersService),
      inject(ChatDbService)
    ),
})
export abstract class ChatSendService {
  public abstract createChat(name: string, chatUserChatIds: string[]): Promise<ChatChannel>;
  public abstract fetchMessagesOfChannel(
    channelSid: string,
    anchor: number,
    count: number,
    chat?: Chat
  ): Promise<ChatMessage[]>;
  public abstract sendMessage(
    channelId: string,
    messageBody: string,
    attributes: {},
    chat?: Chat
  ): Promise<ChatMessage>;
  public abstract setConsumptionIndex(channelId: string, index: number, chat?: Chat): Promise<void>;
  public abstract setAgentConsumptionIndex(channelId: string, index: number): Promise<void>;

  public abstract updateChannelAttributes(channelSid: string, attributes?: Object): Promise<void>;
}
