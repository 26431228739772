<div class="offline-message">
  <div class="wrapper">
    <div class="image">
      <itl-alberta-icon [name]="icon"></itl-alberta-icon>
    </div>
    <div class="message">
      <div class="header">
        {{ header }}
      </div>
      <div class="innerMessage" [innerHTML]="message"></div>
    </div>
  </div>
</div>
