import { IModel } from '@alberta/konexi-shared';
import { Paginated, Query } from '@feathersjs/feathers';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IQuery } from 'src/app/shared/services/contracts/query/query';
import { IFeathersAppProvider } from 'src/app/shared/services/contracts/sync/feathers-app-provider';

import { AggregateStage, DbQueryStrategy } from './db-query-strategy';

export class RemoteQueryStrategy<T extends IModel & {}> extends DbQueryStrategy<T> {
  constructor(
    databaseName: string,
    storage: IGenericStorage,
    feathersAppProvider: IFeathersAppProvider,
    user,
    plugins: any
  ) {
    super(databaseName, storage, feathersAppProvider, user, plugins);
  }

  public async getAll(): Promise<T[]> {
    const result = await this.feathersAppProvider.app
      .service(this.table)
      .find(this.getParamsWithAuth(this.user, { query: { $paginate: false } }));

    return this.isPaginated(result) ? result.data : result;
  }

  public async getItems(keys: string[]): Promise<any[]> {
    const result = await this.feathersAppProvider.app
      .service(this.table)
      .find(this.getParamsWithAuth(this.user, { query: { _id: { $in: keys }, $paginate: false } }));

    return this.isPaginated(result) ? result.data : result;
  }

  public async get(id: string): Promise<T> {
    try {
      const result = await this.feathersAppProvider.app.service(this.table).get(id, this.getParamsWithAuth(this.user));

      return result;
    } catch (e) {
      return void 0;
    }
  }

  public async searchInternal(ids: string[], query: IQuery): Promise<T[]> {
    const params = {
      query: {
        _id: { $in: ids },
        archived: this.withArchive.includes(this.table) ? undefined : { $ne: true },
        $paginate: false,
      },
    };

    if (query.ignoreRegionIds) {
      params.query['$ignoreRegionIds'] = true;
    }

    if (query.ignoreMetadataType) {
      params.query['$ignoreMetadataType'] = true;
    }

    const result = await this.feathersAppProvider.app
      .service(this.table)
      .find(this.getParamsWithAuth(this.user, params));

    return this.isPaginated(result) ? result.data : result;
  }

  public async searchInternalByQuery(
    query: Query,
    aggregate: AggregateStage[],
    ignoreRegionIds: boolean,
    ignoreMetadataType?: boolean
  ) {
    const params = {
      query: {
        ...query,
        archived: this.withArchive.includes(this.table) ? undefined : { $in: [null, false] },
        $paginate: false,
      },
    };

    if (ignoreRegionIds) {
      params.query['$ignoreRegionIds'] = true;
    }

    if (ignoreMetadataType) {
      params.query['$ignoreMetadataType'] = true;
    }

    const paramsWith$client = this.getParamsWithAuth(this.user, params);
    if (aggregate) {
      if (!this.withArchive.includes(this.table)) {
        aggregate.push({ $match: { archived: { $in: [null, false] } } });
      }
      paramsWith$client.query.$client.syncData.aggregate = aggregate;
    }

    const result = await this.feathersAppProvider.app.service(this.table).find(paramsWith$client);

    return this.isPaginated(result) ? result.data : result;
  }

  private isPaginated(paginated: any[] | Paginated<any>): paginated is Paginated<any> {
    return paginated && (paginated as Paginated<any>).data !== undefined;
  }
}
