import { Component, Input, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { hexToHSL } from 'src/app/shared/components/common/label/hex-to-hsl-converter';

import { InfoModalComponent } from './../info-modal/info-modal.component';

export enum LabelType {
  Custom,
  Information,
  Warning,
  Note,
}

@Component({
  selector: 'itl-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnChanges {
  @Input() type: LabelType = LabelType.Custom;
  @Input() baseColor: string;
  @Input() showInfoBox = false;
  @Input() infoHeader: string;
  @Input() infoMessage: string;
  public labelStyle: string;
  public LabelType = LabelType;
  public infoStyle: string;

  constructor(private _modalController: ModalController) {}

  ngOnChanges(): void {
    if (this.type === LabelType.Information) {
      this.baseColor = getComputedStyle(document.documentElement).getPropertyValue('--alberta-label-info').trim();
    } else if (this.type === LabelType.Warning) {
      this.baseColor = getComputedStyle(document.documentElement).getPropertyValue('--alberta-label-warning').trim();
    } else if (this.type === LabelType.Note) {
      this.baseColor = getComputedStyle(document.documentElement).getPropertyValue('--alberta-label-note').trim();
    }
    this.setLabelStyle();
  }

  public async showInfoMessage() {
    if (!this.showInfoBox) {
      return;
    }
    const infoModal = await this._modalController.create({
      component: InfoModalComponent,
      componentProps: {
        baseColor: this.baseColor,
        message: this.infoMessage,
        header: this.infoHeader,
      },
      cssClass: 'auto-height',
      backdropDismiss: true,
    });
    infoModal.present();
  }

  private setLabelStyle(): void {
    if (!this.baseColor) {
      return;
    }
    const hsl = hexToHSL(this.baseColor);
    const hslPrefix = 'hsl(' + hsl.hue + ',' + hsl.saturation + '%,';
    this.infoStyle = `color: ${hslPrefix}${hsl.lightness}%)`;
    this.labelStyle = `
      background-color: ${hslPrefix}95%);
      border-color: ${hslPrefix}70%);
      color: ${hslPrefix}${hsl.lightness}%);
    `;
  }
}
