import { Permission } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, ReplaySubject } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';

import makeDebug from '../../../../makeDebug';
import { IAuthEventData } from '../../../common/contracts/authentication/auth-event-data';
import { AuthService } from '../auth.service';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { FeathersService } from '../feathers.service';
import { Chat } from './model/chat-instance';

const debug = makeDebug('services:chat:user');

@Injectable({ providedIn: 'root' })
export class ChatUserService {
  get localUserChatToken$(): Observable<string> {
    return this._localUserChatToken$.asObservable();
  }

  get localAgentChatToken$(): Observable<string> {
    return this._localAgentChatToken$.asObservable();
  }

  private _localUserIdentity$ = new ReplaySubject<string>();
  private _localUserChatToken$ = new ReplaySubject<string>();
  private _localAgentChatToken$ = new ReplaySubject<string>();

  constructor(
    private readonly _auth: AuthService,
    private readonly _platform: Platform,
    @Inject(FeathersService) private readonly _feathersApp: IFeathersAppProvider
  ) {
    this._auth.accountChanged.subscribe(() => {
      debug('account changed', this._auth.authentication.account);
      this._updateAccountChatInformation().catch(err => window.logger.error('Handle account change failed', err));
    });
    this._auth.authenticatedEventPublisher.subscribe((authEvent: IAuthEventData) => {
      if (authEvent.isAuthenticated && authEvent.authOnline) {
        this._updateAccountChatInformation().catch(err => window.logger.error('_updateAccountChatInformation', err));
      }
    });
  }

  public getUserDisplayName() {
    return `${this._auth.authentication.account.firstName} ${this._auth.authentication.account.lastName}`;
  }

  public getUserIdentity(): Promise<string> {
    debug('get user chat identity');
    return this._localUserIdentity$.pipe(take(1)).toPromise();
  }

  public async refreshToken(chat: Chat): Promise<string> {
    const authPromise = new Promise<void>(resolve => {
      this._auth.authenticatedEventPublisher
        .pipe(takeWhile((authEvent: IAuthEventData) => !authEvent.isAuthenticated))
        .subscribe({ complete: resolve });
    });

    await authPromise;

    debug('refreshing token...');
    if (chat === Chat.Alberta) {
      const chatToken = await this.getChatToken();
      if (!chatToken) {
        throw Error('refreshing failed');
      }
      this._localUserChatToken$.next(chatToken);
      return chatToken;
    } else {
      const chatToken = await this.getAgentChatToken();
      if (!chatToken) {
        throw Error('refreshing failed');
      }
      this._localAgentChatToken$.next(chatToken);
      return chatToken;
    }
  }
  private async _updateAccountChatInformation() {
    if (!this.hasPermission() || !this._auth.authentication.account) {
      return;
    }

    const chatToken = await this.getChatToken();
    const agentChatToken = await this.getAgentChatToken();
    const { _id } = this._auth.authentication.account;
    debug('update account information', { chatToken: chatToken });

    if (chatToken) {
      this._localUserChatToken$.next(chatToken);
    }
    if (agentChatToken) {
      this._localAgentChatToken$.next(agentChatToken);
    }

    this._localUserIdentity$.next(_id);
  }

  private async getChatToken(): Promise<string> {
    try {
      const { token } = await this._feathersApp.app.service('chat').create({
        pushChannel: this._platform.is('ios') ? 'apn' : 'fcm',
      });
      return token;
    } catch (e) {
      return null;
    }
  }

  private async getAgentChatToken(): Promise<string> {
    if (!this._auth.authentication.account.isAgent) {
      return 'NO_AGENT';
    }

    try {
      const { token } = await this._feathersApp.app.service('agent-chat').create({
        pushChannel: this._platform.is('ios') ? 'apn' : 'fcm',
      });
      return token;
    } catch (e) {
      return null;
    }
  }

  private hasPermission(): boolean {
    return this._auth.userHasPermission('chat', Permission.DeleteArchive);
  }
}
