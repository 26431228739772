import { Inject, Injectable, NgZone } from '@angular/core';
import { filter } from 'rxjs/operators';
import io from 'socket.io-client';
import { IShutdownBroker } from 'src/app/business/maintenance/contracts/shutdown-broker';
import { ConnectionMode } from 'src/app/common/contracts/connection/connection-mode';
import { LogLevel } from 'src/app/common/contracts/logging/log-level';

import makeDebug from '../../../makeDebug';
import { ConnectionStateService } from './connection-state.service';
import { IConnectionStateService } from './contracts/sync/connection-state-service';
import { IFeathersService } from './contracts/sync/feathers-service';
import { IWebSocketClient } from './contracts/sync/web-socket-client';
import { FeathersService } from './feathers.service';
import { ShutdownBroker } from './maintenance/shutdown-broker.service';

const debug = makeDebug('websocket:websocket-client');

@Injectable({ providedIn: 'root' })
export class WebSocketClient implements IWebSocketClient {
  private _fireWhenStartOffline = true;
  private _socket: SocketIOClient.Socket;
  private _onlineInterval: NodeJS.Timer;
  private _interval: NodeJS.Timer;

  constructor(
    private _ngZone: NgZone,
    @Inject(ShutdownBroker) private _maintenanceShutdownWatch: IShutdownBroker,
    @Inject(FeathersService) private _feathersService: IFeathersService,
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService
  ) {
    this._maintenanceShutdownWatch.shutdownWatch
      .pipe(filter(shutdownInfo => shutdownInfo != null))
      .subscribe(shutdownInfo => {
        if (shutdownInfo.userIsItLabs) {
          return;
        }

        this._feathersService.connectionMode = shutdownInfo.shutdown
          ? ConnectionMode.maintenance
          : ConnectionMode.default;
        debug('shuting down websocket', shutdownInfo);

        this._connectionStateService.setConnected(!shutdownInfo.shutdown && this._socket.connected);
        this._connectionStateService.setConnectionState();
      });
  }

  public async connect(uri: string): Promise<void> {
    return Promise.resolve().then(async () => {
      const raisedByConnect = await this.connectInternal(uri);

      this._feathersService.setup(this._socket);

      this.publishConnectionState(raisedByConnect);
    });
  }

  private publishConnectionState(raisedByConnect: boolean) {
    global.clearInterval(this._onlineInterval);

    // Occurs only once when user starts offline
    if (this._fireWhenStartOffline && !this._connectionStateService.isConnected) {
      this._fireWhenStartOffline = false;
      this._connectionStateService.setConnectionState();
      return;
    }

    if (!raisedByConnect) {
      return;
    }

    this._ngZone.runOutsideAngular(() => {
      this._onlineInterval = global.setInterval(() => {
        if (this._connectionStateService.isConnected) {
          global.clearInterval(this._onlineInterval);
          debug('setting connection state by connect');
          this._connectionStateService.setConnectionState();
        }
      }, 300);
    });
  }

  private async connectInternal(uri: string) {
    let initialConnect = true;
    return new Promise<boolean>(resolve => {
      if (this._socket) {
        this._socket.disconnect();
        delete this._socket;
        this._socket = null;
      }

      this._socket = io.connect(uri, {
        transports: ['websocket'],
        upgrade: false,
        forceNew: true,
      });

      this._socket.on('connect', () => {
        this._connectionStateService.setSocketConnectionState(true);
        this._connectionStateService.setConnected(true);
        this._fireWhenStartOffline = false;
        resolve(true);
        if (!initialConnect) {
          this.publishConnectionState(true);
        }
        initialConnect = false;
      });

      this._socket.on('error', error => {
        window.logger.error('connection could not be established', error, LogLevel.silent);
        this._connectionStateService.setSocketConnectionState(false);
        initialConnect = false;
        resolve(false);
      });

      this._socket.on('connect_error', error => {
        window.logger.error('connection could not be established', error, LogLevel.silent);
        this._connectionStateService.setSocketConnectionState(false);
        initialConnect = false;
        resolve(false);
      });

      this._socket.on('disconnect', error => {
        debug('setting connection state by disconnect');
        this._connectionStateService.setSocketConnectionState(false);
        this._connectionStateService.setConnected(false);
        if (!this._fireWhenStartOffline) {
          this._connectionStateService.setConnectionState();
        }

        global.clearInterval(this._interval);

        this._ngZone.runOutsideAngular(() => {
          this._interval = global.setInterval(() => {
            if (this._connectionStateService.isConnected) {
              global.clearInterval(this._interval);
              this._interval = null;
              return;
            }
            this.publishConnectionState(false);
          }, 5000);
        });

        window.logger.error(`disconnect: ${error}`, error, LogLevel.silent);
      });
    });
  }
}
