// tslint:disable-next-line:max-line-length
import { IConfig, IDeactivationReasonConfig, IDeliveryInformationConfig, IPatientDataConfig } from '@alberta/konexi-shared';
import { IShippingInformation } from '@alberta/konexi-shared/dist/general/shippingInformation';
import { Injectable } from '@angular/core';
import { ConfigDB } from 'src/app/common/repository/databases';
import { ConfigDto } from 'src/app/shared/models/config/config-dto.model';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable({ providedIn: 'root' })
export class TenantConfigService {
  constructor(private _queryService: QueryService) {}

  public async getTenantConfig(): Promise<IConfig | null> {
    const config = await this._queryService.getAll<ConfigDto>(ConfigDB);
    if (config && config[0]) {
      return config[0];
    } else {
      return null;
    }
  }

  public async showResmedTenant(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showResmedTenant);
  }

  public async showDevices(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showDevices);
  }

  public async showIntegratedCare(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showIntegratedCare);
  }

  public async showCareProposal(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showCareProposal);
  }

  public async showLimitedReport(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.document?.disableReports);
  }

  public async getPatientDataConfig(): Promise<IPatientDataConfig | undefined> {
    const config = await this.getTenantConfig();
    return await config?.patientData;
  }

  public async getDeactivationReasonConfig(): Promise<IDeactivationReasonConfig[] | undefined> {
    const config = await this.getTenantConfig();
    return await config?.patientData?.deactivationReasonConfig;
  }

  public async hashAudits(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.document?.hashAudit);
  }

  public async saveImagesAtAudit(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return !Boolean(config?.document?.dontSaveImagesAtAudit);
  }

  public async completeAuditsOnCreate(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.document?.completeOnCreate);
  }

  public async getDeliveryInformation(): Promise<IDeliveryInformationConfig[]> {
    const config = await this.getTenantConfig();
    return config?.deliveryInformation;
  }

  public async showIntegratedCareAbo(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.showAbo);
  }

  public async usedirectOrder(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.orderConfig?.directOrder);
  }
  public async canEditInsuranceContract(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return !!config?.insuranceContract?.canEdit;
  }
  public async calculateContractOnStandardCareProposal(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.contractCalculationOnStandardCareProposal);
  }

  public async canCalculateByContractArticleGroup(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return !!config?.integratedCare?.contractArticleGroupCalculation;
  }

  public async canAddAddtionalFieldNurse(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return !!config?.patient?.canAddAdditionalFieldNurse;
  }

  public async showHospitalPrescriberToggle(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return !!config?.orderConfig.showOrderPrescriberToggle;
  }

  public async showCrossTherapyArticleSearch(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return Boolean(config?.integratedCare?.showCrossTherapyArticleSearch);
  }

  public async getClassificationLabel(): Promise<string> {
    const config = await this.getTenantConfig();
    return config?.classificationLabel;
  }

  public async classificationRequired(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.classificationRequired);
  }

  public async deliveryInformationRequired(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return Boolean(config?.deliveryInformationRequired);
  }

  public async getPatientClassifications(): Promise<string[]> {
    const config = await this.getTenantConfig();
    return config?.patientClassifications;
  }

  public async getMaxAnnotationLength(): Promise<number> {
    const config = await this.getTenantConfig();
    return config?.orderConfig.maxAnnotationLength;
  }

  public async getShippingInformation(): Promise<IShippingInformation[]> {
    const config = await this.getTenantConfig();
    return config?.orderConfig?.shippingInformation;
  }
  public async showPatientDeliveryInformation(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return config?.patientData?.showDeliveryInformation;
  }
  public async showCalorieDemand(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return config?.patientData?.showCalorieDemand;
  }

  public async hideBuildNumber(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.hideBuildNumber);
  }
  public async mustSetDeliveryDate(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return !Boolean(config?.orderConfig?.deliveryDateEmpty);
  }
  public async canSetDeliveryDateUnknown(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.orderConfig?.deliveryDateUnknown);
  }
  public async getDoctorCorrespondenceTypes(): Promise<string[] | undefined> {
    const config = await this.getTenantConfig();
    return await config?.doctor?.correspondenceTypes;
  }
}
