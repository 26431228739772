import { IModel } from '@alberta/konexi-shared';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IIndexMetaDataInfo } from 'src/app/shared/services/contracts/database/index-metadata-info';
import { IQuery } from 'src/app/shared/services/contracts/query/query';

import { IRepository } from '../contracts/repository/repository';
import { Deferred } from '../deferred/deferred';
import { SqliteStorage } from '../storage/sqlite-storage';

export class Repository<T extends IModel & {}> implements IRepository<T> {
  private _ready = new Deferred<void>();
  private _plugin: any;
  private _table: string;

  constructor(private _storage: IGenericStorage, private _databaseName: string, private _plugins: any) {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      this._plugin = this._plugins[this._databaseName];
      this._table = this._databaseName.substring(0, this._databaseName.length - 3);

      try {
        if (this._storage instanceof SqliteStorage) {
          await this._storage.recreateIndex();
        }
      } catch (error) {
        window.logger.error('recreateIndex failed.', error);
      } finally {
        this._ready.resolve();
      }
    })();
  }

  get ready(): Promise<void> {
    return this._ready.promise;
  }

  async count(): Promise<number> {
    return this._storage.length();
  }

  execBatch(batch: any[]): Promise<any> {
    return this._storage.executeBatch(batch);
  }

  public async addOrUpdateIndex(item: T): Promise<IIndexMetaDataInfo> {
    if (!this._plugin) {
      return null;
    }

    return this._storage.readIndexFieldMetaInfo(item);
  }

  async createOrUpdate(item: T): Promise<any> {
    item.timestamp = new Date();
    return this.createOrUpdateInternal(item);
  }

  private async createOrUpdateInternal(item: T): Promise<any> {
    await this.ready;

    const indexValues = await this.addOrUpdateIndex(item);
    if (typeof indexValues === 'object' && indexValues != null) {
      const indexBatches = [];
      indexBatches.push([
        `DELETE FROM [${this._table}_fts] WHERE rowid = (SELECT docid FROM [${this._table}_fts] WHERE ${
          this._table
        }_fts MATCH 'id:${String(item._id)}')`,
        [],
      ]);
      indexBatches.push([
        `INSERT INTO [${this._table}_fts] (id, ${indexValues.fieldNames.join(', ')}) VALUES (?1, ${
          indexValues.bindings
        })
    `,
        [String(item._id), ...indexValues.entries.map(entry => String(entry).toLocaleLowerCase())],
      ]);
      await this._storage.executeBatch(indexBatches);
    }
    return this._storage.set(item._id, item);
  }

  async createOrUpdateFromSync(itemsForDb: { items: T[]; deletable: boolean }, batch: any[]): Promise<any> {
    await this.ready;

    return this._storage.setItems(itemsForDb, batch);
  }

  async getAll(): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      const items = [];
      this._storage.getAll().subscribe(
        item => {
          if (item) {
            items.push(item);
          }
        },
        error => reject(error),
        () => resolve(items)
      );
    });
  }

  async getItems(keys: string[]): Promise<any[]> {
    const items = await this._storage.getItems(keys);
    return items.filter(item => !!item);
  }

  async get(id: string): Promise<T> {
    try {
      const result = await this._storage.get(id);
      return (result as any) as T;
    } catch (e) {
      return void 0;
    }
  }

  async delete(id: string): Promise<void> {
    await this._storage.removeItem(id);
  }

  async search(query: IQuery): Promise<T[]> {
    await this.ready;

    if (!query || !query.query || query.query.length === 0) {
      return [];
    }

    this.splitTooLongQuery(query);

    const indexResults = [];

    do {
      const searchTerm = this.toSearchTerm(query);
      const results = await this._storage.search(searchTerm);
      indexResults.push(...results);
    } while (query.isIn && query.queryList && query.queryList.length);

    return this._storage.getItems(indexResults);
  }

  private splitTooLongQuery(query: IQuery) {
    if (!query.isIn) {
      return;
    }

    const terms = query.query.split(' ');
    if ((terms || []).length > 200) {
      query.queryList = [];
      while (terms.length > 0) {
        query.queryList.push([...terms.splice(0, terms.length > 200 ? 200 : terms.length)]);
      }
    }
  }

  private toSearchTerm(query: IQuery): string {
    if (query.isIn && query.queryList && query.queryList.length) {
      query.query = query.queryList.shift().join(' ');
    }
    query.query = query.query.replace(/\+/g, '');

    let statement = query.query.split(' ').reduce((searchTerm, word) => {
      const indexOfColon = word.indexOf(':');

      return `${searchTerm}${searchTerm.length > 0 ? ' ' : ''}${
        indexOfColon > -1 ? `${this.splitToLowerCase(word)}*` : `${word.toLocaleLowerCase()}*`
      }`;
    }, '');

    // remove prefix from field name
    // e.g. metadata.patient:1234-xxx => patient:1234-xxx
    statement = statement
      .split(' ')
      .map(kvp => {
        if (!kvp.includes(':')) {
          return kvp;
        }

        const [key, value] = kvp.split(':');
        if (key.includes('.')) {
          const [_, realKey] = key.split('.');
          return `${realKey}:${value}`;
        }
        return kvp;
      })
      .join(' ');

    if (query.isIn) {
      statement = statement.split(' ').join(' OR ');
    }

    console.log(`SQL statement: ${statement}`);

    return `SELECT * FROM ${this._table}_fts WHERE ${this._table}_fts MATCH '${statement}'`;
  }

  private splitToLowerCase(query: string): string {
    const fieldAndWord = query.split(':');

    if (fieldAndWord.length === 2) {
      return `${fieldAndWord[0]}:${fieldAndWord[1].toLocaleLowerCase()}`;
    }

    return query;
  }
}
