import { InjectionToken } from '@angular/core';

import {
  Authenticator,
  CommandQueueRunner,
  DatabaseCreator,
  MaintenanceSynchroniser,
  MaintenanceWatchStarter,
  RootHistorySetter,
  ShutdownInfoListener,
  SyncIndicatorCloser,
  SyncIndicatorOpener,
  SyncIndicatorPreCloser,
  SyncStarter,
  TokenHandler,
  WebSyncStarter,
} from '../contracts/sync/sync-endpoints';

export const syncOrder = [
  SyncIndicatorPreCloser,
  TokenHandler,
  Authenticator,
  DatabaseCreator,
  MaintenanceSynchroniser,
  MaintenanceWatchStarter,
  ShutdownInfoListener,
  CommandQueueRunner,
  RootHistorySetter,
  SyncIndicatorOpener,
  SyncStarter,
  WebSyncStarter,
  SyncIndicatorCloser,
];

export const SyncOrderToken = new InjectionToken<string[]>('SyncOrder', {
  providedIn: 'root',
  factory: () => syncOrder,
});
