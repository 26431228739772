import { Inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { SyncIndicatorPage } from 'src/app/sync-indicator/sync-indicator.page';

import makeDebug from '../../../../makeDebug';
import { AppReadyEvent } from '../../models/app-ready-event';
import { EventService } from '../../services/event.service';
import { NetworkInfoService } from '../../services/network/network-info.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { SyncIndicatorOpener as Opener } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:sync-indicator-opener');

@Injectable()
export class SyncIndicatorOpener implements ISyncElement {
  public get name(): string {
    return Opener;
  }

  constructor(
    private modalCtrl: ModalController,
    private _appReadyService: EventService<AppReadyEvent>,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync,
    private readonly _networkInfoService: NetworkInfoService
  ) {}

  public async execute(context: ISyncContext): Promise<void> {
    await this._platformSync.ready;

    if (!this._platformSync.canBeSynced) {
      this._appReadyService.dispatchEvent(new AppReadyEvent(true));
      return;
    }

    debug('entered execute function', context);
    if (
      !context.params.isOnline ||
      !context.params.isStartUpSync ||
      context.syncModal ||
      this._networkInfoService.isConnectionSlow
    ) {
      return;
    }

    debug('opening sync indicator');
    await this.initializeSyncIndicator(context).catch(error =>
      window.logger.error('initializing sync-indicator crashed', error)
    );
  }

  public async initializeSyncIndicator(context: ISyncContext) {
    debug('initializing sync modal', context);
    context.syncModal = await this.modalCtrl.create({
      component: SyncIndicatorPage,
      backdropDismiss: false,
    });

    await context.syncModal.present();

    // tslint:disable-next-line: no-floating-promises
    context.syncModal.onDidDismiss().then(() => {
      context.syncModal = null;
    });
  }
}
