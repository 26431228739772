import { Injectable } from '@angular/core';
import { clamp, isNumber } from 'lodash';

import makeDebug from '../../../../makeDebug';
import { ChatDbService } from './data/chat-db.service';

const debug = makeDebug('services:chat:chat-messages-data');

@Injectable({
  providedIn: 'root',
})
export class ChatMessageDataService {
  constructor(private readonly _chatDb: ChatDbService) {}
  public async getUnreadMessagesOfChannel(channelSid: string): Promise<number> {
    const channel = await this._chatDb.getChannel(channelSid);
    const localChannelConsumption = await this._chatDb.getLocalConsumptionOfChannel(channelSid);
    debug('get unread messages of channel', { channelSid, channel, localChannelConsumption });
    let consumptionIndex = 0;
    if (
      localChannelConsumption &&
      localChannelConsumption.localLastConsumedMessageIndex > (channel.lastConsumedMessageIndex || 0)
    ) {
      consumptionIndex = localChannelConsumption.localLastConsumedMessageIndex;
    } else if (isNumber(channel.lastConsumedMessageIndex)) {
      consumptionIndex = channel.lastConsumedMessageIndex || 0;
    }
    return clamp((channel.lastMessageIndex || 0) - consumptionIndex, 0, Number.MAX_SAFE_INTEGER);
  }

  public async updateChannelConsumptionStatus(channelSid: string, index: number) {
    const localChannelConsumption = await this._chatDb.getLocalConsumptionOfChannel(channelSid);
    if (!localChannelConsumption || localChannelConsumption.localLastConsumedMessageIndex < index) {
      debug('update channel consumption', channelSid, index);
      await this._chatDb.setConsumptionIndex(channelSid, index);
      await this._chatDb.setChannelLastLocalUpdate(channelSid);
    } else {
      debug('no consumption update needed', { channelSid, index, localChannelConsumption });
    }
  }
}
