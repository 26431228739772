import { AttachmentType, IAttachment, IPatient, Therapy } from '@alberta/konexi-shared';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, lowerFirst } from 'lodash';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { AttachmentWorkItem } from 'src/app/business/attachment/attachment-work-item';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { Logger } from 'src/app/common/logging/logger';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';
import { AttachmentMetadata } from 'src/app/shared/models/attachment/attachment-meta-data.model';
import {
  AttachmentDatabaseService,
  EnhancedAttachmentDto,
} from 'src/app/shared/services/attachment/attachment-database.service';
import {
  base64ToBlob,
  getMimeTypeFromBase64String,
  stripDataPartFromBase64String,
} from 'src/app/shared/services/attachment/attachment-helper';
import { AttachmentQueuesProcessorService } from 'src/app/shared/services/attachment/attachment-queues-processor.service';
import {
  AttachmentTransferService,
  OfflineUploadResult,
  UploadResult,
} from 'src/app/shared/services/attachment/attachment-transfer.service';
import { Database } from 'src/app/shared/services/attachment/Database';
import { v4 } from 'uuid';

import { AttachmentModelName } from '../../models/model-names';
import { Therapies } from '../../models/therapy';
import { AuthService } from '../auth.service';
import { BodyPartService } from '../body-part.service';
import { ConnectionStateService } from '../connection-state.service';

export type UploadReadyMetadata = Omit<AttachmentMetadata & { uniqueId: string }, 'name'>;
export type CreateMetadata = Partial<AttachmentMetadata> & { name: string };
type CreateObjectUrlType = (object: any) => string;

export const CreateObjectUrl = new InjectionToken<CreateObjectUrlType>('createObjectUrl', {
  providedIn: 'root',
  factory: () => URL.createObjectURL,
});
interface GetBlobOrAttachmentReturnValue {
  blob?: Blob;
  attachment?: AttachmentDto;
}

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  public LOCAL_PREFIX = 'local|';

  private _isOnline = false;
  private _websocketConnectedSubscription = new Subscription();
  private _isBusyWithOfflineUpload: boolean;

  public get offlineTxSuccess(): Observable<OfflineUploadResult> {
    return this._attachmentTransferService.offlineTxSuccess;
  }

  constructor(
    private _auth: AuthService,
    private _platform: Platform,
    private _connectionStateService: ConnectionStateService,
    private _attachmentDbProvider: AttachmentDatabaseService,
    private _attachmentTransferService: AttachmentTransferService,
    private _attachmentQueuesProcessor: AttachmentQueuesProcessorService,
    private _logger: Logger,
    private _attachmentWorkItem: AttachmentWorkItem,
    private _translateService: TranslateService,
    private _bodyPartService: BodyPartService,
    @Inject(Dispatcher) private _dispatcher: IDispatcher<AttachmentDto>,
    @Inject(CreateObjectUrl) private _createObjectUrl: CreateObjectUrlType
  ) {
    this._platform.pause.subscribe(_ => {
      if (this._websocketConnectedSubscription && !this._websocketConnectedSubscription.closed) {
        this._websocketConnectedSubscription.unsubscribe();
      }
    });
    this._platform.resume.subscribe(_ => this.registerOnlineObservable());
    this.registerOnlineObservable();
    this.performDelete = this.performDelete.bind(this);
    this.uploadLocal = this.uploadLocal.bind(this);
  }

  public async save(attachments: AttachmentDto[]) {
    await this._platform.ready();
    if (this._platform.is('hybrid') || !attachments || !attachments.length) {
      return;
    }

    await Promise.all(attachments.map(attachment => this._attachmentWorkItem.create(attachment)));

    this._dispatcher.sync(AttachmentModelName, {
      created: attachments,
      updated: [],
      deleted: [],
    });
  }

  public async get(id: string, returnValue: string = 'base64'): Promise<string | Blob> {
    return this._attachmentDbProvider
      .get(Database.Blob, id)
      .then(data => (data ? { blob: data } : this.getAttachmentMetadataFromDb(id)))
      .then(data => (data.blob ? data.blob : this.download(id, data.attachment.contentType)))
      .then(data => this.transformBlob(data, returnValue));
  }

  private async getAttachmentMetadataFromDb(id: string): Promise<GetBlobOrAttachmentReturnValue> {
    if (id.startsWith(this.LOCAL_PREFIX)) {
      throw Error(`blob is missing for ${id}`);
    }
    const attachment: AttachmentDto = await this._attachmentDbProvider.get(Database.Attachment, id);
    if (!attachment) {
      throw Error(`attachment is undefined for ${id}`);
    }
    return { attachment };
  }

  private async transformBlob(data: string | Blob, returnValue: string): Promise<string | Blob> {
    if (data) {
      switch (returnValue) {
        case 'blobUrl':
          return Promise.resolve(this._createObjectUrl(data));
        case 'blob':
          return Promise.resolve(data);
        default:
          // default = base64
          return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(data as Blob);
            reader.onload = () => {
              resolve(reader.result as string);
            };
          });
      }
    }
  }

  public async download(id: string, mimeType: string): Promise<Blob> {
    await this._auth.init;

    const blob = await this._attachmentTransferService.fetchBlob(id, mimeType);
    return blob;
  }

  public async create(base64String: string, metadata: CreateMetadata): Promise<UploadResult> {
    if (!base64String) {
      return Promise.reject('no image data');
    }
    const base64Data: string = stripDataPartFromBase64String(base64String);
    const mime: string = getMimeTypeFromBase64String(base64String);
    const blob: Blob = base64ToBlob(base64Data, mime);
    const filename = `${metadata.name ? metadata.name : 'unnamed.jpg'}`;
    const uploadMetadata = this.getUploadReadyMetadata(metadata);
    await this._attachmentDbProvider.writeBlobToStorage(uploadMetadata.uniqueId, blob);
    await this._attachmentDbProvider.writeAttachmentToStorage({
      _id: uploadMetadata.uniqueId,
      filename,
      contentType: mime,
      mime,
      uploadDate: new Date(new Date().toUTCString()),
      metadata: { ...uploadMetadata, createdBy: this._auth.authentication.account._id },
    });

    return this._attachmentTransferService.upload(
      { blob, metadata: uploadMetadata, filename, mime },
      uploadMetadata.uniqueId,
      this._isOnline
    );
  }

  private getUploadReadyMetadata(metadata: CreateMetadata): UploadReadyMetadata {
    const newMetadata = (cloneDeep(metadata) as unknown) as UploadReadyMetadata;
    const localId = `${this.LOCAL_PREFIX}${v4()}`;
    newMetadata.uniqueId = localId;
    delete newMetadata['name'];
    return newMetadata;
  }

  public async uploadLocal(localId: string): Promise<UploadResult | null> {
    const isAlreadyUploaded = localId.startsWith(this.LOCAL_PREFIX) === false;
    if (isAlreadyUploaded) {
      return null;
    }
    const [blob, attachment] = await Promise.all<Blob, EnhancedAttachmentDto>([
      this._attachmentDbProvider.get(Database.Blob, localId),
      this._attachmentDbProvider.get(Database.Attachment, localId),
    ]);

    if (!blob || !attachment) {
      if (blob) {
        this._logger.error(
          'local attachment found without blob - deleting it',
          new Error('local attachment found without blob - deleting it')
        );
        await this._attachmentDbProvider.remove(Database.Attachment, localId);
      }
      return null;
    }
    const { metadata } = attachment;
    return this._attachmentTransferService.upload(
      { blob, metadata, filename: attachment.filename, mime: attachment.mime },
      localId,
      this._isOnline
    );
  }

  public async update(attachment: AttachmentDto): Promise<boolean> {
    await this._attachmentDbProvider.writeAttachmentToStorage({
      ...attachment,
      uploadDate: new Date(new Date().toUTCString()),
      metadata: { ...attachment.metadata, updatedBy: this._auth.authentication.account._id },
    });

    await this._attachmentDbProvider.set(Database.AttachmentToUpdate, attachment._id, attachment);
    if (this._isOnline) {
      await this.runAttachmentQueuesProcessor();
    }
    return true;
  }

  public async softDelete(attachment: AttachmentDto): Promise<boolean> {
    attachment.metadata.archived = true;

    await this._attachmentDbProvider.writeAttachmentToStorage({
      ...attachment,
      uploadDate: new Date(new Date().toUTCString()),
      metadata: { ...attachment.metadata, updatedBy: this._auth.authentication.account._id },
    });

    await this._attachmentDbProvider.set(Database.AttachmentToUpdate, attachment._id, attachment);
    if (this._isOnline) {
      await this.runAttachmentQueuesProcessor();
    }
    return true;
  }

  public async delete(id: string): Promise<void> {
    if (!this._isOnline) {
      await this._attachmentDbProvider.set(Database.AttachmentToRemove, id, undefined);
      return;
    }
    try {
      await this.performDelete(id);
    } catch (error) {
      this._logger.error('delete image failed', error);
      await this._attachmentDbProvider.set(Database.AttachmentToRemove, id, undefined);
    }
  }

  private async performDelete(id: string): Promise<void> {
    const isRemoteAttachment = id.startsWith(this.LOCAL_PREFIX) === false;
    if (isRemoteAttachment) {
      await this._attachmentTransferService.delete(id);
    }
    await this._attachmentDbProvider.purge(id);
  }

  private async performUpdate(attachment: AttachmentDto): Promise<void> {
    const uploadedAttachment = await this._attachmentTransferService.update(attachment._id, attachment);
    await this._attachmentDbProvider.writeAttachmentToStorage(uploadedAttachment);
    await this._attachmentDbProvider.remove(Database.AttachmentToUpdate, attachment._id);
  }

  public async getAttachmentDto(id: string): Promise<AttachmentDto> {
    return this._attachmentDbProvider.getAttachmentDto(id);
  }

  public async isAttachmentBlobAvailable(id: string): Promise<boolean> {
    return this._attachmentDbProvider.isAttachmentBlobAvailable(id);
  }

  public async getLocalAttachments(): Promise<string[]> {
    return this._attachmentDbProvider.getLocalAttachments();
  }

  public getTherapyName(attachment: IAttachment): string {
    const therapyId = attachment?.metadata?.therapyId;
    const therapyTypeId = attachment?.metadata?.therapyTypeId;
    let therapyName = Therapies.getTherapyAndTypeName(therapyId, therapyTypeId);

    if (therapyId === Therapy.WV && attachment?.metadata?.woundLocation !== 'undefined') {
      const woundLocation = parseInt(attachment?.metadata?.woundLocation, 10);
      const woundLocationName = this._bodyPartService.getFullBodyPartName(woundLocation);
      therapyName += `, ${woundLocationName}`;
    }
    return therapyName;
  }

  public getDisplayFileName(patient: IPatient, attachment: IAttachment): string {
    if (!attachment || !patient) {
      return '';
    }

    return this.createFileName(patient, attachment, true);
  }

  public createMetaData(patient: IPatient, data: any): any {
    const { image, ...meta } = data;
    meta['patientId'] = patient._id;
    meta['regionId'] = patient.regionId;
    meta['archived'] = false;
    const fileExtension = image.substring(image.indexOf('/') + 1, image.indexOf(';base64'));
    if (!meta['name']) {
      const attachmentTypeName = this._translateService.instant(
        `attachment.type.${lowerFirst(AttachmentType[meta['type']])}`
      );
      const timestamp = new Date().toISOString().substr(0, 10);
      meta['name'] = `${attachmentTypeName}_${patient.firstName}_${patient.lastName}_${timestamp}.${fileExtension}`;
    }

    return meta;
  }

  private createFileName(
    patient: IPatient,
    attachment: IAttachment,
    includeTherapy?: boolean,
    fileExtension?: string
  ): string {
    const uploadDate = new Date(attachment.uploadDate).toISOString().substr(0, 10);

    if (!fileExtension) {
      fileExtension = attachment.filename.substring(attachment.filename.indexOf('.') + 1, attachment.filename.length);
    }
    const attachmentType = this._translateService.instant(
      `attachment.type.${lowerFirst(AttachmentType[attachment?.metadata?.type])}`
    );

    if (includeTherapy) {
      let therapyName = '';
      const therapyItem = Therapies.getTherapy(attachment?.metadata?.therapyId);
      if (therapyItem && therapyItem.id > 0) {
        therapyName += `_${therapyItem.displayName}`;

        const therapyType = Therapies.getTherapyType(
          attachment?.metadata?.therapyId,
          attachment?.metadata?.therapyTypeId
        );
        if (therapyType) {
          therapyName += `_${therapyType.displayName}`;
        }
      }
      return `${attachmentType}_${patient.firstName}_${patient.lastName}${therapyName}_${uploadDate}.${fileExtension}`;
    } else {
      return `${attachmentType}_${patient.firstName}_${patient.lastName}_${uploadDate}.${fileExtension}`;
    }
  }

  private registerOnlineObservable(): void {
    this._websocketConnectedSubscription = combineLatest([
      this._connectionStateService.connectionState,
      this._auth.authenticatedEventPublisher,
    ])
      .pipe(
        map(([connectionStatus, authEvent]) => ({ isOnline: connectionStatus.isOnline, authEvent })),
        tap(value => {
          this._isOnline = value.isOnline;
          this._attachmentQueuesProcessor.isOnline = value.isOnline;
        }),
        distinctUntilChanged(
          (x, y) => x.authEvent.isAuthenticated === y.authEvent.isAuthenticated && x.isOnline === y.isOnline
        ),
        filter(value => value.isOnline && value.authEvent.isAuthenticated && !this._isBusyWithOfflineUpload)
      )
      .subscribe(async () => {
        this._isBusyWithOfflineUpload = true;
        try {
          await this.runAttachmentQueuesProcessor();
        } catch (error) {
          this._logger.error('Error on websocketConnectedSubscription', error);
        } finally {
          this._isBusyWithOfflineUpload = false;
        }
      });
  }

  private async runAttachmentQueuesProcessor() {
    await this._attachmentQueuesProcessor.processQueues(
      this.performDelete.bind(this),
      this.uploadLocal.bind(this),
      this.performUpdate.bind(this)
    );
  }
}
