import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ChannelStatus } from './channel-list-entry.component';

@Pipe({ name: 'channelstatus' })
export class ChannelListEntryStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(status: ChannelStatus): string {
    switch (status) {
      case ChannelStatus.new:
        return this.translate.instant('chat.channels.status.new').toUpperCase();
      case ChannelStatus.activ:
        return this.translate.instant('chat.channels.status.active').toUpperCase();
      case ChannelStatus.finished:
        return this.translate.instant('chat.channels.status.finished').toUpperCase();
      case ChannelStatus.completed:
        return this.translate.instant('chat.channels.status.completed').toUpperCase();
    }
  }
}
