import { AttachmentType } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { Logger } from 'src/app/common/logging/logger';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';
import {
  AttachmentDatabaseService,
  EnhancedAttachmentDto,
} from 'src/app/shared/services/attachment/attachment-database.service';
import { Database } from 'src/app/shared/services/attachment/Database';
import { arrayBufferToBlob, blobToArrayBuffer } from 'src/app/shared/services/attachment/attachment-helper';
import { FeathersService } from 'src/app/shared/services/feathers.service';

import { NetworkInfoService } from '../network/network-info.service';

export interface OfflineUploadResult {
  localId: string;
  type: AttachmentType;
  id: string;
}

export interface UploadResult {
  loading?: boolean;
  progress?: number;
  error?: Error;
  needRetry?: boolean;
  data?: { id: string };
}

export type UploadData = EnhancedAttachmentDto & { blob: Blob | ArrayBuffer };

@Injectable({ providedIn: 'root' })
export class AttachmentTransferService {
  private _offlineTxSuccess = new Subject<OfflineUploadResult>();

  public get offlineTxSuccess(): Observable<OfflineUploadResult> {
    return this._offlineTxSuccess.asObservable();
  }

  constructor(
    private _logger: Logger,
    private _attachmentDatabaseService: AttachmentDatabaseService,
    private _feathersService: FeathersService,
    private readonly _networkInfoService: NetworkInfoService
  ) {}

  public async upload(
    data: UploadData,
    localId: string,
    isOnline: boolean,
    isFromQueue?: boolean
  ): Promise<UploadResult> {
    try {
      if (!isOnline || this._networkInfoService.isConnectionSlow) {
        data.blob = await blobToArrayBuffer(data.blob as Blob);
        await this._attachmentDatabaseService.set(Database.AttachmentToSave, localId, { data, localId });
        return { needRetry: true, loading: false, data: { id: localId } };
      }
      return await this.onlineUpload(data, localId, isFromQueue);
    } catch (error) {
      this._logger.error('Failed to upload attachment', error);
      const isDuplicatedError = error && error.code && error.code === 409;
      if (isDuplicatedError) {
        this._logger.error('got duplicated attachment error', error);
        await this.handleDuplicateError(localId).catch(_err =>
          this._logger.error('duplicate error cleanup failed', error)
        );
      }
      return { error, needRetry: !isDuplicatedError, loading: false, data: { id: localId } };
    }
  }

  private async onlineUpload(data: UploadData, localId: string, isFromQueue: boolean): Promise<UploadResult> {
    this._logger.info(`online upload ${localId}`);
    const uploadedAttachment = await this.uploadToBackend(data, localId, isFromQueue);
    await this._attachmentDatabaseService.replaceLocalData(localId, uploadedAttachment._id, uploadedAttachment);
    await this._attachmentDatabaseService.replaceLocalUpdate(localId, uploadedAttachment._id);
    if (isFromQueue) {
      this._offlineTxSuccess.next({ localId, type: data.metadata.type, id: uploadedAttachment._id });
    }
    return { loading: false, data: { id: uploadedAttachment._id } };
  }

  private async uploadToBackend(data: UploadData, localId: string, isFromQueue: boolean): Promise<AttachmentDto> {
    this._logger.info(`upload to backend ${localId}`);
    const attachmentService = this._feathersService.getService<AttachmentDto>('attachment');
    try {
      return await attachmentService.create(data);
    } catch (error) {
      if (!isFromQueue) {
        const clonedData = cloneDeep(data);
        clonedData.blob = await blobToArrayBuffer(data.blob as Blob);
        await this._attachmentDatabaseService.set(Database.AttachmentToSave, localId, {
          data: clonedData,
          localId,
        });
      }
      throw error;
    }
  }

  private async handleDuplicateError(localId: string): Promise<void> {
    try {
      await this._attachmentDatabaseService.purge(localId);
    } catch (error) {
      this._logger.error(`handleDuplicateError:remove ${localId}`, error);
    }
  }

  public async fetchBlob(id: string, mimeType: string): Promise<Blob> {
    const keys = await this._attachmentDatabaseService.keys(Database.Blob);
    const blobAlreadyInDb = keys.some(f => f === id);
    if (blobAlreadyInDb) {
      return;
    }
    const result = await this._feathersService.getService<ArrayBuffer>('attachment').get(id);
    const blob = arrayBufferToBlob(result, mimeType);
    await this._attachmentDatabaseService.writeBlobToStorage(id, blob);
    return blob;
  }

  public async delete(id: string): Promise<void> {
    const attachmetService = this._feathersService.getService('attachment');
    await attachmetService.remove(id);
  }

  public async update(id: string, data: any): Promise<any> {
    const attachmentService = this._feathersService.getService('attachment');
    return attachmentService.update(id, data);
  }
}
