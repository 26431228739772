import { KeyFunctionMap, RxCollection, RxJsonSchema } from 'rxdb';

export interface ChatCollections {
  channels: RxCollection<ChatChannel, {}>;
  members: RxCollection<ChatMember, {}>;
  messages: RxCollection<ChatMessage, {}>;
  consumptions: RxCollection<ChatChannelConsumptionStatus, {}>;
}

export interface ChatChannelConsumptionStatus {
  _id: string; // channel id
  localLastConsumedMessageIndex: number;
  syncLastConsumedMessageIndex: boolean;
}

export const chatChannelConsumptionSchema: RxJsonSchema<ChatChannelConsumptionStatus> = {
  title: 'channels',
  version: 0,
  description: 'All chat channels',
  type: 'object',
  properties: {
    _id: { type: 'string', primary: true }, // channelSid
    localLastConsumedMessageIndex: { type: 'number', default: -1 },
    syncLastConsumedMessageIndex: { type: 'boolean', default: false },
  },
};

export interface ChatChannel {
  sid: string;
  dateUpdated: string;
  dateCreated: string;
  friendlyName: string;
  uniqueName: string;
  lastMessageIndex: number;
  lastMessageTimestamp: string;
  lastLocalUpdateAt: string;
  lastConsumedMessageIndex: number;
  removed: boolean;
  isAgent?: boolean;
  attributes?: Object;
  updateReasons: string;
}

export const channelsSchema: RxJsonSchema<ChatChannel> = {
  title: 'channels',
  version: 6,
  description: 'All chat channels',
  type: 'object',
  properties: {
    sid: { type: 'string', primary: true },
    dateUpdated: { type: 'string' },
    dateCreated: { type: 'string' },
    friendlyName: { type: 'string', default: 'NO NAME' },
    uniqueName: { type: 'string' },
    lastMessageIndex: { type: ['number', 'null'] },
    lastMessageTimestamp: { type: 'string' },
    lastLocalUpdateAt: { type: 'string' },
    lastConsumedMessageIndex: { type: ['number', 'null'] },
    removed: { type: 'boolean' },
    isAgent: { type: 'boolean' },
    attributes: { type: 'object' },
    updateReasons: { type: 'string' },
  },
};

export const channelsMigrationStrategies: KeyFunctionMap = {
  1: oldDoc => oldDoc,
  2: oldDoc => oldDoc,
  3: oldDoc => oldDoc,
  4: oldDoc => oldDoc,
  5: oldDoc => oldDoc,
  6: oldDoc => oldDoc,
};

export interface ChatMessage {
  _id: string;
  channelSid: string;
  isLocal: boolean;
  sid: string;
  dateUpdated: string;
  timestamp: string;
  index: number;
  body: string;
  memberSid: string;
  memberIdentity: string;
  status: 'pending' | 'sent';
  retries: number;
  authorName?: string;
  attributes?: any;
}

export const messagesSchema: RxJsonSchema<ChatMessage> = {
  title: 'messages',
  version: 1,
  description: 'All chat messages',
  type: 'object',
  properties: {
    _id: { type: 'string', primary: true },
    channelSid: { type: 'string', index: true },
    isLocal: { type: 'boolean' },
    sid: { type: 'string' },
    dateUpdated: { type: 'string' },
    timestamp: { type: 'string' },
    index: { type: 'number', index: true },
    body: { type: 'string' },
    status: { type: 'string' },
    memberSid: { type: 'string' },
    memberIdentity: { type: 'string' },
    retries: { type: 'number' },
    attributes: { type: 'object' },
  },
};

export const messagesMigrationStrategies: KeyFunctionMap = {
  1: oldDoc => oldDoc,
};
export interface ChatMember {
  sid: string;
  channelSid: string;
  identity: string;
  lastConsumedMessageIndex: number;
  dateUpdated: string;
  isLocal: boolean;
  friendlyName?: string;
}

export const membersSchema: RxJsonSchema<ChatMember> = {
  title: 'members',
  version: 0,
  description: 'Channel members',
  type: 'object',
  properties: {
    sid: { type: 'string', primary: true },
    channelSid: { type: 'string', index: true },
    identity: { type: 'string' },
    lastConsumedMessageIndex: { type: 'number' },
    dateUpdated: { type: 'string' },
    isLocal: { type: 'boolean' },
  },
};
