import { ModuleWithProviders, NgModule } from '@angular/core';

import { PipelineToken } from './contracts/pipeline-token';
import { IScheduler } from './contracts/scheduler/scheduler';
import { SyncToken } from './contracts/sync-token';
import { ISyncElement } from './contracts/sync/sync-element';
import { ISyncRegistry } from './contracts/sync/sync-registry';
import { SyncScheduler } from './scheduler/sync-scheduler';
import { LocalFilesystemCleaner } from './start/local-filesystem-cleaner';
import { PlatformSyncDetector } from './start/platform-sync-detector';
import { ServerConnector } from './start/server-connector';
import { SyncRegistry } from './start/sync-registry';
import { Authenticator } from './sync/authenticator';
import { CommandQueueRunner } from './sync/command-queue-runner';
import { DatabaseCreator } from './sync/database-creator';
import { MaintenanceSynchroniser } from './sync/maintenance-synchronizer';
import { MaintenanceWatchStarter } from './sync/maintenance-watch-starter';
import { RootHistorySetter } from './sync/root-history-setter';
import { ShutdownInfoListener } from './sync/shutdown-info-listener';
import { SyncIndicatorCloser } from './sync/sync-indicator-closer';
import { SyncIndicatorOpener } from './sync/sync-indicator-opener';
import { SyncIndicatorPreCloser } from './sync/sync-indicator-pre-closer';
import { SyncOrderToken } from './sync/sync-order';
import { SyncStarter } from './sync/sync-starter';
import { TokenHandler } from './sync/token-handler';
import { WebSyncStarter } from './sync/web-sync-starter';

let syncRegistry: ISyncRegistry = null;

export function syncRegistryFactory(syncScheduler: IScheduler, syncElements: ISyncElement[], syncOrders: string[]) {
  if (syncRegistry) {
    return syncRegistry;
  }

  syncRegistry = new SyncRegistry(syncScheduler, syncElements, syncOrders);

  return syncRegistry;
}

@NgModule({ providers: [] })
export class PipelineModule {
  static forRoot(): ModuleWithProviders<PipelineModule> {
    return {
      ngModule: PipelineModule,
      providers: [
        { provide: PipelineToken, useClass: ServerConnector, multi: true },
        { provide: PipelineToken, useClass: PlatformSyncDetector, multi: true },
        {
          provide: PipelineToken,
          useFactory: syncRegistryFactory,
          deps: [SyncScheduler, SyncToken, SyncOrderToken],
          multi: true,
        },
        { provide: PipelineToken, useClass: LocalFilesystemCleaner, multi: true },
        { provide: SyncToken, useClass: Authenticator, multi: true },
        { provide: SyncToken, useClass: CommandQueueRunner, multi: true },
        { provide: SyncToken, useClass: MaintenanceSynchroniser, multi: true },
        { provide: SyncToken, useClass: SyncIndicatorPreCloser, multi: true },
        { provide: SyncToken, useClass: SyncIndicatorOpener, multi: true },
        { provide: SyncToken, useClass: DatabaseCreator, multi: true },
        { provide: SyncToken, useClass: SyncIndicatorCloser, multi: true },
        { provide: SyncToken, useClass: SyncStarter, multi: true },
        { provide: SyncToken, useClass: RootHistorySetter, multi: true },
        { provide: SyncToken, useClass: WebSyncStarter, multi: true },
        { provide: SyncToken, useClass: TokenHandler, multi: true },
        { provide: SyncToken, useClass: MaintenanceWatchStarter, multi: true },
        { provide: SyncToken, useClass: ShutdownInfoListener, multi: true },
        {
          provide: SyncRegistry,
          useFactory: syncRegistryFactory,
          deps: [SyncScheduler, SyncToken, SyncOrderToken],
        },
      ],
    };
  }

  static forChild(): ModuleWithProviders<PipelineModule> {
    return { ngModule: PipelineModule };
  }
}
