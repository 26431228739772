import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ChatAgentGuard } from './chat-agent/chat-agent.guard';
import { ChatGuard } from './chat/chat.guard';
import { InstitutionContactResolver } from './institution/master/institution-contact/institution-contact.resolver';
import { InstitutionNoteResolver } from './institution/master/institution-note/institution-note.resolver';
import { SubunitResolver } from './institution/master/subunit/subunit.resolver';
import { NotesResolver } from './patient/components/patient-detail/sections/notes/notes-detail/notes.resolver';
import { ReturnDeliveryResolver } from './patient/components/return-delivery-detail/return-delivery.resolver';
import { ReportGuard } from './report/report.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { NoAuthGuard } from './shared/guards/no-auth.guard';
import { PermissionGuard } from './shared/guards/permission.guard';
import { AppointmentResolver } from './shared/resolver/appointment.resolver';
import { InstitutionAuditResolver } from './shared/resolver/institutionaudit.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'patient/:patientId/careproposal/returndelivery/:returnDeliveryId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { returnDelivery: ReturnDeliveryResolver },
    loadChildren: () =>
      import('./patient/components/return-delivery-detail/return-delivery-detail.module').then(
        m => m.ReturnDeliveryDetailModule
      ),
  },
  {
    path: 'patient/:patientId/notes/:noteId',
    resolve: { data: NotesResolver },
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      import('./patient/components/patient-detail/sections/notes/notes-detail/notes-detail.module').then(
        m => m.NotesDetailModule
      ),
  },
  {
    path: 'patient/:patientId/activities/:appointmentId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { appointment: AppointmentResolver },
    loadChildren: () =>
      import('./patient/components/patient-detail/sections/activities/activities-detail/activities-detail.module').then(
        m => m.ActivitiesDetailModule
      ),
  },
  {
    path: 'patient/:patientId/attachments/detail/:attachmentId',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      import('./patient/components/patient-detail/sections/attachment/attachment-detail/attachment-detail.module').then(
        m => m.AttachmentDetailModule
      ),
  },
  {
    path: 'patient/:patientId/attachments/patientapp/detail/:attachmentId',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      import(
        // tslint:disable-next-line:max-line-length
        './patient/components/patient-detail/sections/attachment/patient-app-attachment-detail/patient-app-attachment-detail.module'
      ).then(m => m.PatientAppAttachmentDetailModule),
  },
  {
    path: 'patient/:patientId/device/:deviceId',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      import('./patient/components/patient-detail/sections/device/device-detail/device-detail.module').then(
        m => m.DeviceDetailModule
      ),
  },
  {
    path: 'patient/:patientId/erpTask/:erpTaskId',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      import('./patient/components/patient-detail/sections/dashboard/tasks/erpTask-detail/erp-task-detail.module').then(
        m => m.ErpTaskDetailModule
      ),
  },
  {
    path: 'patient/:patientId/plannedOrder/:plannedOrderId',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      import(
        './patient/components/patient-detail/sections/dashboard/tasks/planned-order-detail/planned-order-detail.module'
      ).then(m => m.PlannedOrderDetailModule),
  },
  {
    path: 'patient/:patientId/documents/:id',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./swodoc/swodoc.module').then(m => m.SwodocModule),
  },
  {
    path: 'patient/:patientId/integratedCare/:integratedCareId',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      // tslint:disable-next-line:max-line-length
      import(
        './patient/components/patient-detail/sections/integratedcare/integratedcare-detail/integratedcare-detail.module'
      ).then(m => m.IntegratedCareDetailModule),
  },
  {
    path: 'patient/:patientId/integratedCare/:integratedCareId/singleOrder/:singleOrderId',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      // tslint:disable-next-line:max-line-length
      import(
        './patient/components/patient-detail/sections/integratedcare/single-order-detail/single-order-detail.module'
      ).then(m => m.SingleOrderDetailModule),
  },
  {
    path: 'patient/:patientId/directOrder/:directOrderId',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      // tslint:disable-next-line:max-line-length
      import(
        './patient/components/patient-detail/sections/integratedcare/direct-order-detail/direct-order-detail.module'
      ).then(m => m.DirectOrderDetailModule),
  },
  {
    path: 'patient/:patientId/crossTherapy/:directOrderId',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      // tslint:disable-next-line:max-line-length
      import(
        './patient/components/patient-detail/sections/integratedcare/cross-therapy-detail/cross-therapy-detail.module'
      ).then(m => m.CrossTherapyDetailModule),
  },
  {
    path: 'patient',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule),
  },
  {
    path: 'emergency',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./emergency/emergency.module').then(m => m.EmergencyModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },

  {
    path: 'report',
    canActivate: [AuthGuard, ReportGuard],
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
  },
  {
    path: 'fax',
    canActivate: [AuthGuard],
    loadChildren: () => import('./fax/fax.module').then(m => m.FaxModule),
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
  },
  {
    path: 'setting',
    canActivate: [AuthGuard],
    loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule),
  },
  {
    path: 'maintenance',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./maintenance/maintenance-management.module').then(m => m.MaintenanceMangagementModule),
  },
  {
    path: 'article',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./article/desktop/article-desktop.module').then(m => m.ArticleListDesktopModule),
  },
  {
    path: 'article-mobile',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./article/mobile/article-mobile.module').then(m => m.ArticleModule),
  },
  {
    path: 'standard-care-proposal',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      import('./standard-care-proposal/standard-care-proposal.module').then(m => m.StandardCareProposalModule),
  },
  {
    path: 'product-group',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./product-group/product-group.module').then(m => m.ProductGroupModule),
  },
  {
    path: 'insurance-contract',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./insurance-contract/insurance-contract.module').then(m => m.InsuranceContractModule),
  },
  {
    path: 'nursing-home',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./institution/search/nursing-home/nursing-home.module').then(m => m.NursingHomeModule),
  },
  {
    path: 'nursing-home/:id/contacts/:contactId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { contact: InstitutionContactResolver },
    loadChildren: () =>
      import('./institution/master/institution-contact/detail/institution-contact-detail.module').then(
        m => m.InstitutionContactDetailModule
      ),
  },
  {
    path: 'nursing-home/:id/notes/:noteId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { note: InstitutionNoteResolver },
    loadChildren: () =>
      import('./institution/master/institution-note/detail/institution-note-detail.module').then(
        m => m.InstitutionNoteDetailModule
      ),
  },
  {
    path: 'nursing-home/:id/activities/:appointmentId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { appointment: AppointmentResolver },
    loadChildren: () =>
      import('./institution/master/institution-activities/detail/institution-activities-detail.module').then(
        m => m.InstitutionActivitiesDetailModule
      ),
  },
  {
    path: 'nursing-home/:id/subunits/:subunitId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { subunit: SubunitResolver },
    loadChildren: () =>
      import('./institution/master/subunit/detail/subunit-detail.module').then(m => m.SubunitDetailModule),
  },
  {
    path: 'nursing-home/:id/documents/:institutionauditId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { audit: InstitutionAuditResolver },
    loadChildren: () =>
      import('./institution/master/institution-documents/detail/institution-documents-detail.module').then(
        m => m.InstitutionDocumentsDetailModule
      ),
  },
  {
    path: 'doctor',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./institution/search/doctor/doctor.module').then(m => m.DoctorModule),
  },
  {
    path: 'doctor/:id/contacts/:contactId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { contact: InstitutionContactResolver },
    loadChildren: () =>
      import('./institution/master/institution-contact/detail/institution-contact-detail.module').then(
        m => m.InstitutionContactDetailModule
      ),
  },
  {
    path: 'doctor/:id/notes/:noteId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { note: InstitutionNoteResolver },
    loadChildren: () =>
      import('./institution/master/institution-note/detail/institution-note-detail.module').then(
        m => m.InstitutionNoteDetailModule
      ),
  },
  {
    path: 'doctor/:id/documents/:institutionauditId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { audit: InstitutionAuditResolver },
    loadChildren: () =>
      import('./institution/master/institution-documents/detail/institution-documents-detail.module').then(
        m => m.InstitutionDocumentsDetailModule
      ),
  },
  {
    path: 'hospital',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./institution/search/hospital/hospital.module').then(m => m.HospitalModule),
  },
  {
    path: 'hospital/:id/contacts/:contactId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { contact: InstitutionContactResolver },
    loadChildren: () =>
      import('./institution/master/institution-contact/detail/institution-contact-detail.module').then(
        m => m.InstitutionContactDetailModule
      ),
  },
  {
    path: 'hospital/:id/notes/:noteId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { note: InstitutionNoteResolver },
    loadChildren: () =>
      import('./institution/master/institution-note/detail/institution-note-detail.module').then(
        m => m.InstitutionNoteDetailModule
      ),
  },
  {
    path: 'hospital/:id/subunits/:subunitId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { subunit: SubunitResolver },
    loadChildren: () =>
      import('./institution/master/subunit/detail/subunit-detail.module').then(m => m.SubunitDetailModule),
  },
  {
    path: 'hospital/:id/documents/:institutionauditId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { audit: InstitutionAuditResolver },
    loadChildren: () =>
      import('./institution/master/institution-documents/detail/institution-documents-detail.module').then(
        m => m.InstitutionDocumentsDetailModule
      ),
  },
  {
    path: 'pharmacy',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./institution/search/pharmacy/pharmacy.module').then(m => m.PharmacyModule),
  },
  {
    path: 'pharmacy/:id/contacts/:contactId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { contact: InstitutionContactResolver },
    loadChildren: () =>
      import('./institution/master/institution-contact/detail/institution-contact-detail.module').then(
        m => m.InstitutionContactDetailModule
      ),
  },
  {
    path: 'pharmacy/:id/notes/:noteId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { note: InstitutionNoteResolver },
    loadChildren: () =>
      import('./institution/master/institution-note/detail/institution-note-detail.module').then(
        m => m.InstitutionNoteDetailModule
      ),
  },
  {
    path: 'pharmacy/:id/subunits/:subunitId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { subunit: SubunitResolver },
    loadChildren: () =>
      import('./institution/master/subunit/detail/subunit-detail.module').then(m => m.SubunitDetailModule),
  },
  {
    path: 'pharmacy/:id/documents/:institutionauditId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { audit: InstitutionAuditResolver },
    loadChildren: () =>
      import('./institution/master/institution-documents/detail/institution-documents-detail.module').then(
        m => m.InstitutionDocumentsDetailModule
      ),
  },
  {
    path: 'nursing-service',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () =>
      import('./institution/search/nursing-service/nursing-service.module').then(m => m.NursingServiceModule),
  },
  {
    path: 'nursing-service/:id/contacts/:contactId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { contact: InstitutionContactResolver },
    loadChildren: () =>
      import('./institution/master/institution-contact/detail/institution-contact-detail.module').then(
        m => m.InstitutionContactDetailModule
      ),
  },
  {
    path: 'nursing-service/:id/notes/:noteId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { note: InstitutionNoteResolver },
    loadChildren: () =>
      import('./institution/master/institution-note/detail/institution-note-detail.module').then(
        m => m.InstitutionNoteDetailModule
      ),
  },
  {
    path: 'nursing-service/:id/subunits/:subunitId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { subunit: SubunitResolver },
    loadChildren: () =>
      import('./institution/master/subunit/detail/subunit-detail.module').then(m => m.SubunitDetailModule),
  },
  {
    path: 'nursing-service/:id/documents/:institutionauditId',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: { audit: InstitutionAuditResolver },
    loadChildren: () =>
      import('./institution/master/institution-documents/detail/institution-documents-detail.module').then(
        m => m.InstitutionDocumentsDetailModule
      ),
  },
  {
    path: 'rights-management',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./rights-management/rights-management.module').then(m => m.RightsManagementModule),
  },
  {
    path: 'chat',
    canActivate: [AuthGuard, ChatGuard],
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
  },
  {
    path: 'agent-chat',
    canActivate: [AuthGuard, ChatAgentGuard],
    loadChildren: () => import('./chat-agent/chat-agent.module').then(m => m.ChatAgentModule),
  },
  {
    path: 'agent-admin',
    canActivate: [AuthGuard, ChatAgentGuard],
    loadChildren: () => import('./agent-admin/agent-admin.module').then(m => m.AgentAdminModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  providers: [
    ReturnDeliveryResolver,
    InstitutionContactResolver,
    InstitutionNoteResolver,
    SubunitResolver,
    NotesResolver,
    AppointmentResolver,
    InstitutionAuditResolver,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
