import { INursingHome } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { NursingHomeDB } from 'src/app/common/repository/databases';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';
import { QueryService } from 'src/app/shared/services/query/query.service';

import { MessagePayloadNursingHome } from '../../models/share/message-payload-nursingHome';
import { IRouteParser } from '../contracts/route/route-parser';

@Injectable()
export class NursingHomeRouteParser implements IRouteParser {
  type = MessagePayloadType.NursingHome;
  constructor(private _queryService: QueryService) {}

  async parse(payload: MessagePayloadNursingHome): Promise<string> {
    const nursingHome: INursingHome = await this._queryService.get(payload.nursingHomeId, NursingHomeDB);

    if (!nursingHome) {
      throw new Error(`No nursingHome found for id ${payload.nursingHomeId}`);
    }

    return `/nursing-home/${payload.nursingHomeId}/${payload.path}`;
  }
}
