import { IModel } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';

import { IDispatcher } from '../contracts/dispatch/dispatcher';
import { StateExtensionAction } from '../contracts/state/state-extension-action';
import { IStateRegistry } from '../contracts/state/state-registry';
import { ISyncState } from '../contracts/sync/sync-state';
import { StateRegistry } from '../state/state-registry';

@Injectable({ providedIn: 'root' })
export class Dispatcher implements IDispatcher<IModel> {
  constructor(@Inject(StateRegistry) private _stateRegistry: IStateRegistry) {}

  public async runExtension(id: string, action: StateExtensionAction, items: IModel[]): Promise<void> {
    await this._stateRegistry.runExtension(id, action, items);
  }

  public async createState(modelName: string, payload: IModel, path?: string): Promise<void> {
    await this._stateRegistry.createBySync(modelName, path || 'items', payload);

    await this._stateRegistry.runExtension(modelName, StateExtensionAction.create, [payload]);
  }

  public async updateState(modelName: string, payload: IModel[], path?: string): Promise<void> {
    await this._stateRegistry.updateBySync(modelName, path || 'items', payload);

    await this._stateRegistry.runExtension(modelName, StateExtensionAction.update, payload);
  }

  public async removeFromState(modelName: string, payload: IModel, path?: string): Promise<void> {
    await this._stateRegistry.removeBySync(modelName, path || 'items', [payload]);
  }

  public async removeAllFromState(modelName: string, path?: string): Promise<void> {
    await this._stateRegistry.removeAllFromState(modelName, path || 'items');
  }

  public async sync(modelName: string, syncState: ISyncState<IModel>): Promise<void> {
    await this._stateRegistry.removeBySync(modelName, 'items', syncState.deleted);
    await this._stateRegistry.updateBySync(modelName, 'items', syncState.updated);

    await this._stateRegistry.runExtension(modelName, StateExtensionAction.create, syncState.created);
    await this._stateRegistry.runExtension(modelName, StateExtensionAction.update, syncState.updated);
  }
}
