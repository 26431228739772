import { Inject, Injectable, NgZone } from '@angular/core';
import makeDebug from 'src/makeDebug';
import * as TwilioChat from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';
import { Member } from 'twilio-chat/lib/member';
import { Message } from 'twilio-chat/lib/message';

import { ChatConnectionStateService } from '../chat-connection-state.service';
import { ChatEventService } from '../chat-event.service';
import { Chat } from '../model/chat-instance';
import { TWILIO_AGENT_TOKEN } from './agent-twilio.factory';
import { TwilioToChatDtoHelpersService } from './twilio-to-chat-dto-helpers';
import { TWILIO_TOKEN } from './twilio.factory';

const debug = makeDebug('services:chat:twilioSync');

@Injectable({ providedIn: 'root' })
export class TwilioChatEventSourceService {
  constructor(
    @Inject(TWILIO_TOKEN) private readonly _twilioChatClient: Promise<TwilioChat.Client>,
    @Inject(TWILIO_AGENT_TOKEN) private readonly _twilioAgentChatClient: Promise<TwilioChat.Client>,
    private readonly _twilioToChatDtoHelpers: TwilioToChatDtoHelpersService,
    private readonly _chatEventService: ChatEventService,
    private readonly _chatConnectionStateService: ChatConnectionStateService,
    private readonly _ngZone: NgZone
  ) {
    this.initialize();
  }

  private initialize() {
    debug('init');
    this._ngZone.runOutsideAngular(() => {
      const timeout = setTimeout(async () => {
        clearTimeout(timeout);
        await this.setupChannelEvents();
        await this.setupMessageEvents();
        await this.setupTokenEvents();
        await this.setupConnectionStateEvents();
      });
    });
  }

  private async setupChannelEvents() {
    const twilioClient = await this._twilioChatClient;

    debug('setup channel events');
    twilioClient.on('channelAdded', channel => this.addChannel(channel));
    twilioClient.on('channelUpdated', ({ channel, updateReasons }: Channel.UpdatedEventArgs) =>
      this.updateChannel(channel, updateReasons)
    );
    twilioClient.on('channelRemoved', (channel: Channel) => this.removeChannel(channel));

    const twilioAgentClient = await this._twilioAgentChatClient;
    if (!twilioAgentClient) {
      return;
    }

    debug('setup agent channel events');

    twilioAgentClient.on('channelAdded', channel => this.addChannel(channel, Chat.PatientApp));
    twilioAgentClient.on('channelUpdated', ({ channel, updateReasons }: Channel.UpdatedEventArgs) =>
      this.updateChannel(channel, updateReasons, Chat.PatientApp)
    );
    twilioAgentClient.on('channelRemoved', (channel: Channel) => this.removeChannel(channel));
  }

  private async setupMessageEvents() {
    const twilioClient = await this._twilioChatClient;

    debug('setup message events');
    twilioClient.on('messageAdded', (message: Message) => this.addMessage(message));
    twilioClient.on('messageRemoved', (message: Message) => this.removeMessage(message));
    twilioClient.on('memberJoined', (member: Member) => this.addMemberToChannel(member));
    twilioClient.on('memberLeft', (member: Member) => this.removeMemberFromChannel(member));

    const twilioAgentClient = await this._twilioAgentChatClient;
    if (!twilioAgentClient) {
      return;
    }
    debug('setup message events for agents');
    twilioAgentClient.on('messageAdded', (message: Message) => this.addMessage(message, Chat.PatientApp));
    twilioAgentClient.on('messageRemoved', (message: Message) => this.removeMessage(message));
    twilioAgentClient.on('memberJoined', (member: Member) => this.addMemberToChannel(member));
    twilioAgentClient.on('memberLeft', (member: Member) => this.removeMemberFromChannel(member));
  }

  private async setupTokenEvents() {
    const twilioClient = await this._twilioChatClient;

    debug('setup token events');
    twilioClient.on('tokenAboutToExpire', () => this.reactToTokenAboutToExpire(Chat.Alberta));
    twilioClient.on('tokenExpired', () => this.reactToTokenExpired(Chat.PatientApp));

    const twilioAgentClient = await this._twilioAgentChatClient;
    if (!twilioAgentClient) {
      return;
    }
    debug('setup token events');
    twilioAgentClient.on('tokenAboutToExpire', () => this.reactToTokenAboutToExpire(Chat.Alberta));
    twilioAgentClient.on('tokenExpired', () => this.reactToTokenExpired(Chat.PatientApp));
  }

  private async addChannel(channel: Channel, chat = Chat.Alberta) {
    debug('twilio: channel added', channel);
    const chatChannel = this._twilioToChatDtoHelpers.convertToChatChannel(channel);
    chatChannel.isAgent = chat === Chat.Alberta ? undefined : true;
    await this._chatEventService.addChatChannel(chatChannel);

    const members = await channel.getMembers();
    const chatMembers = members.map(member => this._twilioToChatDtoHelpers.convertToChatMember(member));

    return this._chatEventService.updateChannelMembers(channel.sid, chatMembers);
  }

  private async updateChannel(channel: Channel, updateReasons: Channel.UpdateReason[], chat = Chat.Alberta) {
    debug('twilio: channel updated', channel, updateReasons);
    const chatChannel = this._twilioToChatDtoHelpers.convertToChatChannel(
      channel,
      chat === Chat.PatientApp ? updateReasons.join('#') : undefined
    );
    chatChannel.isAgent = chat === Chat.Alberta ? undefined : true;

    return this._chatEventService.updateChatChannel(chatChannel);
  }

  private async removeChannel(channel: Channel) {
    debug('twilio: channel removed', channel);
    return this._chatEventService.removeChatChannelById(channel.sid);
  }

  private async addMemberToChannel(member: Member) {
    debug('twilio: member joined', member);
    const channelMember = this._twilioToChatDtoHelpers.convertToChatMember(member);
    return this._chatEventService.addMemberToChannel(channelMember);
  }

  private async removeMemberFromChannel(member: Member) {
    debug('twilio: member left', member);
    return this._chatEventService.removeMemberFromChannel(member.channel.sid, member.sid);
  }

  private async addMessage(message: Message, chat = Chat.Alberta) {
    debug('twilio: message added', message);
    const chatMessage = await this._twilioToChatDtoHelpers.convertToChatMessage(message);
    const attributes = chatMessage.attributes || {};
    chatMessage.attributes = chat === Chat.PatientApp ? { ...attributes, isAgent: true } : chatMessage.attributes;

    return this._chatEventService.addMessage(chatMessage);
  }

  private async removeMessage(message: Message) {
    debug('twilio: message removed', message);
    return this._chatEventService.removeMessageById(message.sid);
  }

  private async reactToTokenAboutToExpire(chat: Chat) {
    debug('twilio: token about to expire');
    await this._chatEventService.reactToTokenAboutToExpire(chat);
  }

  private async reactToTokenExpired(chat: Chat) {
    debug('twilio: token expired');
    await this._chatEventService.reactToTokenExpired(chat);
  }

  private async setupConnectionStateEvents() {
    const twilioClient = await this._twilioChatClient;

    twilioClient.on('connectionStateChanged', state => this.handleConnectionStateChanged(state));
    if (twilioClient.connectionState === 'connected') {
      this._chatConnectionStateService.setConnectionState('connected');
    }

    const twilioAgentClient = await this._twilioAgentChatClient;
    if (!twilioAgentClient) {
      return;
    }
    twilioAgentClient.on('connectionStateChanged', state => this.handleConnectionStateChanged(state, Chat.PatientApp));
    if (twilioAgentClient.connectionState === 'connected') {
      this._chatConnectionStateService.setConnectionState('connected', Chat.PatientApp);
    }
  }

  private handleConnectionStateChanged(connectionState, chat = Chat.Alberta) {
    debug('connection state changed', connectionState);
    this._chatConnectionStateService.setConnectionState(connectionState, chat);
  }
}
