import { Inject, Injectable } from '@angular/core';

import makeDebug from '../../../../makeDebug';
import { IScheduler } from '../contracts/scheduler/scheduler';
import { IPipelineContext } from '../contracts/start/pipeline-context';
import { PlatformSyncDetector, SyncRegistry as Registry } from '../contracts/start/pipeline-endpoints';
import { SyncToken } from '../contracts/sync-token';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { ISyncRegistry } from '../contracts/sync/sync-registry';
import { SyncScheduler } from '../scheduler/sync-scheduler';
import { SyncOrderToken } from '../sync/sync-order';

const debug = makeDebug('sync:sync-registry');

@Injectable()
export class SyncRegistry implements ISyncRegistry {
  public get endpoint(): string {
    return Registry;
  }

  public get parent(): string {
    return PlatformSyncDetector;
  }

  private _context: ISyncContext = {
    params: { isStartUpSync: true, isLogin: false },
    executeOnlyOnce: new Map<string, boolean>(),
  };

  constructor(
    @Inject(SyncScheduler) private _syncScheduler: IScheduler,
    @Inject(SyncToken) private _syncElements: ISyncElement[],
    @Inject(SyncOrderToken) private _syncOrder: string[]
  ) {
    this.sortElementsByOrder();
  }

  public execute(context: IPipelineContext): Promise<void> {
    context.executeOnlyOnce.set(this.endpoint, true);

    debug('calling schedule of sync-scheduler in execute');
    this._syncScheduler.schedule(this, context);

    return;
  }

  public async start(context: IPipelineContext): Promise<void> {
    this._context.params.noAuth = false;

    if (context.params.isOnline != null) {
      this._context.params.isOnline = context.params.isOnline;
    }

    this._context.params.isLogin = context.params.isLogin;
    debug('entered start function', this._context);
    for (const syncElement of this._syncElements) {
      try {
        if (this._context.executeOnlyOnce.get(syncElement.name)) {
          continue;
        }
        debug('calling execute of sync element', syncElement.name);
        await syncElement.execute(this._context);
        if (this._context.params.noAuth && !this._context.params.isLogin) {
          debug('breaking loop whit noAuth', this._context);
          break;
        }
      } catch (error) {
        window.logger.error(`Failed sync process for step: ${syncElement.name}`, error);
        throw error;
      }
    }
  }

  public add(newSyncElement: ISyncElement, parent?: string): void {
    debug('adding new sync element', newSyncElement.name);
    if (parent) {
      const index = this._syncElements.findIndex(syncElement => syncElement.name === parent);
      this._syncElements.splice(index + 1, 0, newSyncElement);
    } else {
      this._syncElements.splice(0, 0, newSyncElement);
    }
  }

  private sortElementsByOrder(): void {
    const sortedSyncElements: ISyncElement[] = [];

    for (const endpoint of this._syncOrder) {
      const syncElement = this._syncElements.find(element => element.name === endpoint);
      if (!syncElement) {
        throw new Error(`Sync element for endpoint ${endpoint} is undefined.`);
      }

      sortedSyncElements.push(syncElement);
    }

    this._syncElements.length = 0;
    this._syncElements.push(...sortedSyncElements);
  }
}
