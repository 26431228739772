import { OrderStatus } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'orderStatusPipe',
})
export class OrderStatusPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  transform(item: OrderStatus): string {
    switch (item) {
      case OrderStatus.Pending:
        return this._translate.instant('order.status.-3');
      case OrderStatus.Canceled:
        return this._translate.instant(`order.status.7`);
      case OrderStatus.Deleted:
        return this._translate.instant(`order.status.6`);
      case OrderStatus.Created:
      case OrderStatus.Transmitted:
      case OrderStatus.Transmission_failure:
      case OrderStatus.Processed:
      case OrderStatus.Sent:
      case OrderStatus.Delivered:
        return this._translate.instant(`order.status.${item}`);
      case OrderStatus.OrderStopped:
        return this._translate.instant(`order.status.8`);
      case OrderStatus.ErpCall:
        return this._translate.instant(`order.status.9`);
    }
  }
}
