import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { ObservableModel } from 'src/app/common/viewmodel/observable-model';

@Injectable({ providedIn: 'root' })
export class NetworkInfoService {
  private _slowConnectionModel = new ObservableModel<boolean>(false, false);
  private _wasPaused = false;

  get isConnectionSlow(): boolean {
    return this._slowConnectionModel.get();
  }

  constructor(private readonly _network: Network, private readonly _platform: Platform) {}

  public async init() {
    await this._platform.ready();

    if (!this._platform.is('cordova')) {
      return;
    }

    this._platform.pause.subscribe(() => (this._wasPaused = true));

    this._platform.resume.subscribe(() => {
      if (!this._wasPaused) {
        return;
      }

      setTimeout(() => {
        this._slowConnectionModel.set(
          this._network.type === this._network.Connection.CELL_2G ||
            this._network.type === this._network.Connection.CELL_3G ||
            this._network.type === this._network.Connection.NONE
        );
      }, 1000);
    });

    this._network.onChange().subscribe(connectionState => {
      if (connectionState === 'connected') {
        this._slowConnectionModel.set(
          this._network.type === this._network.Connection.CELL_2G ||
            this._network.type === this._network.Connection.CELL_3G ||
            this._network.type === this._network.Connection.NONE
        );
      } else {
        this._slowConnectionModel.set(true);
      }
    });

    this._network.onConnect().subscribe(() => {
      this._slowConnectionModel.set(
        this._network.type === this._network.Connection.CELL_2G ||
          this._network.type === this._network.Connection.CELL_3G ||
          this._network.type === this._network.Connection.NONE
      );
    });
  }
}
