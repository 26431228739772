<itl-chat-message-list
  #chatMessageList
  (markMessagesAsRead)="this.markLastMessageAsRead()"
  (loadMoreMessages)="this.handleLoadMoreMessages()"
>
  <div class="loading-container" *ngIf="this.showLoadingIndicator">
    <itl-loading-indicator></itl-loading-indicator>
    <h2 class="loading-text">{{ 'chat.messages.loading' | translate }}</h2>
  </div>
  <div class="messages" *ngIf="!this.showLoadingIndicator && messagesByDay">
    <!-- show date created if first message is initial message -->
    <ng-container *ngIf="this.messages[0]?.index === 0 || this.messages.length === 0">
      <itl-chat-notification-message>{{
        this.channel.dateCreated | channelActivityDate
      }}</itl-chat-notification-message>
      <itl-chat-notification-message>{{ 'chat.channels.created' | translate }}</itl-chat-notification-message>
    </ng-container>
    <ng-container *ngFor="let dayEntry of messagesByDay">
      <itl-chat-notification-message>{{ dayEntry.dayTimestamp | channelActivityDate }}</itl-chat-notification-message>
      <itl-chat-message-component
        *ngFor="let message of dayEntry.messages; let messageDisplayIndex = index"
        [text]="message.body"
        [messageTime]="message.timestamp | messageTime"
        [authorName]="message.authorName"
        [colorHex]="message.memberIdentity | valueToHexColor"
        [status]="message.status"
        [type]="message.isLocal ? 'local' : 'remote'"
        [showNames]="showNames && (messageDisplayIndex == 0 || message.changedMemberSidSinceLastMessage)"
        [changedMemberSid]="messageDisplayIndex > 0 && message.changedMemberSidSinceLastMessage"
        [payload]="message.attributes"
        (messageClicked)="messageClicked.emit($event)"
      ></itl-chat-message-component>
    </ng-container>
  </div>
</itl-chat-message-list>
