import { Inject, Injectable } from '@angular/core';
import { ChatDbService } from 'src/app/shared/services/chat/data/chat-db.service';
import makeDebug from 'src/makeDebug';
import * as TwilioChat from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';

import { ChatSendService } from '../chat-send.service';
import { ChatChannel, ChatMessage } from '../data/db-schema';
import { Chat } from '../model/chat-instance';
import { TWILIO_AGENT_TOKEN } from './agent-twilio.factory';
import { TwilioToChatDtoHelpersService } from './twilio-to-chat-dto-helpers';
import { TWILIO_TOKEN } from './twilio.factory';

const debug = makeDebug('services:chat:twilio-sendService');

@Injectable({
  providedIn: 'root',
})
export class TwilioChatSendService implements ChatSendService {
  constructor(
    @Inject(TWILIO_TOKEN) private readonly _twilioChatClient: Promise<TwilioChat.Client>,
    @Inject(TWILIO_AGENT_TOKEN) private readonly _twilioAgentChatClient: Promise<TwilioChat.Client>,
    private readonly twilioDtoHelper: TwilioToChatDtoHelpersService,
    private readonly chatDBService: ChatDbService
  ) {}

  /**
   * Only works in online mode
   */
  public async createChat(name: string, chatUserChatIdentitites: string[]): Promise<ChatChannel> {
    const client = await this._twilioChatClient;
    let uniqueName: string;
    if (chatUserChatIdentitites.length === 2) {
      chatUserChatIdentitites.sort((a, b) => a.localeCompare(b));
      uniqueName = chatUserChatIdentitites.join('§');
      if (uniqueName.indexOf('§') === -1) {
        return;
      }
      const existingChannel = await this.tryToGetUniqueChannel(uniqueName);
      if (existingChannel) {
        await this.writeMembersToDb(existingChannel);
        return this.twilioDtoHelper.convertToChatChannel(existingChannel);
      }
    }

    const channel = await client.createChannel({
      isPrivate: true,
      friendlyName: name,
      uniqueName,
    });
    const invites = chatUserChatIdentitites.map(identity => channel.add(identity));
    await Promise.all(invites);
    await this.writeMembersToDb(channel);
    // TODO test
    return this.twilioDtoHelper.convertToChatChannel(channel);
  }

  private async writeMembersToDb(channel: Channel) {
    for (const member of await channel.getMembers()) {
      await this.chatDBService.upsertChannelMember(this.twilioDtoHelper.convertToChatMember(member));
    }
  }

  private async tryToGetUniqueChannel(uniqueName: string) {
    const client = await this._twilioChatClient;
    try {
      const existingChannel = await client.getChannelByUniqueName(uniqueName);
      return existingChannel;
    } catch (error) {
      window.logger.error('Getting channel by unique name failed.', error);
    }
  }

  public async fetchMessagesOfChannel(
    channelSid: string,
    anchor: number,
    count: number,
    chat = Chat.Alberta
  ): Promise<ChatMessage[]> {
    debug('fetch messages of channel', { channelSid, anchor, count });
    const client = chat === Chat.Alberta ? await this._twilioChatClient : await this._twilioAgentChatClient;
    if (!client) {
      return [];
    }
    const channel = await client.getChannelBySid(channelSid);
    const messages = await channel.getMessages(count, anchor);
    const convertedMessages = [];

    for (const message of messages.items) {
      const convertedMessage = await this.twilioDtoHelper.convertToChatMessage(message);
      convertedMessages.push(convertedMessage);
    }
    debug('got messages from twlio', { channelSid, anchor, count, convertedMessages });
    return convertedMessages;
  }

  public async sendMessage(
    channelId: string,
    bodyText: string,
    attributes: {},
    chat = Chat.Alberta
  ): Promise<ChatMessage> {
    debug('send message in queue', bodyText);
    const twilioClient: TwilioChat.Client =
      chat === Chat.Alberta ? await this._twilioChatClient : await this._twilioAgentChatClient;
    if (!twilioClient) {
      return null;
    }
    const channel: Channel = await twilioClient.getChannelBySid(channelId);
    if (!channel) {
      throw new Error('channel not found:' + channelId);
    }
    debug('sending message...', bodyText);

    const result = await channel.sendMessage(bodyText, attributes);
    if (!Number.isInteger(result)) {
      throw new Error('send-failed-with:' + result);
    }
    const message = await channel.getMessages(1, result);
    debug('got send result for', bodyText, 'result:', result);
    return this.twilioDtoHelper.convertToChatMessage(message.items[0]);
  }

  public async setConsumptionIndex(channelId: string, index: number): Promise<void> {
    debug('set consumption index', { channelId, index });
    const twilioClient: TwilioChat.Client = await this._twilioChatClient;
    if (!twilioClient) {
      return;
    }
    const channel: Channel = await twilioClient.getChannelBySid(channelId);
    if (!channel) {
      throw new Error('channel not found:' + channelId);
    }
    await channel.advanceLastConsumedMessageIndex(index);
  }

  public async setAgentConsumptionIndex(channelId: string, index: number): Promise<void> {
    debug('set consumption index', { channelId, index });
    const twilioClient: TwilioChat.Client = await this._twilioAgentChatClient;
    if (!twilioClient) {
      return;
    }
    const channel: Channel = await twilioClient.getChannelBySid(channelId);
    if (!channel) {
      throw new Error('channel not found:' + channelId);
    }
    await channel.advanceLastConsumedMessageIndex(index);
  }

  public async updateChannelAttributes(channelSid: string, attributes = {}): Promise<void> {
    const twilioClient: TwilioChat.Client = await this._twilioAgentChatClient;
    if (!twilioClient) {
      return;
    }

    const channel: Channel = await twilioClient.getChannelBySid(channelSid);

    if (!channel) {
      throw new Error('channel not found:' + channelSid);
    }

    await channel.updateAttributes({ ...channel.attributes, ...attributes }).catch(error => {
      console.error(JSON.stringify(error));
    });
  }
}
