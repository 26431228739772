import { get, isEqual } from 'lodash';
import moment from 'moment';
import { v4 } from 'uuid';

import { IChangeTracker } from '../contracts/tracking/change-tracker';
import { dateFormat, dateFormatWithoutTime } from '../date/format';
import { map } from '../mapper/mapper';
import { BaseViewModel } from '../viewmodel/base-view-model';
import { Change } from './change';
import { ChangeTrack } from './change-track';
import { ChangeTrackCollection } from './change-track-collection';
import { ChangeTrackContainer } from './change-track-container';
import { ChangeTracker } from './change-tracker';
import { Changes } from './changes';
import { createHandler } from './handlers';
import { proxyToRaw, rawToProxy } from './internals';
import { ignoreMetadataKey, removeTimeMetadataKey } from './tracking-keys';

const trackingMetadataKey = 'trackableProperty';
type PropertyFunction<T> = () => T;

export type MomentInput = string | Date | number;

export function trackInternal(
  target: any,
  changeTracker: IChangeTracker,
  path: string = '#',
  atRuntime?: boolean
): any {
  const trackedProps: string[] = [];

  if (!changeTracker.getTrackId(target)) {
    changeTracker.setTrackId(target, v4());
  }
  for (const prop in target) {
    if (canSkip(prop, target, changeTracker)) {
      continue;
    }

    buildTrack(prop);
  }

  if (atRuntime) {
    return;
  }
  const observable = new Proxy(target, createHandler(changeTracker, trackedProps, trackInternal));

  rawToProxy.set(target, observable);
  proxyToRaw.set(observable, target);

  return observable;

  function buildTrack(prop: string) {
    const metadata = Reflect.getMetadata(trackingMetadataKey, target, prop);
    if (Array.isArray(target[prop])) {
      target[prop] = target[prop].map((element, index) => {
        if (typeof element !== 'object' || element == null) {
          return element;
        }
        const element1 = metadata ? map(element, metadata.factory()) : element;
        return trackInternal(element1, changeTracker, `${path}.${prop}[${index}]`, atRuntime);
      });
      trackedProps.push(prop);
      const changeTrackCollection = new ChangeTrackCollection(target[prop]);
      changeTracker.set(
        `${changeTracker.getTrackId(target)}.${prop}`,
        new ChangeTrackContainer(`${path}.${prop}`, changeTrackCollection)
      );
      target[prop] = changeTrackCollection.proxy;
      changeTracker.setHierarchy(target, target[prop]);
      for (const item of target[prop]) {
        changeTracker.setHierarchy(target[prop], proxyToRaw.get(item));
      }
    } else if (
      !moment(target[prop], dateFormat, true).isValid() &&
      !moment(target[prop], dateFormatWithoutTime, true).isValid() &&
      typeof target[prop] === 'object'
    ) {
      const element = metadata ? map(target[prop], metadata.factory()) : target[prop];
      changeTracker.setHierarchy(target, element);
      target[prop] = trackInternal(element, changeTracker, atRuntime ? path : `${path}.${prop}`, false);
      changeTracker.set(
        `${changeTracker.getTrackId(target)}.${prop}`,
        new ChangeTrackContainer(
          atRuntime ? path : `${path}.${prop}`,
          new ChangeTrack(moment(target[prop], dateFormat, true).isValid() ? target[prop].toISOString() : target[prop])
        )
      );
      trackedProps.push(prop);
    } else {
      if (
        !(target[prop] instanceof Date) &&
        (moment(target[prop], dateFormat, true).isValid() ||
          moment(target[prop], dateFormatWithoutTime, true).isValid())
      ) {
        target[prop] = new Date(target[prop]);
      }
      trackedProps.push(prop);
      changeTracker.set(
        `${changeTracker.getTrackId(target)}.${prop}`,
        new ChangeTrackContainer(
          atRuntime ? path : `${path}.${prop}`,
          new ChangeTrack(moment(target[prop], dateFormat, true).isValid() ? target[prop].toISOString() : target[prop])
        )
      );
    }
  }
}

function canSkip(prop: string, target, changeTracker) {
  return (
    prop === 'changeTracker' ||
    prop === 'changes' ||
    Reflect.getMetadata(ignoreMetadataKey, target, prop) != null ||
    target[prop] == null ||
    changeTracker.get(`${changeTracker.getTrackId(target)}.${prop}`)
  );
}

export function tracked(metadata: { factory: PropertyFunction<any> } = { factory: () => {} }): any {
  return Reflect.metadata(trackingMetadataKey, { ...metadata });
}

export function ignore(save: boolean = false): any {
  return Reflect.metadata(ignoreMetadataKey, { save });
}

export function removeTime(): any {
  return Reflect.metadata(removeTimeMetadataKey, {});
}

export function observe(target: BaseViewModel): any {
  const changeTracker = new ChangeTracker();
  delete target.changeTracker;

  const proxy = trackInternal(target, changeTracker);

  target.changeTracker = changeTracker;

  return proxy;
}

export function getChanges(target: BaseViewModel, dto: any): Changes {
  if (!target.changeTracker) {
    return new Changes('#', []);
  }

  const changeValues: Change[] = [];
  for (const [key, entry] of target.changeTracker) {
    if (!entry.changeTrack.isDirty) {
      continue;
    }

    if (entry.changeTrack instanceof ChangeTrackCollection) {
      changeValues.push(new Change(entry.path, entry.changeTrack.originTarget, entry.changeTrack.oldValue));
    } else {
      changeValues.push(new Change(entry.path, entry.changeTrack.newValue, entry.changeTrack.oldValue));
    }
  }

  target.changeTracker.listeners.forEach(listener => listener.unsubscribe());
  delete target.changeTracker;

  const changes = new Changes((target as any)._id, changeValues);
  replaceProxies(changes, dto);
  cleanupEqualChanges(changes);

  return changes;
}

function replaceProxies(changes: Changes, item: any) {
  for (const change of changes.changes) {
    if (
      typeof change.newValue === 'object' ||
      moment((change.newValue as any) as MomentInput, dateFormat, true).isValid()
    ) {
      change.newValue = get(item, change.path.replace('#.', ''));
    }
    if (change.oldValue === undefined) {
      change.oldValue = null;
    }
    if (change.newValue === undefined) {
      change.newValue = null;
    }
  }
}

function cleanupEqualChanges(changes: Changes) {
  for (let index = changes.changes.length - 1; index >= 0; index--) {
    if (isEqual(changes.changes[index].oldValue, changes.changes[index].newValue)) {
      changes.changes.splice(index, 1);
    }
  }
}
