import { InjectionToken } from '@angular/core';
import {
  TemplateTagItem,
  // tslint:disable-next-line:max-line-length
} from 'src/app/insurance-contract/components/insurance-contract-edit/sections/required-templates/insurance-contract-required-template-item/template-tag-item';

import {
  ArticleDB,
  AttributeTemplateDB,
  DeviceDB,
  DoctorDB,
  ErpOrderDB,
  GroupDB,
  HospitalDB,
  InsuranceContractDB,
  NursingHomeDB,
  NursingServiceDB,
  PatientDB,
  PayerDB,
  PharmacyDB,
  PostalCodeDB,
  ProductGroupDB,
  RegionDB,
  StandardCareProposalDB,
} from '../../common/repository/databases';
import {
  AttributeTemplateItem,
} from '../components/inputs/autocomplete/list-items/attribute-template-list-item/attribute-template-list-item';
import { DoctorListItem } from '../components/inputs/autocomplete/list-items/doctor-list-item/doctor-list-item';
import {
  FieldNurseListItem,
} from '../components/inputs/autocomplete/list-items/field-nurse-list-item/field-nurse-list-item';
import { GroupListItem } from '../components/inputs/autocomplete/list-items/group-list-item/group-list-item';
import { HospitalListItem } from '../components/inputs/autocomplete/list-items/hospital-list-item/hospital-list-item';
import {
  InstitutionContactFunctionListItem,
  // tslint:disable-next-line:max-line-length
} from '../components/inputs/autocomplete/list-items/institution-contact-function-list-item/institution-contact-function-list-item';
import {
  InsuranceContractListItem,
} from '../components/inputs/autocomplete/list-items/insurance-contract-list-item/insurance-contract-list-item';
import {
  NursingHomeListItem,
} from '../components/inputs/autocomplete/list-items/nursing-home-list-item/nursing-home-list-item';
import {
  NursingServiceListItem,
} from '../components/inputs/autocomplete/list-items/nursing-service-list-item/nursing-service-list-item';
import {
  PatientTemplateItem,
} from '../components/inputs/autocomplete/list-items/patient-template-list-item/patient-template-list-item';
import { PayerListItem } from '../components/inputs/autocomplete/list-items/payer-list-item/payer-list-item';
import { PharmacyListItem } from '../components/inputs/autocomplete/list-items/pharmacy-list-item/pharmacy-list-item';
import {
  PostalCodeListItem,
} from '../components/inputs/autocomplete/list-items/postal-code-list-item/postal-code-list-item';
import {
  ProductGroupListItem,
} from '../components/inputs/autocomplete/list-items/product-group-list-item/product-group-list-item';
import { RegionListItem } from '../components/inputs/autocomplete/list-items/region-list-item/region-list-item';
import {
  StandardCareProposalListItem,
} from '../components/inputs/autocomplete/list-items/standard-care-proposal/standard-care-proposal-list-item';
import {
  TemplateTagTemplateItem,
} from '../components/inputs/autocomplete/list-items/templatetag-template-list-item/templatetag-template-list-item';
import {
  MulitAutocompleteAttributeTemplateListItem,
} from '../components/inputs/multi-autocomplete/list-items/multi-autocomplete-attribute-template-item';
import {
  MulitAutocompleteGroupListItem,
} from '../components/inputs/multi-autocomplete/list-items/multi-autocomplete-group-list-item';
import {
  MultiAutocompleteInsuranceContractListItem,
} from '../components/inputs/multi-autocomplete/list-items/multi-autocomplete-insurance-contract-list-item';
import {
  MultiAutocompletePayerListItem,
} from '../components/inputs/multi-autocomplete/list-items/multi-autocomplete-payer-list-item';
import {
  MultiAutocompleteProductGroupListItem,
} from '../components/inputs/multi-autocomplete/list-items/multi-autocomplete-product-group-list-item';
import {
  MulitAutocompleteStandardCareProposalpListItem,
} from '../components/inputs/multi-autocomplete/list-items/multi-autocomplete-std-care-proposal-list-item';
import {
  MulitAutocompleteTemplateTagListItem,
} from '../components/inputs/multi-autocomplete/list-items/multi-autocomplete-template-tag-list-item';
import { ProductGroupDto } from '../models/article/product-group-dto.model';
import { ConfigItem } from '../models/config-item';
import { DeviceDto } from '../models/device/device-dto.model';
import { FieldNurse } from '../models/field-nurse';
import { AttributeTemplateDto } from '../models/general/attribute-template-dto.model';
import { DoctorDto } from '../models/institution/doctor-dto.model';
import { HospitalDto } from '../models/institution/hospital-dto.model';
import { InstitutionContactFunctionModel } from '../models/institution/institution-contact-function.model';
import { NursingHomeDto } from '../models/institution/nursing-home-dto.model';
import { NursingServiceDto } from '../models/institution/nursing-service-dto.model';
import { PayerDto } from '../models/institution/payer-dto.model';
import { PharmacyDto } from '../models/institution/pharmacy-dto.model';
import { PostalCodeDto } from '../models/institution/postal-code-dto.model';
import { InsuranceContractDto } from '../models/insurance-contract/insurance-contract-dto.model';
import {
  AttributeTemplateModelName,
  DeviceModelName,
  DoctorModelName,
  ErpOrderModelName,
  GroupModelName,
  HospitalModelName,
  InsuranceContractModelName,
  NursingHomeModelName,
  NursingServiceModelName,
  PatientModelName,
  PayerModelName,
  PharmacyModelName,
  PostalCodeModelName,
  ProductGroupModelName,
  RegionModelName,
  StandardCareProposalModelName,
} from '../models/model-names';
import { ErpOrderDto } from '../models/order/erp-order-dto.model';
import { PatientDto } from '../models/patient/patient-dto.model';
import { StandardCareProposalDto } from '../models/standard-careproposal/standard-careproposal-dto.model';
import { GroupDto } from '../models/user-management/group-dto.model';
import { RegionDto } from '../models/user-management/region-dto.model';
import { ArticleListItem } from './../components/inputs/autocomplete/list-items/article-list-item/article-list-item';
import { ArticleDto } from './../models/article/article-dto.model';
import { ArticleModelName } from './../models/model-names';

export const ProviderConfigToken = new InjectionToken('ProviderConfigToken');

export interface IProviderConfig {
  [Key: string]: ConfigItem;
}

const PROVIDER_CONFIG: IProviderConfig = {};

PROVIDER_CONFIG[HospitalModelName] = new ConfigItem(HospitalDB, () => new HospitalDto(), HospitalListItem);
PROVIDER_CONFIG[PostalCodeModelName] = new ConfigItem(PostalCodeDB, () => new PostalCodeDto(), PostalCodeListItem);
PROVIDER_CONFIG[NursingServiceModelName] = new ConfigItem(
  NursingServiceDB,
  () => new NursingServiceDto(),
  NursingServiceListItem
);
PROVIDER_CONFIG[DoctorModelName] = new ConfigItem(DoctorDB, () => new DoctorDto(), DoctorListItem);
PROVIDER_CONFIG[NursingHomeModelName] = new ConfigItem(NursingHomeDB, () => new NursingHomeDto(), NursingHomeListItem);
PROVIDER_CONFIG[PayerModelName] = new ConfigItem(
  PayerDB,
  () => new PayerDto(),
  PayerListItem,
  MultiAutocompletePayerListItem
);
// TODO fieldNurse
PROVIDER_CONFIG['fieldNurse'] = new ConfigItem(
  null,
  () => new FieldNurse(null, null, null, null, null),
  FieldNurseListItem
);
PROVIDER_CONFIG[GroupModelName] = new ConfigItem(
  GroupDB,
  () => new GroupDto(),
  GroupListItem,
  MulitAutocompleteGroupListItem
);
PROVIDER_CONFIG[RegionModelName] = new ConfigItem(RegionDB, () => new RegionDto(), RegionListItem);
PROVIDER_CONFIG[ProductGroupModelName] = new ConfigItem(
  ProductGroupDB,
  () => new ProductGroupDto(),
  ProductGroupListItem,
  MultiAutocompleteProductGroupListItem
);
PROVIDER_CONFIG[PharmacyModelName] = new ConfigItem(PharmacyDB, () => new PharmacyDto(), PharmacyListItem);
PROVIDER_CONFIG[InsuranceContractModelName] = new ConfigItem(
  InsuranceContractDB,
  () => new InsuranceContractDto(),
  InsuranceContractListItem,
  MultiAutocompleteInsuranceContractListItem
);
PROVIDER_CONFIG[ErpOrderModelName] = new ConfigItem(ErpOrderDB, () => new ErpOrderDto(), null);
PROVIDER_CONFIG[DeviceModelName] = new ConfigItem(DeviceDB, () => new DeviceDto(), null);
PROVIDER_CONFIG[StandardCareProposalModelName] = new ConfigItem(
  StandardCareProposalDB,
  () => new StandardCareProposalDto(),
  StandardCareProposalListItem,
  MulitAutocompleteStandardCareProposalpListItem
);
PROVIDER_CONFIG[AttributeTemplateModelName] = new ConfigItem(
  AttributeTemplateDB,
  () => new AttributeTemplateDto(),
  AttributeTemplateItem,
  MulitAutocompleteAttributeTemplateListItem
);
PROVIDER_CONFIG[PatientModelName] = new ConfigItem(PatientDB, () => new PatientDto(), PatientTemplateItem, null);
PROVIDER_CONFIG['templateTag'] = new ConfigItem(
  null,
  () => new TemplateTagItem(),
  TemplateTagTemplateItem,
  MulitAutocompleteTemplateTagListItem
);
PROVIDER_CONFIG['institutionContactFunction'] = new ConfigItem(
  null,
  () => new InstitutionContactFunctionModel(),
  InstitutionContactFunctionListItem
);
PROVIDER_CONFIG[ArticleModelName] = new ConfigItem(ArticleDB, () => new ArticleDto(), ArticleListItem);

export default PROVIDER_CONFIG;
