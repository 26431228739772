import { Inject, Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { take, takeWhile } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConnectionStateService } from 'src/app/shared/services/connection-state.service';
import { IConnectionStateService } from 'src/app/shared/services/contracts/sync/connection-state-service';
import { Branch, EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { LogLevel } from '../contracts/logging/log-level';
import { ILogger } from '../contracts/logging/logger';
import { IStateRegistry } from '../contracts/state/state-registry';
import { StateRegistry } from '../state/state-registry';

@Injectable({ providedIn: 'root' })
export class Logger implements ILogger {
  private static instance: ILogger;
  private _logLevel: LogLevel;

  get logLevel(): LogLevel {
    return this._logLevel;
  }

  set logLevel(logLevel: LogLevel) {
    this._logLevel = logLevel;
  }

  constructor(
    private _authService: AuthService,
    @Inject(StateRegistry) private _stateRegistry: IStateRegistry,
    private _device: Device,
    private _platform: Platform,
    private _environmentService: EnvironmentService,
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService
  ) {
    this._stateRegistry.update('sentry', 'context', {});
    this.setScope = this.setScope.bind(this);

    this._authService.authenticatedEventPublisher
      .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
      .subscribe({
        complete: this.setScope,
      });
    this._logLevel = LogLevel.verbose;

    Logger.setInstance(this);
  }

  private static setInstance(logger: ILogger) {
    Logger.instance = logger;
  }

  static log(message: string, level: LogLevel = LogLevel.verbose) {
    return Logger.instance.log(message, level);
  }

  static error(message: string, error: Error) {
    return Logger.instance.error(message, error);
  }

  static info(message: string, level: LogLevel = LogLevel.info) {
    return Logger.instance.info(message, level);
  }

  log(message: string, level: LogLevel = LogLevel.verbose) {
    console.log(message);
  }

  error(message: string, error: Error, level: LogLevel = LogLevel.fatal) {
    console.error(`Error occured / message: ${message} - error: ${error.message}`);
    if (this._environmentService.branch === Branch.dev || level === LogLevel.silent) {
      return;
    }

    this._stateRegistry
      .select('sentry', 'context')
      .pipe(take(1))
      .subscribe((context: any) => {
        if (context) {
          Sentry.withScope(scope => {
            scope.addBreadcrumb({ data: context, level: Sentry.Severity.Info });
            if (error) {
              Sentry.captureException(error);
            } else {
              Sentry.captureMessage(message);
            }
          });
          return;
        }
        if (error) {
          Sentry.captureException(error);
        } else {
          Sentry.captureMessage(message);
        }
      });
  }

  /**
   * Capture error messages in specific parts of the app.
   * Use tags and level to help classifing errors.
   * You can pass message or error
   * @param message The message to capture
   * @param error The error to captupre
   * @param tags Map with key value tags
   * @param level The logging level (see sentry levels)
   */
  public captureErrorWithExtras(
    message: string | null,
    error: Error | null,
    tags: Map<string, string> = new Map(),
    level: string = 'info',
    extras: Record<string, unknown> = {}
  ): void {
    console.error(message, error, tags, level, extras);
    Sentry.withScope(scope => {
      scope.setExtras(extras);
      tags.forEach((key, value) => scope.setTag(key, value));
      scope.setLevel(Sentry.Severity.fromString(level) || Sentry.Severity.Info);
      if (error) {
        Sentry.captureException(error);
      } else {
        Sentry.captureMessage(message);
      }
    });
  }

  public addBreadcrumb(breadcrumb: Sentry.Breadcrumb): void {
    Sentry.addBreadcrumb(breadcrumb);
  }

  info(message: string, level: LogLevel = LogLevel.info) {
    console.log(message);
  }

  private setScope() {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: this._authService.authentication.account._id,
        email: this._authService.authentication.account.email,
        // tslint:disable-next-line: max-line-length
        username: `${this._authService.authentication.account.lastName} ${this._authService.authentication.account.firstName}`,
      });
      scope.setExtra('isConnected', this._connectionStateService.isConnected);
      if (!this._platform.is('hybrid')) {
        return;
      }
      scope.setExtras({
        manufacturer: this._device.manufacturer,
        model: this._device.model,
        os: this._device.platform,
        os_version: this._device.version,
        build_number: this._environmentService.buildNumber,
      });
    });
  }
}
