import { Subject } from 'rxjs';

import { IChangeTrack } from '../contracts/tracking/change-track';
import { IChangeTrackContainer } from '../contracts/tracking/change-track-container';
import { IChangeTracker } from '../contracts/tracking/change-tracker';
import { INotifyInfo } from './notify-info';

export class ChangeTracker implements IChangeTracker {
  private _bags = new Map<string, IChangeTrackContainer>();
  private _trackIds = new Map<{}, string>();
  private _suspend: boolean;
  private _modifyListeners: Subject<INotifyInfo>[] = [];

  // tslint:disable-next-line:prefer-array-literal
  private _hierarchy = new Map<{}, Array<{}>>();

  get listeners(): Subject<INotifyInfo>[] {
    return this._modifyListeners;
  }

  get isSuspended(): boolean {
    return this._suspend;
  }

  // tslint:disable-next-line:function-name
  [Symbol.iterator]() {
    return this._bags.entries();
  }

  public setTrackId(target: any, id: string): void {
    if (this._trackIds.has(target)) {
      return;
    }

    this._trackIds.set(target, id);
  }

  public suspend() {
    this._suspend = true;
  }

  public resume() {
    this._suspend = false;
  }

  public getTrackId(target: any): string {
    return this._trackIds.has(target) ? this._trackIds.get(target) : undefined;
  }

  public setHierarchy(parent: any, child: any): void {
    if (this._hierarchy.has(parent)) {
      const children = this._hierarchy.get(parent);
      if (children.some(child1 => child1 === child)) {
        return;
      }
      children.push(child);
    } else {
      this._hierarchy.set(parent, [child]);
    }
  }
  //tslint:disable
  /**
   * @example
   * Entspricht folgenden Hierarchien
   *
   * Fall 1 (Kind-Element):
   * class ParentViewModel {
   *  child: ChildViewModel;
   * }
   * class ChildViewModel {
   * value: string;
   * }
   *
   * Fall 2 (Array Kind-Element):
   * class ParentViewModel {
   *  child: ChildViewModel[];
   * }
   * class ChildViewModel {
   * value: string;
   * }
   *
   * Fall 3 (Array Kind-Element mit verschachtelten Array-Kind-Elementen):
   * class ParentViewModel {
   *  child: ChildViewModel[];
   * }
   * class ChildViewModel {
   *  value: string;
   *  subChildren: SubChildViewModel[];
   * }
   * class SubChildViewModel {
   *  name: string;
   * }
   *
   * Fall 4 (Array Kind-Element mit verschachtelten Array-Kind-Elementen und/oder mit weiterer 'einfacher' Abhängigkeit):
   * class ParentViewModel {
   *  child: ChildViewModel[];
   * }
   * class ChildViewModel {
   *  value: string;
   *  subChildren: SubChildViewModel[];
   *  dependency: DependencyViewModel;
   * }
   * class SubChildViewModel {
   *  name: string;
   * }
   * class DependencyViewModel {
   *  value: number;
   * }
   */
  // tslint:enable
  public getPathByHierarchy(target: any, key: string, proxyToRaw: WeakMap<object, any>): string {
    for (const [parent, children] of this._hierarchy) {
      if (children.some(child => child === target)) {
        const keys = Object.keys(parent).filter(k => {
          const value = proxyToRaw.get(parent[k]) || parent[k];
          return value === target;
        });
        if (keys.length) {
          // tslint:disable-next-line:no-parameter-reassignment
          key = `${keys[0]}.${key}`;
        }
        return this.getPathByHierarchy(parent, key, proxyToRaw);
      }
      // tslint:disable-next-line:prefer-array-literal
      let childArray: Array<{}>;
      if (
        children.some(child => {
          if (Array.isArray(child) && child.indexOf(target) >= 0) {
            childArray = child;
            return true;
          }
        })
      ) {
        const keys = Object.keys(parent).filter(k => parent[k] === childArray);
        const indexOfObj = childArray.indexOf(target);
        if (keys.length) {
          // tslint:disable-next-line:no-parameter-reassignment
          key = `${keys}[${indexOfObj.toString()}].${key}`;
        }
        return this.getPathByHierarchy(parent, key, proxyToRaw);
        // tslint:disable-next-line:no-else-after-return
      }
      continue;
    }
    return `#.${key}`;
  }

  public set(key: string, changeTrackContainer: IChangeTrackContainer): void {
    if (this._bags.has(key)) {
      return;
    }

    this._bags.set(key, changeTrackContainer);
  }

  public get(key: string): IChangeTrack {
    if (this._bags.has(key)) {
      return this._bags.get(key).changeTrack;
    }
    return undefined;
  }
}
