export const UPDATES = [
  {
    version: '2.13.0',
    text: 'Alberta Update - 21.12.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-21-12-2021',
  },
  {
    version: '2.12.5',
    text: 'Alberta Update - 22.10.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-22-10-2021',
  },
  {
    version: '2.12.4',
    text: 'Alberta Update - 23.09.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-23-09-2021',
  },
  {
    version: '2.12.3',
    text: 'Alberta Update - 02.09.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-02-09-2021',
  },
  {
    version: '2.12.2',
    text: 'Alberta Update - 22.07.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-22-07-2021',
  },
  {
    version: '2.12.0',
    text: 'Alberta Update - 20.06.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-20-06-2021',
  },
  {
    version: '2.11.4',
    text: 'Alberta Update - 20.05.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-20-05-2021',
  },
  {
    version: '2.11.1',
    text: 'Alberta Update - 22.04.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-22-04-2021',
  },
  {
    version: '2.11.0',
    text: 'Alberta Update - 25.03.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-25-03-2021',
  },
  {
    version: '2.10.3',
    text: 'Alberta Update - 25.02.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-25-02-2021',
  },
  {
    version: '2.10.1',
    text: 'Alberta Update - 21.01.2021',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-18-01-2021',
  },
  {
    version: '2.10.0',
    text: 'Alberta Update - 16.12.2020',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-16-12-2020',
  },
  {
    version: '2.9.1',
    text: 'Alberta Update - 26.11.2020',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-26-11-2020-26-11-2020-1',
  },
  {
    version: '2.8.0',
    text: 'Alberta Update - 26.10.2020',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-26-10-2020',
  },
  {
    version: '2.7.0',
    text: 'Alberta Update - 02.10.2020',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-02-10-2020',
  },
  {
    version: '2.6.0',
    text: 'Alberta Update - 24.09.2020',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-24-09-2020',
  },
  {
    version: '2.5.3',
    text: 'Alberta Update - 31.08.2020',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-31-08-2020',
  },
  {
    version: '2.5.2',
    text: 'Alberta Update - 20.08.2020',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-20-08-2020',
  },
  {
    version: '2.5.1',
    text: 'Update Alberta - 23.07.2020',
    link: 'https://hilfe.halloalberta.de/portal/de/kb/articles/alberta-update-23-07-2020',
  },
  {
    version: '2.5.0',
    text: 'Update Alberta - 03.07.2020',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-03-07-2020',
  },
  {
    version: '2.4.2',
    text: 'Update Alberta - 25.06.2020',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-25-06-2020',
  },
  {
    version: '2.4.1',
    text: 'Update Alberta - 28.05.2020',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-28-05-2020',
  },
  {
    version: '2.4.0',
    text: 'Update Alberta - 07.05.2020',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-07-05-2020',
  },
  {
    version: '2.3.0',
    text: 'Update Alberta - 17.04.2020',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-17-04-2020',
  },
  {
    version: '2.2.1',
    text: 'Update Alberta - 16.04.2020',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-14-04-2020',
  },
  {
    version: '2.2.0',
    text: 'Update Alberta - 20.03.2020',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-20-03-2020',
  },
  {
    version: '2.1.15',
    text: 'Update Alberta - 20.02.2020',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-20-02-2020',
  },
  {
    version: '2.1.14',
    text: 'Update Alberta - 29.01.2020',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-28-01-2020',
  },
  {
    version: '2.1.10',
    text: 'Update Alberta - 17.12.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-17-12-2019',
  },
  {
    version: '2.1.9',
    text: 'Update Alberta - 02.12.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-02-12-2019',
  },
  {
    version: '2.1.8',
    text: 'Update Alberta - 29.11.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-29-11-2019',
  },
  {
    version: '2.1.5',
    text: 'Update Alberta - 25.11.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-25-11-2019',
  },
  {
    version: '2.1.1',
    text: 'Update Alberta - 27.09.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-27-09-2019',
  },
  {
    version: '2.0.0',
    text: 'Update Alberta - 25.07.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-25-07-2019',
  },
  {
    version: '1.3.8',
    text: 'Update Alberta - 02.05.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-02-05-2019',
  },
  {
    version: '1.3.7',
    text: 'Update Alberta - 12.04.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-12-04-2019',
  },
  {
    version: '1.3.6',
    text: 'Update Alberta - 02.04.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-02-04-2019',
  },
  {
    version: '1.3.5',
    text: 'Update Alberta - 15.03.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-15-03-2019',
  },
  {
    version: '1.3.3',
    text: 'Update Alberta - 14.03.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-14-03-2019',
  },
  {
    version: '1.3.2',
    text: 'Alberta Update - 27.02.2019',
    link: 'https://hilfe.halloalberta.de/portal/kb/articles/alberta-update-27-02-2019',
  },
];
