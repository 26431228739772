import {
  ICareProposal,
  IDoctor,
  IHospital,
  IInstitutionContact,
  INursingHome,
  INursingService,
  IPatient,
  IPostalCode,
  ISubunit,
} from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { isEqual, uniqWith } from 'lodash';
import {
  CareProposalDB,
  DoctorDB,
  HospitalDB,
  InstitutionContactDB,
  NursingHomeDB,
  NursingServiceDB,
  PatientDB,
  PostalCodeDB,
  SubunitDB,
} from 'src/app/common/repository/databases';

import { PatientContact } from '../models/patient-contact.model';
import { PatientDto } from '../models/patient/patient-dto.model';
import { QueryService } from './query/query.service';

export enum PatientContactServiceTypes {
  patient,
  caregiver,
  doctor,
  nursingHome,
  nursingService,
  hospital,
}

@Injectable({
  providedIn: 'root',
})
export class PatientContactService {
  constructor(private _queryService: QueryService) {}

  public async getPatientContacts(id: string, types?: PatientContactServiceTypes[]): Promise<PatientContact[]> {
    const patient = await this._queryService.get<PatientDto>(id, PatientDB);

    return patient == null
      ? []
      : uniqWith(
          [
            ...(await this.getPatientPatientContact(patient, types)),
            ...(await this.getPatientCareGiverPatientContacts(patient, types)),
            ...(await this.getPatientDoctorPatientContacts(patient, types)),
            ...(await this.getPatientNursingHomePatientContacts(patient, types)),
            ...(await this.getPatientNursingServicePatientContacts(patient, types)),
            ...(await this.getPatientHospitalPatientContacts(patient, types)),
          ],
          isEqual
        );
  }

  private async getPatientPatientContact(
    patient: IPatient,
    types?: PatientContactServiceTypes[]
  ): Promise<PatientContact[]> {
    if (types && !types.includes(PatientContactServiceTypes.patient)) {
      return [];
    }
    if (!(patient.fax || patient.email)) {
      return [];
    }

    const combinedName = this.sanitizeContactName(patient.firstName, patient.lastName);
    return [
      new PatientContact(combinedName, patient.email, patient.fax, await this.getPostalAddress(patient), 'Patient'),
    ];
  }

  private async getPatientCareGiverPatientContacts(
    patient: IPatient,
    types?: PatientContactServiceTypes[]
  ): Promise<PatientContact[]> {
    if (types && !types.includes(PatientContactServiceTypes.caregiver)) {
      return [];
    }
    if (!patient.careGivers || !patient.careGivers.length) {
      return [];
    }

    const PatientContacts = [];
    for (const careGiver of patient.careGivers.filter(caregiver => !!caregiver.fax || !!caregiver.email)) {
      PatientContacts.push(
        new PatientContact(
          this.sanitizeContactName(careGiver.firstName, careGiver.lastName),
          careGiver.email,
          careGiver.fax,
          await this.getPostalAddress(careGiver),
          'Kontakt'
        )
      );
    }
    return PatientContacts;
  }

  private async getPatientDoctorPatientContacts(
    patient: IPatient,
    types?: PatientContactServiceTypes[]
  ): Promise<PatientContact[]> {
    if (types && !types.includes(PatientContactServiceTypes.doctor)) {
      return [];
    }
    let doctorIds = [];
    const patientContacts = [];
    // doctor from pateint
    if (patient.primaryDoctorId) {
      const contacts = await this.transformDoctorIdToPatientContact(patient.primaryDoctorId);
      patientContacts.push(...contacts);
    }
    // load from patient doctor subunit
    if (patient.primaryDoctor && patient.primaryDoctor.contactId) {
      const contacts = await this.getInsitutionContactPatientContact(patient.primaryDoctor.contactId, 'Arzt');
      patientContacts.push(...contacts);
    }
    // get doctors from careproposal
    const careproposals = await this._queryService.search<ICareProposal>(
      { query: `patientId:${patient._id}` },
      CareProposalDB
    );
    if (!careproposals || !careproposals.length) {
      return patientContacts;
    }

    doctorIds = [
      ...new Set([
        ...doctorIds,
        ...careproposals.filter(careProposal => careProposal.doctorId).map(element => element.doctorId),
      ]),
    ];

    for (const doctorId of doctorIds) {
      const contacts = await this.transformDoctorIdToPatientContact(doctorId);
      patientContacts.push(...contacts);
    }

    return patientContacts;
  }

  private async getPatientNursingHomePatientContacts(
    patient: IPatient,
    types?: PatientContactServiceTypes[]
  ): Promise<PatientContact[]> {
    if (types && !types.includes(PatientContactServiceTypes.nursingHome)) {
      return [];
    }
    const patientContacts = [];
    // load from patient - subunit and contact
    patientContacts.push(...(await this.getPatientNursingHomeCrmPatientContacts(patient)));
    // load from patient
    if (!patient.nursingHomeId) {
      return patientContacts;
    }

    const nursingHome = await this._queryService.get<INursingHome>(patient.nursingHomeId, NursingHomeDB);
    if (nursingHome && (nursingHome.fax || nursingHome.email)) {
      patientContacts.push(
        new PatientContact(
          nursingHome.name,
          nursingHome.email,
          nursingHome.fax,
          await this.getPostalAddress(nursingHome),
          'Pflegeheim'
        )
      );
    }

    return patientContacts;
  }

  private async getPatientNursingHomeCrmPatientContacts(patient: IPatient): Promise<PatientContact[]> {
    if (!patient.nursingHome) {
      return [];
    }

    const patientContacts = [];
    if (patient.nursingHome.contactId) {
      const contacts = await this.getInsitutionContactPatientContact(patient.nursingHome.contactId, 'Pflegeheim');
      patientContacts.push(...contacts);
    }
    if (patient.nursingHome.subunitId) {
      const contacts = await this.getInsitutionSubUnitPatientContact(patient.nursingHome.subunitId, 'Pflegeheim');
      patientContacts.push(...contacts);
    }

    return patientContacts;
  }

  private async getPatientNursingServicePatientContacts(
    patient: IPatient,
    types?: PatientContactServiceTypes[]
  ): Promise<PatientContact[]> {
    if (types && !types.includes(PatientContactServiceTypes.nursingService)) {
      return [];
    }
    const patientContacts = [];
    // load from patient - subunit and contact
    patientContacts.push(...(await this.getPatientNursingServiceCrmPatientContacts(patient)));
    // load form patient
    if (!patient.nursingServiceId) {
      return patientContacts;
    }

    const nursingService = await this._queryService.get<INursingService>(patient.nursingServiceId, NursingServiceDB);
    if (nursingService?.fax || nursingService?.email) {
      patientContacts.push(
        new PatientContact(
          nursingService.name,
          nursingService.email,
          nursingService.fax,
          await this.getPostalAddress(nursingService),
          'Pflegedienst'
        )
      );
    }

    return patientContacts;
  }

  private async getPatientNursingServiceCrmPatientContacts(patient: IPatient): Promise<PatientContact[]> {
    if (!patient.nursingService) {
      return [];
    }

    const patientContacts = [];

    if (patient.nursingService.contactId) {
      const contacts = await this.getInsitutionContactPatientContact(patient.nursingService.contactId, 'Pflegedienst');
      patientContacts.push(...contacts);
    }
    if (patient.nursingService.subunitId) {
      const contacts = await this.getInsitutionSubUnitPatientContact(patient.nursingService.subunitId, 'Pflegedienst');
      patientContacts.push(...contacts);
    }

    return patientContacts;
  }

  private async getPatientHospitalPatientContacts(
    patient: IPatient,
    types?: PatientContactServiceTypes[]
  ): Promise<PatientContact[]> {
    if (types && !types.includes(PatientContactServiceTypes.hospital)) {
      return [];
    }
    const PatientContacts = [];
    // load from patient - subunit and contact
    PatientContacts.push(...(await this.getPatientHospitalCrmPatientContacts(patient)));
    // load form patient
    if (!patient.hospital || !patient.hospital.hospitalId) {
      return PatientContacts;
    }

    const hospital = await this._queryService.get<IHospital>(patient.hospital.hospitalId, HospitalDB);
    if (hospital?.fax || hospital?.email) {
      PatientContacts.push(
        new PatientContact(
          hospital.name,
          hospital.email,
          hospital.fax,
          await this.getPostalAddress(hospital),
          'Krankenhaus'
        )
      );
    }
    return PatientContacts;
  }

  private async getPatientHospitalCrmPatientContacts(patient: IPatient): Promise<PatientContact[]> {
    if (!patient.hospital) {
      return [];
    }

    const patientContacts = [];
    if (patient.hospital.contactId) {
      const contacts = await this.getInsitutionContactPatientContact(patient.hospital.contactId, 'Krankenhaus');
      patientContacts.push(...contacts);
    }
    if (patient.hospital.subunitId) {
      const contacts = await this.getInsitutionSubUnitPatientContact(patient.hospital.subunitId, 'Krankenhaus');
      patientContacts.push(...contacts);
    }

    return patientContacts;
  }

  private async getPostalAddress(
    { lastName, firstName, address, postalCodeId, name, city, postalCode }: any,
    title?: string
  ) {
    const combinedName = this.sanitizeContactName(firstName, lastName);
    const nameOfAddressObject = name ? name : `${title ? title : ''} ${combinedName}`.trim();
    let postalCodeCity = '';
    if (postalCodeId) {
      const postalCodeEntity = await this.postCodeFromId(postalCodeId);
      postalCodeCity = postalCodeEntity && `${postalCodeEntity.postalCode} ${postalCodeEntity.city}`;
    }
    return `${nameOfAddressObject}\n${address}\n${
      postalCodeCity !== '' ? postalCodeCity : postalCode && city ? `${postalCode} ${city}` : ''
    }`;
  }

  private async postCodeFromId(postalCodeId: string) {
    return this._queryService.get<IPostalCode>(postalCodeId, PostalCodeDB);
  }

  private async transformDoctorIdToPatientContact(id: string): Promise<PatientContact[]> {
    const doctor: IDoctor = await this._queryService.get<IDoctor>(id, DoctorDB);
    if (!doctor || !doctor.fax) {
      return [];
    }

    const title = `${doctor.title ? doctor.title : doctor.titleShort ? doctor.titleShort : ''}`;
    const combinedName = this.sanitizeContactName(doctor.firstName, doctor.lastName);

    const contracts: PatientContact[] = [];
    contracts.push(
      new PatientContact(
        `${title} ${combinedName}`.trim(),
        '',
        doctor.fax,
        await this.getPostalAddress(doctor, title),
        'Arzt'
      )
    );
    if (doctor.faxPrescriptionRequest) {
      contracts.push(
        new PatientContact(
          `${title} ${combinedName}`.trim(),
          '',
          doctor.faxPrescriptionRequest,
          await this.getPostalAddress(doctor, title),
          'Arzt - Rezeptanforderung'
        )
      );
    }
    return contracts;
  }

  private async getInsitutionContactPatientContact(id: string, type?: string): Promise<PatientContact[]> {
    const insitutionContact = await this._queryService.get<IInstitutionContact>(id, InstitutionContactDB);
    if (!insitutionContact?.fax && !insitutionContact?.email) {
      return [];
    }

    const combinedName = this.sanitizeContactName(insitutionContact.firstName, insitutionContact.lastName);
    return [
      new PatientContact(
        combinedName,
        insitutionContact.email,
        insitutionContact.fax,
        await this.getPostalAddress(insitutionContact),
        `Insitutionskontakt ${type ? type : ''}`
      ),
    ];
  }

  private async getInsitutionSubUnitPatientContact(id: string, type?: string): Promise<PatientContact[]> {
    const institutionSubUnit = await this._queryService.get<ISubunit>(id, SubunitDB);
    if (!institutionSubUnit.fax && !institutionSubUnit?.email) {
      return [];
    }

    return [
      new PatientContact(
        `${institutionSubUnit.name}`,
        institutionSubUnit.email,
        institutionSubUnit.fax,
        await this.getPostalAddress(institutionSubUnit),
        `Institutionsuntereinheit ${type ? type : ''}`
      ),
    ];
  }

  private sanitizeContactName(firstName: string, lastName: string): string {
    const names = [firstName, lastName].filter(name => !!name);
    return names.join(' ');
  }
}
