import { ISingleOrder } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from 'src/app/common/state/state-extension';
import { SingleOrderModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class SingleOrderStateExtension extends BaseStateExtension<ISingleOrder> {
  public get name(): string {
    return SingleOrderModelName;
  }

  async canBeStored(item: ISingleOrder): Promise<boolean> {
    return true;
  }

  protected afterCreateInternal(item: ISingleOrder): void {}

  protected async afterUpdateInternal(item: ISingleOrder): Promise<void> {
    await this.stateRegistry.update(SingleOrderModelName, 'selectedItem', item);
  }
  protected removeFromState(item: ISingleOrder): void {}
}
