const baseUrl = 'halloalberta.de';
export const environment = {
  production: true,
  disableDeploy: false,
  baseUrl,
  o365Scopes: ['user.read'],
  firebase: {
    apiKey: 'AIzaSyCFI8FjgCF9O8jQWmeNIadi-64M-nyCxu8',
    authDomain: 'alberta-e5259.firebaseapp.com',
    databaseURL: 'https://alberta-e5259.firebaseio.com',
    projectId: 'alberta-e5259',
    storageBucket: 'alberta-e5259.appspot.com',
    messagingSenderId: '32777537853',
    appId: '1:32777537853:web:f06357dc49bfc6d64f9d54',
  },
  amplitude: {
    apiKey: 'ab65433b479343895b82b89fdf5538ff',
  },
  sentry: {
    dsn: 'https://fec3ae89cf75472c97023f4609a0b6c1@sentry.io/1774312',
  },
  authCodeFlowConfigWeb: {
    issuer: `https://auth.${baseUrl}`,
    redirectUri: window.location.origin,
    clientId: '5e9e8812-b866-48d2-9b15-8a96fdb92473',
    responseType: 'code',
    scope: 'openid offline_access',
    clearHashAfterLogin: true,
    showDebugInformation: false,
  },
  authCodeFlowConfigApp: {
    client_id: '5e9e8812-b866-48d2-9b15-8a96fdb92473',
    server_host: `https://auth.${baseUrl}`,
    redirect_url: 'appauth://callback',
    end_session_redirect_url: 'appauth://endsession',
    scopes: 'openid offline_access',
    pkce: true,
  },
  codePushDeploymentKeys: [
    { deploymentKey: 'BrSgolTnL1XL_kH0qCXbeVuZ_gfHFKqWu2jui', platform: 'android', deployment: 'dev' },
    { deploymentKey: 'vSQ7WCiqJANrtezBF3akiPkOjLHUtwuXRxriu', platform: 'android', deployment: 'staging' },
    { deploymentKey: 'X_qhUznDXTBB0TXNn61eJgSGUK2xZH1lYGuXU', platform: 'android', deployment: 'production' },
    { deploymentKey: 'g5SL8aaCEgZGZfHDNC7UYcqiPALJ-9pex36S5', platform: 'ios', deployment: 'dev' },
    { deploymentKey: 'tSoS-gGre8t0uN8hHPSEL98c7zqxHZhXDhMrZ', platform: 'ios', deployment: 'staging' },
    { deploymentKey: 'ZBfMW9NSubm7WqvVnwfULZh0_0XAZorDMguyw', platform: 'ios', deployment: 'production' },
  ],
  stimulsoftLicense:
    '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkyf6GCtMIvMrFSuO65hDII/QxiVSjc6dLKFeN+mcA+0g9Y8N' +
    'YAkJWK1pE0VMMEuPCcbPX4qqV/OscTTDZ9nZkNY1ZsJ+IXvS1tjgciZIPEhZuTKiWUkl9emnEch7g/tOA/M0Lrrzh5' +
    'uAeiRBh1C6PaZMi9GlwqZXPZS8TWURbZm1CKvuRC49Lk8b6U663R/LgjzaH0dzOGZOndv0NcDFSgy7IHLPuJMTgIGJ' +
    'd7Hoqsh1nHsZ6bJiS108btCoAxsi/7M2foOje3BiJj3I5Q+Dj+/L2eeczjrKE92CUxAlPBqMnXha3/MLylWJF1lchE' +
    'DTclhy+03+blMfwCp+1Liy2VwZAx1g1pliwaO1zkJtVCK0Zx200kEGqlD4w4+EMPHVmAaDZmpx/mOGBV+LzpL5NxLz' +
    'V/hbRgbSWdiB0eC6mRGpMmhPAfkZYw/lG22gW5MpcYey4nlpGn4Cnk4GXvzwoLzWkEaLCXx2xKTOZOP2tBvAU4nkcx' +
    'aCqQo9h9BE5D+tyGy1bQE6oTkEnjPDIvhb6K',
  calendar: {
    settingsPath: `app.${baseUrl}/setting/calendar`,
    apiPath: `api.${baseUrl}/calendar`,
    helpLink: {
      url: `https://hilfe.${baseUrl}/portal/kb/articles/kalender`,
      label: `hilfe.${baseUrl}`,
    },
    appLinkLabel: `app.${baseUrl}`,
  },
  encryption: {
    regex: /^\w+([-+.']\w+)*@?(resmed\.com|resmed\.de|it-labs\.de|halloalberta\.de)$/,
    settings: [
      { emails: ['@resmed.com', '@resmed.de'], platforms: ['ios', 'iphone', 'ipad'] },
      { emails: ['@it-labs.de', `@${baseUrl}`], platforms: ['cordova', 'hybrid'] },
    ],
  },
  sendAllCommandsToEmail: `hilfe@${baseUrl}`,
  environmentSettings: {
    albertaUrl: {
      dev: `https://dev.k8s.api.${baseUrl}`,
      staging: `https://staging.api.${baseUrl}`,
      production: `https://api.${baseUrl}`,
      uat: `https://uat.api.${baseUrl}`,
    },
    swodocUrls: {
      dev: `https://dev.formulare.${baseUrl}`,
      staging: `https://staging.formulare.${baseUrl}`,
      production: `https://formulare.${baseUrl}`,
    },
  },
  createTenant: {
    defaultUserSuffix: 'halloalberta.de',
  },
  validITLabsUser: ['it-labs.de', 'fiedlefl@gmail.com'],
  markerjs2Key: 'MJS2-P947-M765-5857',
};
