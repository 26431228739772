import { Therapy } from '@alberta/konexi-shared';
import { Component, Input, OnChanges } from '@angular/core';
import { Therapies } from 'src/app/shared/models/therapy';
import { TherapyItem } from 'src/app/shared/models/therapy-item';

@Component({
  selector: 'itl-detail-title-therapy-icon',
  templateUrl: './detail-title-therapy-icon.component.html',
  styleUrls: ['./detail-title-therapy-icon.component.scss'],
})
export class DetailTitleTherapyIconComponent implements OnChanges {
  @Input() therapyId: Therapy;
  @Input() inactive = false;
  public therapyItem: TherapyItem;

  ngOnChanges(): void {
    this.therapyItem = Therapies.getTherapy(this.therapyId);
  }
}
