<div
  class="alberta-button-input-wrapper"
  [ngClass]="{ 'alberta-button-input-wrapper--alert': ngControl && ngControl.invalid }"
  tappable
  (click)="toggleCheckbox($event)"
>
  <div class="button-content-wrapper">
    <div *ngIf="!noIcon" class="icon-wrapper">
      <ion-icon [name]="icon"></ion-icon>
    </div>
    <div class="content-wrapper">
      <ion-checkbox [ngModel]="value" color="variant-secondary"> </ion-checkbox>
      <div class="text-container">
        <div class="text">{{ title }} <span *ngIf="required" class="required-star">*</span></div>
        <div class="description" *ngIf="description">{{ description }}</div>
      </div>
    </div>
  </div>
</div>
