import { inject, InjectionToken } from '@angular/core';
import { AppointmentStateExtension } from 'src/app/business/appointment/appointment-state-extension';
import { AttachmentStateExtension } from 'src/app/business/attachment/attachment-state-extension';
import { DirectOrderStateExtension } from 'src/app/business/direct-order/direct-order-state-extension';
import { InstitutionNoteStateExtension } from 'src/app/business/institution/institution-note-state-extension';
import { OrderStateExtension } from 'src/app/business/order/order-state-extension';
import { PatientNoteStateExtension } from 'src/app/business/patientNotes/patient-note-state-extension';
import { ReminderStateExtension } from 'src/app/business/reminder/reminder-state-extension';
import { SingleOrderStateExtension } from 'src/app/business/single-order/single-order-state-extension';
import { AuditStateExtension } from 'src/app/business/swodoc/audit-state-extension';
import {
  AppointmentModelName,
  AttachmentModelName,
  AuditModelName,
  DirectOrderModelName,
  InstitutionNoteModelName,
  IntegratedCareModelName,
  OrderModelName,
  PatientNoteModelName,
  ReminderModelName,
  SingleOrderModelName,
} from 'src/app/shared/models/model-names';

import { IntegratedCareStateExtension } from '../../business/integrated-care/integratedcare-state-extension';

export const StateExtensionToken = new InjectionToken('StateExtensionToken', {
  factory: () => {
    const appointmentStateExtension = inject(AppointmentStateExtension);
    const patientNoteStateExtension = inject(PatientNoteStateExtension);
    const institutionNoteStateExtension = inject(InstitutionNoteStateExtension);

    appointmentStateExtension.changed.subscribe(async ({ action, payload }) => {
      await patientNoteStateExtension.onChanged(action, payload);
      await institutionNoteStateExtension.onChanged(action, payload);
    });

    const stateExtensions = {
      [AppointmentModelName]: [appointmentStateExtension],
      [OrderModelName]: [inject(OrderStateExtension)],
      [PatientNoteModelName]: [patientNoteStateExtension],
      [InstitutionNoteModelName]: [institutionNoteStateExtension],
      [AttachmentModelName]: [inject(AttachmentStateExtension)],
      [AuditModelName]: [inject(AuditStateExtension)],
      [SingleOrderModelName]: [inject(SingleOrderStateExtension)],
      [DirectOrderModelName]: [inject(DirectOrderStateExtension)],
      [IntegratedCareModelName]: [inject(IntegratedCareStateExtension)],
      [ReminderModelName]: [inject(ReminderStateExtension)],
    };

    return stateExtensions;
  },
});
