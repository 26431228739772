import { Injectable } from '@angular/core';
import { AppflowChannel } from 'src/environments/appflow-channel';

import { Logger } from './common/logging/logger';
import { DataRemovalRegistryService } from './shared/services/data-removal-registry.service';
import { ErpTaskStatusService } from './shared/services/erp-task/erp-task-status.service';
import { NetworkInfoService } from './shared/services/network/network-info.service';
import { PatientAppService } from './shared/services/patient-app/patient-app.service';
import { PingService } from './shared/services/ping.service';
import { PushService } from './shared/services/push/push.service';

@Injectable()
export class AppInitService {
  constructor(
    logger: Logger,
    private _pingService: PingService,
    private _dataRemovalRegistryService: DataRemovalRegistryService,
    private _erpTaskStatusService: ErpTaskStatusService,
    private readonly _patientAppService: PatientAppService,
    private readonly _pushService: PushService,
    private readonly _networkInformationService: NetworkInfoService,
    private readonly _appflowChannel: AppflowChannel
  ) {
    window.logger = logger;
  }

  public init(): void {
    this._pingService.init();
    this._dataRemovalRegistryService.remove();
    this._erpTaskStatusService.init();
    this._patientAppService.init();
    this._pushService.init();
    this._networkInformationService.init();
    this._appflowChannel.init();
  }
}
