<div class="search-filter-bar-wrapper">
  <div class="search-icon" tappable (click)="search()" [ngClass]="{ disabled: disabled }">
    <itl-alberta-icon name="alberta-search"></itl-alberta-icon>
  </div>
  <div class="searchbar-wrapper">
    <form (ngSubmit)="search()">
      <input
        data-testid="search-input"
        #searchInput
        [disabled]="disabled"
        class="searchbar"
        type="search"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [ngModelOptions]="{ standalone: true }"
      />
    </form>
    <div tappable class="cancel-button" (click)="resetSearch()" *ngIf="value">
      <ion-icon name="alberta-search-clear"></ion-icon>
    </div>
  </div>
  <ng-container *ngIf="showFilter">
    <itl-chip icon="alberta-filter" class="filter" *ngIf="!filterActive" (click)="filterType()" [showDismiss]="false">
      {{ 'patient.list.filter.filter' | translate }}
    </itl-chip>
    <ng-container *ngIf="filterActive">
      <div class="filter-chip"><ng-content select="itl-chip"></ng-content></div>
      <itl-chip icon="alberta-filter-active" class="legacy-active-filter" (click)="filterType()">
        {{ 'patient.list.filter.activeFilter' | translate }}
      </itl-chip>
    </ng-container>
  </ng-container>
</div>
